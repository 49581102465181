import React from "react";
import { useSnackbar } from "notistack";
import axios, { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

function useAuth({
  module,
  errorMsg,
  successMsg,
}: {
  module: string;
  errorMsg: string;
  successMsg: string;
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const auth: UseMutationResult = useMutation(
    [module],
    (values: any): Promise<AxiosResponse<any, any>> => {
      return axios.post(
        `${process.env.REACT_APP_NOT_SECRET_CODE}/auth/${module}`,
        {
          ...values,
        }
      );
    },
    {
      onSuccess: (res: AxiosResponse<any, any>) => {
        localStorage.setItem("accessToken", res?.data?.accessToken);
        if (module === "login") {
          navigate("/kasir-usaha/choose-company");
          return;
        }
        if (module === "register") {
          navigate("/kasir-usaha/create-company");
          return;
        }
      },
      onError: (err: AxiosResponse<any, any>) => {
        enqueueSnackbar(errorMsg, { variant: "error" });
      },
    }
  );
  return { auth: auth, ...auth };
}

export default useAuth;
