import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { grey } from "@mui/material/colors";

interface CONTENT {
  title: string;
  tag: string;
  src: string;
}
interface IMAGE {
  src: string;
  index: number;
}
function PoinOfSale() {
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const [activeOne, setActiveOne] = useState<any>({
    index: 0,
    img: "",
  });
  const [activeTwo, setActiveTwo] = useState<any>({
    index: 0,
    img: "",
  });
  const content: CONTENT[] = [
    {
      title: t("management_order"),
      tag: t("digital_payment_tag"),
      src: "/Asset/PoinOfSale/fa6-solid_store.svg",
    },
    {
      title: t("digital_payment"),
      tag: t("digital_payment_tag"),
      src: "/Asset/PoinOfSale/icon-park_wallet.svg",
    },
    {
      title: t("management_payment"),
      tag: t("management_payment_tag"),
      src: "/Asset/PoinOfSale/icon-park_wallet.svg",
    },
  ];
  const imageOne: IMAGE[] = [
    {
      index: 0,
      src: "/Asset/PoinOfSale/sales-android.svg",
    },
    {
      index: 1,
      src: "/Asset/Section/section-one_1.svg",
    },
    {
      index: 2,
      src: "/Asset/Section/section-one_3.svg",
    },
  ];
  const imageTwo: IMAGE[] = [
    {
      index: 0,
      src: "/Asset/PoinOfSale/sales-android.svg",
    },
    {
      index: 1,
      src: "/Asset/Section/section-one_1.svg",
    },
    {
      index: 2,
      src: "/Asset/Section/section-one_3.svg",
    },
  ];
  const handleClickOne: (index: number) => void = (index: number) => {
    setActiveOne(() => {
      const findImage: any = imageOne?.find((i: any) => i?.index === index);
      return { index: index, img: findImage?.src };
    });
  };
  const handleClickTwo: (index: number) => void = (index: number) => {
    setActiveTwo(() => {
      const findImage: any = imageTwo?.find((i: any) => i?.index === index);
      return { index: index, img: findImage?.src };
    });
  };

  return (
    <Box>
      <Box
        sx={{
          bgcolor: "#004C80",
          p: 3,
          py: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box color="#fff">
          <Typography variant="h3" fontWeight={600}>
            {t("title_header_pos")}
          </Typography>
          <Typography
            variant="h5"
            sx={{ maxWidth: { xs: "100%", md: "70%" } }}
            mt={2}
          >
            {t("title_header_pos_tag")}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "100%", display: { xs: "none", md: "block" } }}>
          <img width="100%" src="/Asset/PoinOfSale/poin-of-sale.svg" />
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Box sx={{ maxWidth: "80%" }}>
            <img width="100%" src={activeOne.img} />
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "grid", gap: 5 }}>
          {content?.map((item: CONTENT, index: number) => (
            <Box
              component="span"
              key={index}
              onClick={() => handleClickOne(index)}
              sx={{
                display: "flex",
                gap: 5,
                bgcolor: activeOne.index === index ? grey[300] : "",
                p: 2,
                borderRadius: "16px",
                cursor: "pointer",
              }}
            >
              <Box>
                <img width="70px" src={item?.src} />
              </Box>
              <Box>
                <Typography variant="h5" fontWeight={600}>
                  {item?.title}
                </Typography>
                <Typography>{item?.tag}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            order: 2,
          }}
        >
          <Box sx={{ maxWidth: "80%" }}>
            <img width="100%" src={activeTwo.img} />
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "grid", gap: 5, order: 1 }}>
          {content?.map((item: CONTENT, index: number) => (
            <Box
              component="span"
              key={index}
              onClick={() => handleClickTwo(index)}
              sx={{
                display: "flex",
                gap: 5,
                bgcolor: activeTwo.index === index ? grey[300] : "",
                p: 2,
                borderRadius: "16px",
                cursor: "pointer",
              }}
            >
              <Box>
                <img width="70px" src={item?.src} />
              </Box>
              <Box>
                <Typography variant="h5" fontWeight={600}>
                  {item?.title}
                </Typography>
                <Typography>{item?.tag}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default PoinOfSale;
