import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { cyan } from "@mui/material/colors";

export default function CardComponent({
  sellPrice,
  name,
  handleClick,
  counter,
  bgChange,
}: {
  sellPrice: any;
  handleClick?: () => void;
  name: string;
  counter?: string | number;
  bgChange?: boolean;
}) {
  const AKA: (params: string) => void = (params: string) => {
    const stringParams = params;
    const firstLatter: string = stringParams?.slice(0, 1);
    const secondLatterNumber: number = stringParams?.indexOf(" ") + 2;
    const secondLatter: string = stringParams?.slice(
      secondLatterNumber - 2,
      secondLatterNumber
    );
    const textIniitial: string = firstLatter + secondLatter;
    return textIniitial.replace(" ", "").toUpperCase();
  };
  const akaAlias: any = AKA(name);
  return (
    <Card sx={{ width: "100%" }} onClick={handleClick}>
      <CardActionArea>
        <Box
          sx={{
            height: "120px",
            bgcolor: bgChange ? cyan[900] : "gray",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box sx={{ color: "#fff" }}>
              <Typography fontSize={44} fontWeight="bold">
                {akaAlias}
              </Typography>
              {counter ? (
                <Typography textAlign="center" variant="subtitle2">
                  {counter} Pcs
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
        <CardContent>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="body2">-</Typography>
          <Typography variant="body2" color="text.secondary">
            {sellPrice}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
