import * as React from "react";
import Searching from "../Utils/Search/useSearch";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import { Open_Drawer } from "../Utils/Context/useContextHelper";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AsyncAutoComplete from "./AsyncAutoComplete";

function DrawerSlideBottom({
  open,
  handleClose,
  isFetching,
  setPage,
}: {
  open: boolean;
  isFetching: boolean;
  handleClose: any;
  setPage: (item: number) => void;
}) {
  const { t } = useTranslation<"translation", undefined>();
  const { open: openDrawer, setOpen } = React.useContext(Open_Drawer);
  const navigate: NavigateFunction = useNavigate();
  const [focus, setFocus] = React.useState<boolean>(false);
  const location: Location = useLocation();

  return (
    <div>
      <React.Fragment>
        <Drawer anchor="bottom" open={open} onClose={handleClose}>
          <Box>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 10,
                bgcolor: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: openDrawer.search ? "block" : "none",
                }}
              >
                <Searching
                  autoFocus={true}
                  size="small"
                  label={t("search")}
                  fullWidth
                  setPage={setPage}
                  isFetching={isFetching}
                />
              </Box>
              <AsyncAutoComplete
                onChange={(e: any, i: any) => {
                  const sortByCategory: string = JSON.stringify({
                    categoryId: i?.id,
                  });
                  navigate(`?s=${sortByCategory}`);
                }}
                module="product_categories"
                label={t("sort_by_category")}
                size="small"
                sx={{
                  width: "100%",
                  display: openDrawer.sortByCategory ? "block" : "none",
                }}
              />
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default DrawerSlideBottom;
