import { Autocomplete, Box, Button, Grid, Typography } from "@mui/material";
import { blue, cyan, green, grey, orange, purple, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import { FormatCurrency } from "../../Component/FormatCurrency";
import ReactDatePicker from "../../Component/ReactDatePicker";
import Qs from "../../Utils/QS/qss";
import Filter from "./Filter";
import SalesChart from "./SalesChart";
import TopProduct from "./TopProduct";

function Dashboard() {
  const { t, i18n } = useTranslation();
  const query = Qs();
  const s = query?.s ? JSON.parse(query.s) : {};
  const startDate = s?.createdAt?.['$gte'] || '';
  const endDate = s?.createdAt?.['$lte'] || '';
  const branchId = s?.branchId || '';
  const params = new URLSearchParams({
    startDate,
    endDate,
    branchId
  });
  const queryString = params.toString();

  const { data } = useDataFetch({
    module: `dashboard/card?${queryString}`,
  });
  const cardData = data?.data || {};
  const cards = [
    {
      title: t('net_profit'),
      color: green[400],
      value: FormatCurrency(cardData.netProfit || 0),
    },
    {
      title: t('income'),
      color: cyan[400],
      value: FormatCurrency(cardData.sales || 0),
    },
    {
      title: 'Total ' + t('sales'),
      color: blue[400],
      value: cardData.totalSales || 0,
    },
    {
      title: t('expense'),
      color: purple[400],
      value: FormatCurrency(cardData.expense || 0),
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, sm: 0 } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontWeight={700} variant="h5">
          Dashboard
        </Typography>
        <Filter />
      </Box>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        columnSpacing={2}
        rowSpacing={2}
        mt={1}
      >
        {cards.map((item: any, index: number) => (
          <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
            <Box
              sx={{
                bgcolor: item.color,
                height: 150,
                width: "100%",
                borderRadius: 2,
                p: 2,
              }}
            >
              <Typography color="#fff" fontWeight={600}>
                {item.title}
              </Typography>
              <Typography color="#fff">
                {item.value}
              </Typography>
            </Box>
          </Grid>
        ))}
        <Grid xs={12}>
          <SalesChart />
        </Grid>
        <Grid xs={12}>
          <TopProduct />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
