import React from "react";
import BasicModal from "./BasicModal";
import {
  Typography,
  Autocomplete,
  TextField,
  Box,
  Button,
} from "@mui/material";
import JSPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { Open_Drawer } from "../Utils/Context/useContextHelper";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { FormatCurrency } from "./FormatCurrency";

function Export({
  data,
  tableHead,
  titleReport,
  isLoading,
}: {
  data?: any;
  tableHead?: any;
  titleReport?: string;
  isLoading: boolean;
}) {
  const { open, setOpen } = React.useContext<any>(Open_Drawer);
  const [name, setName] = React.useState<string>("");
  const { t } = useTranslation<"translation", undefined>();
  const [format, setFormat] = React.useState("");
  const handleDownload = () => {
    const doc = new JSPDF();
    doc.text(`${titleReport}`, 13, 10);
    autoTable(doc, {
      styles: { cellPadding: 3 },
      head: [tableHead?.map((i: any) => i?.label)],
      body: data?.map((dat: any) =>
        tableHead?.map((cell: any) => {
          const isDate = cell?.isDate
            ? moment(dat[cell.id]).format("LLLL")
            : dat[cell.id];
          return cell.isCurrency ? FormatCurrency(dat[cell.id]) : isDate;
        })
      ),
    });
    doc.save(`${name}.${format}`);
  };
  return (
    <Box>
      <Button
        disabled={isLoading}
        variant="contained"
        onClick={() =>
          setOpen((i: any) => ({ ...i, export: "export", open: false }))
        }
      >
        Export
      </Button>
      <BasicModal
        labelClose={t("cancel")}
        labelSubmit={t("create")}
        handleSubmit={handleDownload}
        title="Export"
        open={Boolean(open.export)}
        handleClose={() =>
          setOpen((i: any) => ({ ...i, export: null, open: false }))
        }
      >
        <Box>
          <Typography>File format</Typography>
          <Autocomplete
            options={["pdf", "xls"]}
            fullWidth
            value={format}
            onChange={(event, value: any) => {
              setFormat(value);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="select format" />
            )}
          />
          <Typography mt={2}>{t("file_name")}</Typography>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            placeholder="file name"
          />
        </Box>
      </BasicModal>
    </Box>
  );
}

export default Export;
