import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import SpaceBetween from "../../../Component/SpaceBetween";
import Create from "./Create";
import { useTranslation } from "react-i18next";
import ListItems from "../../../Component/ListItems";
import Qs from "../../../Utils/QS/qss";
import TableComponent from "../../../Component/TableComponent";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";
import Update from "./Update";
import Searching from "../../../Utils/Search/useSearch";
import Detail from "./Detail";
import FilterUtility from "../../../Component/FilterUtility";
import { LanguageChange } from "../../../Utils/LanguageChange";
import BasicSpeedDial from "../../../Component/BasicSpeedDial";

interface TABLEHEAD {
  id: string;
  label: string;
  isDate?: boolean;
}
const t: (params: any) => any = (params) => <LanguageChange params={params} />;
export interface TABLEBODY {
  address: string;
  cardNumber: number;
  name: string;
  email: string;
  createdAt: string;
  phone: number;
}

export const tableHead: TABLEHEAD[] = [
  {
    id: "name",
    label: t("name"),
  },
  {
    id: "email",
    label: t("email"),
  },
  {
    id: "phone",
    label: t("phone_number"),
  },
  {
    id: "address",
    label: t("address"),
  },
  {
    id: "createdAt",
    label: t("createdAt"),
    isDate: true,
  },
  {
    id: "cardNumber",
    label: t("card_number"),
  },
];

export default function Supplier(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const { setDialog } = useContext(ConfirmDialog);
  const { mutationDelete } = useMutationDelete({
    module: "suppliers",
    successMsg: t("delete_supplier_success"),
    errorMsg: t("delete_supplier_error"),
  });
  const supplier: any = Qs();
  const { dataFetch, isLoading, setPage, pageCount, isFetching, total } =
    useDataFetch({
      module: "suppliers",
      s: supplier?.s,
    });

  const tableBody: TABLEBODY[] = dataFetch;
  const handleDelete = (item: any) => {
    setDialog({
      id: item?.id,
      msg: `${t("confirm_delete")} ${item?.name}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
        navigate(-1);
      },
    });
  };
  const handleUpdate: (item: any) => void = (item: any) => {
    navigate(`?update-supplier-id=${item?.id}`);
  };
  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-supplier-id=${item?.id}`);
  };

  return (
    <Box>
      <SpaceBetween>
        <Box sx={{ display: "grid" }}>
          <Typography fontWeight={700} variant="h5">
            {t("supplier")}
          </Typography>
          <Typography fontSize={14}>
            Total : {total} {isLoading && <CircularProgress size={13} />} {t("supplier")}
          </Typography>
        </Box>
        <FilterUtility isFetching={isFetching} setPage={setPage} />
        <Box
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            gap: 2,
          }}
        >
          <Searching size="small" label={t("search")} isFetching={isFetching} />
          <Button
            onClick={() => navigate("?create-new-supplier")}
            variant="contained"
          >
            {t("create_supplier")}
          </Button>
        </Box>
      </SpaceBetween>
      <Box mt={3}>
        <TableComponent
          handleDelete={handleDelete}
          pageCount={pageCount}
          handleUpdate={handleUpdate}
          onChange={(event: any, page: number) => setPage(page)}
          isLoading={isLoading}
          tableHead={tableHead}
          tableBody={tableBody}
        />
        <Box sx={{ display: "grid", rowGap: 2 }}>
          <ListItems
            isLoading={isLoading}
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            body={tableBody}
            head={tableHead}
            handleClick={handleDetail}
          />
        </Box>
      </Box>
      <Create />
      <Update />
      <Detail />
      <BasicSpeedDial
        title={t("create_supplier")}
        handleClick={() => navigate("?create-new-supplier")}
      />
    </Box>
  );
}
