import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../../Component/BasicModal";
import Form from "./Form";
import { useTranslation } from "react-i18next";
import useMutationPost from "../../../Hooks/Mutation/useMutationPost";

type FormValues = /*unresolved*/ any;
type INITIALVALUE = { [key:string]: string }

export const initialValues: INITIALVALUE = {
  name: "",
  password: "",
  role: "cashier",
};

function Create(): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { mutationPost, isLoading } = useMutationPost({
    module: "employees",
    errorMsg: 'Gagal',
    successMsg: 'Sukses',
    isBack: true,
  });

  ///stackoverflow start
  /// asked : https://stackoverflow.com/questions/66145167/object-is-possibly-null-typescript-react-useref-formik-innerref
  /// answered by Влад : https://stackoverflow.com/users/5463775/%d0%92%d0%bb%d0%b0%d0%b4
  const formRef = React.useRef<FormikProps<FormValues>>(null);
  ///stackoverflow ends

  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  return (
    <BasicModal
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      handleClose={() => navigate(-1)}
      title={t("create")}
      open={location.search.includes("?create-employee")}
      labelClose={t("cancel")}
      labelSubmit={t("create")}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values: INITIALVALUE) => {
          mutationPost.mutate(values);
        }}
      >
        {(props) => <Form {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Create;
