import { Box, Button, Paper, Typography, Skeleton, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDataFetch from "../Hooks/Query/useDataFetch";

type NavigateFunction = /*unresolved*/ any;

interface SELECT_COMPANY {
  active: null | string;
  data: string;
  profileCompany: any;
}

function ChooseCompany(): JSX.Element {
  const { t } = useTranslation();
  const { dataFetch, isLoading } = useDataFetch({ module: "company_users" });
  const navigate: NavigateFunction = useNavigate();
  const [select, setSelect] = React.useState<SELECT_COMPANY>({
    active: null,
    data: "",
    profileCompany: [],
  });
  const hamdleClick = (): void => {
    localStorage.setItem("companyID", select?.data);
    localStorage.setItem(
      "profileCompany",
      JSON.stringify(select?.profileCompany)
    );
    navigate("/kasir-usaha/app/dashboard");
  };

  const itemRebuild: any = dataFetch?.map((i: any) => i?.company);

  const handleClickCompany = (item: any): void => {
    setSelect((i: SELECT_COMPANY) => ({
      data: item?.id,
      active: item?.id,
      profileCompany: item,
    }));
  };

  const companyID: string | null = localStorage.getItem("companyID");
  const accessToken: string | null = localStorage.getItem("accessToken");
  React.useEffect(() => {
    if (companyID) {
      navigate("/kasir-usaha/app/dashboard");
    }

    if (!accessToken) {
      navigate("/kasir-usaha/login");
    }
  }, [companyID]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            backgroundImage: "url(/Asset/choose-company.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
            position: "relative",
            display: { xs: "none", sm: "none", lg: "block", xl: "block" },
          }}
        >
          <Box
            sx={{
              bgcolor: "#000",
              width: "100%",
              height: "100%",
              opacity: 0.6,
              position: "absolute",
            }}
          />
          <Box sx={{ height: "100vh" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box sx={{ zIndex: 100 }}>
                <Typography variant="h4" fontWeight={700} color={"#fff"}>
                  {t("official_website")}{" "}
                  <span
                    style={{
                      backgroundColor: "#000",
                      color: "#fff",
                      padding: "0px 5px",
                    }}
                  >
                    {t("kasir_usaha_web")}
                  </span>
                </Typography>
                <Typography variant="h6" color={"#fff"}>
                  {t("slogan")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to right,${grey[100]}, ${grey[50]})`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "relative",
            width: { xs: "100%", sm: "100%", lg: "80%", xl: "80%" },
          }}
        >
          <Box
            sx={{
              backgroundImage: "url(/Asset/bg-login.svg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              width: "100%",
              height: "100%",
              opacity: 0.02,
              position: "absolute",
            }}
          />
          <Box
            sx={{
              width: { xs: "90%", sm: "90%", lg: "70%", xl: "70%" },
              zIndex: 999,
            }}
          >
            <Box>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  display: "grid",
                  pb: 4,
                }}
              >
                <Box sx={{ mb: 5 }}>
                  <Typography variant="h4" fontWeight={700} textAlign="center">
                    {t("choose_company")}
                  </Typography>
                </Box>
                {isLoading ? (
                  Array(3)
                    .fill(1)
                    ?.map((item: any, index: number) => (
                      <Skeleton
                        key={index}
                        animation="wave"
                        sx={{ width: "100%", height: "90px" }}
                      />
                    ))
                ) : (
                  <Box
                    sx={{
                      maxHeight: "450px",
                      overflow: "auto",
                      border: `${grey[400]} solid 1px`,
                    }}
                  >
                    {itemRebuild?.map(
                      (
                        item: {
                          name: string;
                          city: string;
                          id: string;
                          address: string;
                        },
                        index: number
                      ) => (
                        <Button
                          onClick={() => handleClickCompany(item)}
                          sx={{
                            // borderRadius: "0px",
                            // display: "flex",
                            // justifyContent: "space-between",
                            bgcolor: Boolean(index % 2)
                              ? item?.id === select.active
                                ? "#000"
                                : grey[200]
                              : item?.id === select.active
                              ? "#000"
                              : "#fff",
                            py: 1,
                            color: item?.id === select.active ? "#fff" : "#000",
                            border: "none",
                            "&:hover": {
                              border: "none",
                              bgcolor: "#000",
                              color: "#fff",
                            },
                          }}
                          fullWidth
                          key={index}
                        >
                          <Grid container direction="row">
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  display: "grid",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography fontSize={13} textAlign="left">
                                  {t("company_name")}
                                </Typography>
                                <Typography
                                  textAlign="left"
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  {item?.name}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "grid",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography fontSize={13} textAlign="right">
                                  {t("address")}
                                </Typography>
                                <Typography
                                  textAlign="left"
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  {item?.address}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Button>
                      )
                    )}
                  </Box>
                )}
                <Button
                  onClick={hamdleClick}
                  disabled={!Boolean(select.active)}
                  variant="contained"
                  fullWidth
                  sx={{ mt: 3 }}
                >
                  {t("enter_to_dashboard")}
                </Button>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChooseCompany;
