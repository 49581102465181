import React from "react";
import { Box, ListItemButton, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import LoadingButton from "../../Component/LoadingButton";
import { CREATE_SALES } from "../../Auth/Validation";
import TableComponent from "../../Component/TableComponent";
import AsyncAutoComplete from "../../Component/AsyncAutoComplete";
import useMutationPost from "../../Hooks/Mutation/useMutationPost";
import { useTranslation } from "react-i18next";
import { FormatCurrency } from "../../Component/FormatCurrency";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import { grey, red } from "@mui/material/colors";

interface CREATE_SALES {
  label: string;
  name: string;
  isAsync: boolean;
  type: string;
  module?: string;
}
interface TABLE_HEAD {
  label: string;
  id: string;
}

function Transaction() {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const [data, setData] = React.useState<[] | any>([]);
  const formCreateSales: CREATE_SALES[] = [
    {
      label: location.search?.includes("sales") ? t("customer") : t("supplier"),
      name: "categoryId",
      isAsync: true,
      type: "text",
      module: location.search?.includes("sales") ? "customers" : "suppliers",
    },
    {
      label: t("branch"),
      name: "branchId",
      isAsync: true,
      type: "text",
      module: "branchs",
    },
    {
      label: t("product"),
      name: "productId",
      isAsync: true,
      type: "text",
      module: "products",
    },
    {
      label: t("quantity"),
      name: "quantity",
      type: "number",
      isAsync: false,
    },
  ];
  const tableHead: TABLE_HEAD[] = [
    {
      id: "name",
      label: t("name"),
    },
    {
      id: "unitPriceCurrency",
      label: location.search?.includes("sales")
        ? t("sell_price")
        : t("purchase_price"),
    },
    {
      id: "quantity",
      label: t("quantity"),
    },
    {
      id: "totalCurrency",
      label: "Total",
    },
  ];

  const { mutationPost, isLoading } = useMutationPost({
    module: location.search?.includes("sales") ? "sales" : "purchases",
    errorMsg: t("create_sales_error_transaction"),
    successMsg: t("create_sales_success_transaction"),
  });

  const handleSave: () => void = () => {
    const contactId: any = data[0]?.contactId;
    const branchId: any = data[0]?.branchId;
    const body: {} = {
      branchId: branchId,
      contactId: contactId,
      total: data
        ?.map((i: any) => i?.total)
        ?.reduce((a: any, b: any) => a + b, 0),
      items: data,
    };
    mutationPost.mutate(body);
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  const handleDelete: (item: any) => void = (item: any) => {
    const clone: any = [...data];
    const delItem = clone?.filter((i: any) => i?.salesId !== item?.salesId);
    setData(delItem);
  };
  const title: string = location.search?.replace("?", "");
  return (
    <Box sx={{ p: { xs: 2, sm: 0, md: 0, lg: 0 } }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" textTransform="capitalize">
          {t("create")} {t(title)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "500px", md: "500px", lg: "500px" },
            position: "sticky",
            top: 50,
            zIndex: 10,
            bgcolor: "#fff",
            pt: { xs: 2, sm: 0, md: 0, lg: 0 },
          }}
        >
          <Formik
            validationSchema={CREATE_SALES}
            initialValues={{
              categoryId: "",
              productId: "",
              branchId: "",
              quantity: 1,
            }}
            onSubmit={(values) => {
              const product: any = values?.productId;
              const contactId: any = values?.categoryId;
              const branchId: any = values?.branchId;
              const transactionPrice: any = location.search?.includes("sales")
                ? product?.sellPrice
                : product?.purchasePrice;
              const body: {} = {
                salesId: `H-${data?.length + 1}`,
                branchId: branchId?.id,
                contactId: contactId?.id,
                productId: product?.id,
                name: product?.name,
                unitPriceCurrency: FormatCurrency(transactionPrice),
                unitPrice: transactionPrice,
                quantity: values?.quantity,
                totalCurrency: FormatCurrency(
                  transactionPrice * values?.quantity
                ),
                total: transactionPrice * values?.quantity,
              };
              const clone: any = [...data];
              clone.push(body);
              setData(clone);
            }}
          >
            {({ getFieldProps, touched, errors, setFieldValue, values }) => (
              <Form>
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    width: "100%",
                  }}
                >
                  {formCreateSales?.map((item, index) => {
                    if (item.isAsync) {
                      return (
                        <AsyncAutoComplete
                          value={(values as any)[item?.name] || ""}
                          error={Boolean(
                            (touched as any)[item?.name] &&
                              (errors as any)[item?.name]
                          )}
                          touched={
                            (touched as any)[item?.name] &&
                            (errors as any)[item?.name]
                          }
                          onChange={(e: any, i: any) => {
                            setFieldValue(item.name, i);
                          }}
                          size="small"
                          module={item.module}
                          key={index}
                          label={item.label}
                          sx={{ width: "100%" }}
                        />
                      );
                    }
                    return (
                      <TextField
                        key={index}
                        fullWidth
                        size="small"
                        type={item?.type}
                        label={item?.label}
                        {...getFieldProps(item?.name)}
                        error={Boolean(
                          (touched as any)[item.name] &&
                            (errors as any)[item?.name]
                        )}
                        helperText={
                          (touched as any)[item.name] &&
                          (errors as any)[item?.name]
                        }
                      />
                    );
                  })}
                  <LoadingButton type="submit" title={t("add")} />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
        <Box sx={{ width: "100%" }}>
          <TableComponent
            isPagination={false}
            pageCount={3}
            handleDelete={handleDelete}
            isLoading={false}
            tableHead={tableHead}
            tableBody={data}
          />
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: {
                  xs: "grid",
                  sm: "none",
                  md: "none",
                  lg: "none",
                },
                rowGap: 2,
                pb: 5,
              }}
            >
              {data?.length <= 0 ? (
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    textAlign="center"
                    color={grey[600]}
                  >
                    {t("add_some_item_to_make")} {title}!
                  </Typography>
                </Box>
              ) : (
                data?.map((i: any, e: number) => (
                  <Box
                    key={e}
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      bgcolor: grey[50],
                      p: 1,
                    }}
                  >
                    <Box sx={{ display: "grid" }}>
                      <Box>
                        <Typography fontSize={14} color="gray">
                          {t("name")}
                        </Typography>
                        <Typography mt={-0.5} variant="h6">
                          {i?.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={14} color="gray">
                          {t("price")} - {t("quantity")}
                        </Typography>
                        <Box sx={{ display: "flex", mt: -0.5 }}>
                          <Typography fontSize={14}>
                            {i?.unitPriceCurrency} x
                          </Typography>
                          <Typography fontSize={14}> {i?.quantity}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <ListItemButton
                        onClick={() => handleDelete(i)}
                        sx={{ float: "right" }}
                      >
                        <DeleteIcon sx={{ color: red[600] }} />
                      </ListItemButton>
                      <Typography fontSize={14} color="gray">
                        total
                      </Typography>
                      <Typography fontSize={14}>{i?.totalCurrency}</Typography>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: {
                xs: "fixed",
                sm: "relative",
                md: "relative",
                lg: "relative",
              },
              bottom: 0,
              right: 10,
              left: 10,
              bgcolor: {
                xs: "#fff",
                sm: "",
                md: "",
                lg: "",
              },
            }}
          >
            <LoadingButton
              disabled={data?.length <= 0}
              onClick={handleSave}
              sx={{
                mt: 2,
                mb: { xs: 1, sm: 0, md: 0, lg: 0 },
                width: { xs: "100%", sm: 0, md: 0, lg: 0 },
              }}
              title={t("save")}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Transaction;
