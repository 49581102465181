import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import Qs from "../../../Utils/QS/qss";
import BasicModal from "../../../Component/BasicModal";
import FormAdd from "./FormAdd";
import { useTranslation } from "react-i18next";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import useMutationUpdate from "../../../Hooks/Mutation/useMutationUpdate";
import { FormValues, initialValues } from "./Create";
import { CREATE_CONTACT } from "../../../Auth/Validation";

function Update() {
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  const customerId: any = Qs();

  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `customers/${customerId["update-customer-id"]}`,
    enabled: location.search.includes("?update-customer-id"),
  });

  const { mutationUpdate, isLoading: btnLoading } = useMutationUpdate({
    module: `customers`,
    id: customerId["update-customer-id"],
    errorMsg: t("update_customer_error"),
    successMsg: t("update_customer_success"),
    isBack: true,
  });

  return (
    <BasicModal
      isLoading={isLoading || btnLoading}
      title={t("update_customer")}
      open={location.search.includes("?update-customer-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("save")}
    >
      <Formik
        innerRef={formRef}
        validationSchema={CREATE_CONTACT}
        initialValues={
          dataFetchNotPagination
            ? { ...dataFetchNotPagination }
            : { ...initialValues }
        }
        onSubmit={(values) => {
          mutationUpdate.mutate(values);
        }}
        enableReinitialize
      >
        {(props: FormikProps<FormValues>) => <FormAdd {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Update;
