import BarChartIcon from "@mui/icons-material/BarChart";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import LogoutIcon from "@mui/icons-material/Logout";
import BuildIcon from "@mui/icons-material/Build";
import ContactsIcon from "@mui/icons-material/Contacts";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { LanguageChange } from "../Utils/LanguageChange";

type Children = {
  path: string;
  icon?: any;
  label: any;
  subChildren?: any;
};

interface Router {
  path: string;
  label: any;
  icon?: any;
  children?: Children[];
}
const t: (params: any) => any = (params) => <LanguageChange params={params} />;

export const SideMenuApp: Router[] = [
  {
    path: "/kasir-usaha/app/dashboard",
    label: t("dashboard"),
    icon: <BarChartIcon />,
  },
  // {
  //   icon: <PointOfSaleIcon />,
  //   path: "/kasir-usaha/app/cashier",
  //   label: t("cashier"),
  // },
  {
    path: "?master",
    label: t("inventory"),
    icon: <WidgetsIcon />,
    children: [
      {
        path: "/kasir-usaha/app/master/products",
        label: t("product"),
      },
      {
        path: "/kasir-usaha/app/master/product-category",
        label: t("product_category"),
      },
      {
        path: "/kasir-usaha/app/master/discount",
        label: t("discount"),
      },
      // {
      //   path: "/kasir-usaha/app/master/banner",
      //   label: t("banner"),
      // },
      // {
      //   path: "?raw-material",
      //   label: t("raw_material"),
      //   icon: <SoupKitchenIcon />,
      //   subChildren: [
      //     {
      //       path: "/kasir-usaha/app/raw-material/raw-material",
      //       label: t("raw_material"),
      //     },
      //     {
      //       path: "/kasir-usaha/app/raw-material/category-raw-material",
      //       label: t("category_raw_material"),
      //     },
      //     {
      //       path: "/kasir-usaha/app/raw-material/recipe",
      //       label: t("recipe"),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/kasir-usaha/app/branch/branch-list",
    label: t("branch"),
    icon: <StorefrontIcon />,
  },
  {
    path: "/kasir-usaha/app/branch/employee",
    label: t("employee"),
    icon: <VerifiedUserRoundedIcon />,
  },
  // {
  //   path: "?branch",
  //   label: t("branch"),
  //   icon: <StorefrontIcon />,
  //   children: [
  //     {
  //       path: "/kasir-usaha/app/branch/branch-list",
  //       label: t("branch_list"),
  //     },
      // {
      //   path: "/kasir-usaha/app/branch/employee",
      //   label: t("employee"),
      // },
      // {
      //   path: "/kasir-usaha/app/branch/cashier-access-code",
      //   label: t("cashier_access_code"),
      // },
      // {
      //   path: "/kasir-usaha/app/branch/shift",
      //   label: t("shift"),
      // },
      // {
      //   path: "/kasir-usaha/app/branch/struck",
      //   label: t("struck"),
      // },
      // {
      //   path: "/kasir-usaha/app/branch/staff-role",
      //   label: t("staff_role"),
      // },
  //   ],
  // },
  {
    path: "?contact",
    icon: <ContactsIcon />,
    label: t("contact"),
    children: [
      {
        path: "/kasir-usaha/app/contact/supplier",
        label: t("supplier"),
      },
      {
        path: "/kasir-usaha/app/contact/customer",
        label: t("customer"),
      },
    ],
  },
  {
    // path: "?transaction",
    path: "/kasir-usaha/app/transaction/sales",
    label: t("transaction"),
    icon: <CurrencyExchangeIcon />,
    // children: [
    //   {
    //     path: "/kasir-usaha/app/transaction/purchase",
    //     label: t("purchase"),
    //   },
    //   {
    //     path: "/kasir-usaha/app/transaction/sales",
    //     label: t("sales"),
    //   },
    // ],
  },
  {
    label: t("expenses"),
    path: "?expenses",
    icon: <CompareArrowsIcon />,
    children: [
      {
        path: "/kasir-usaha/app/transaction/expenses/manage-expenses",
        label: t("manage_expenses"),
      },
      {
        path: "/kasir-usaha/app/transaction/expenses/category-expenses",
        label: t("category_expenses"),
      },
    ],
  },
  // {
  //   path: "?crm",
  //   label: "CMR",
  //   icon: <SupportAgentIcon />,
  //   children: [
  //     {
  //       path: "/kasir-usaha/app/crm/promo",
  //       label: t("promo"),
  //     },
  //     {
  //       path: "/kasir-usaha/app/crm/voucher",
  //       label: t("voucher"),
  //     },
  //     {
  //       path: "/kasir-usaha/app/crm/marketing",
  //       label: t("marketing"),
  //     },
  //   ],
  // },
  {
    path: "?report",
    icon: <ContentPasteIcon />,
    label: t("report"),
    children: [
      {
        path: "/kasir-usaha/app/report/sales-report",
        label: t("sales_report"),
      },
      {
        path: "/kasir-usaha/app/report/product-report",
        label: t("product_report"),
      },
      // {
      //   path: "/kasir-usaha/app/report/purchase-report",
      //   label: t("purchase_report"),
      // },
    ],
  },

  {
    path: "?setting",
    label: t("setting"),
    icon: <BuildIcon />,
    children: [
      {
        path: "/kasir-usaha/app/setting/change-company",
        label: t("change_company"),
      },
      // {
      //   path: "/kasir-usaha/app/setting/payment-method",
      //   label: t("payment_method"),
      // },
      {
        path: "/kasir-usaha/app/setting/change-language",
        label: t("language"),
      },
    ],
  },
  {
    path: "?log-out",
    label: t("log_out"),
    icon: <LogoutIcon />,
  },
];
