import React from "react";
import { Form } from "formik";
import { TextField, Box, Button } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import { default as NumberFormat } from "react-number-format";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import AsyncAutoComplete from "../../../Component/AsyncAutoComplete";
import AutoCompleteForm from "../../../Component/AutoCompleteForm";
import MultipleAutoComplete from "../../../Component/MultipleAutoComplete";
import ListItemButton from "@mui/material/ListItemButton";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

interface FormAddCategory {
  name: string;
  label: string;
  type: string;
  isMultiline?: boolean;
  isAutoCompleteAsync?: boolean;
  isAutoComplete?: boolean;
  module?: string | undefined;
  isCurrency?: boolean;
  isMultiple?: boolean;
  isVariant?: boolean;
}
function FormAdd({
  getFieldProps,
  setFieldValue,
  errors,
  touched,
  values,
}: {
  getFieldProps: any;
  setFieldValue: any;
  errors: any;
  values: any;
  touched: any;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const handleAddVariant: () => void = () => {
    let cloneInitialValues = [...values?.variants];
    cloneInitialValues.push({ name: "", id: Math.random() * 999 + 1 });
    setFieldValue("variants", cloneInitialValues);
  };

  const handleDeleteVariant: (item: any) => void = (item: any) => {
    let cloneInitialValues: any[] = [...values?.variants];
    let delItem: any[] = cloneInitialValues?.filter(
      (i: any) => i?.id !== item?.id
    );
    console.log(cloneInitialValues);
    setFieldValue("variants", delItem);
  };

  /// disabled if form of variant reach 8 input form
  const isDisabledButton: boolean = values?.variants?.length >= 8;

  const formList: FormAddCategory[] = [
    {
      name: "code",
      type: "text",
      label: t("code"),
    },
    {
      name: "name",
      type: "text",
      label: t("name"),
    },
    {
      name: "unit",
      type: "text",
      label: t("unit"),
      isAutoComplete: true,
      module: "product_units",
    },
    {
      name: "purchasePrice",
      type: "number",
      label: t("purchase_price"),
      isCurrency: true,
    },
    {
      name: "sellPrice",
      type: "number",
      label: t("sell_price"),
      isCurrency: true,
    },
    {
      name: "categoryId",
      type: "text",
      label: t("category"),
      module: "product_categories",
      isAutoCompleteAsync: true,
    },
    {
      name: "variant",
      type: "text",
      label: t("variant"),
      module: "variant",
      isVariant: true,
    },
    {
      name: "description",
      type: "text",
      label: t("description"),
      isMultiline: true,
    },
    // {
    //   name: "imageUrl",
    //   type: "text",
    //   label: "imageUrl",
    // },
  ];
  return (
    <Form>
      <Box sx={{ display: "grid", gap: 3 }}>
        {formList?.map((item, index) => {
          if (item.isVariant) {
            return (
              <Box sx={{ display: "grid", gap: 2 }} key={index}>
                <Box sx={{ border: "1px solid #000", p: 2 }}>
                  <Box sx={{ display: "grid", gap: 2 }}>
                    {values?.variants?.map((x: any, y: number) => {
                      return (
                        <Box
                          key={y}
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "stretch",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ width: "50%" }}>
                            <TextField
                              size="small"
                              {...getFieldProps(`variants[${y}]].name`)}
                              label={`${t("variant")} ${y + 1}`}
                              fullWidth
                            />
                          </Box>
                          <Box sx={{ width: "50%" }}>
                            <NumberFormat
                              customInput={TextField}
                              key={index}
                              {...getFieldProps(`variants[${y}]].purchasePrice`)}
                              label={`${t("purchase_price")} ${y + 1}`}
                              thousandSeparator
                              prefix="Rp"
                              size="small"
                            />
                          </Box>
                          <Box sx={{ width: "50%" }}>
                            <NumberFormat
                              customInput={TextField}
                              key={index}
                              {...getFieldProps(`variants[${y}]].sellPrice`)}
                              label={`${t("sell_price")} ${y + 1}`}
                              thousandSeparator
                              prefix="Rp"
                              size="small"
                            />
                          </Box>
                          <Button
                            onClick={() => handleDeleteVariant(x)}
                            variant="contained"
                            color="error"
                          >
                            <DeleteIcon />
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                  <Button
                    disabled={isDisabledButton}
                    sx={{ mt: 3 }}
                    onClick={handleAddVariant}
                    variant="contained"
                    fullWidth
                  >
                    {t("add_variant")}
                  </Button>
                  <FormHelperText>Max 8 variant</FormHelperText>
                </Box>
              </Box>
            );
          }
          if (item?.isCurrency) {
            return (
              <NumberFormat
                customInput={TextField}
                key={index}
                {...getFieldProps(item.name)}
                value={values[item?.name]}
                label={item?.label}
                thousandSeparator
                prefix="Rp"
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                helperText={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
              />
            );
          }
          if (item?.isAutoComplete) {
            return (
              <AutoCompleteForm
                key={index}
                label={item?.label}
                value={values[item?.name]}
                module={item?.module}
                id={item?.name}
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                touched={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
                onChange={(e: any, i: any) => {
                  setFieldValue(item.name, i);
                }}
              />
            );
          }
          if (item?.isAutoCompleteAsync) {
            return (
              <AsyncAutoComplete
                value={values[item?.name] || ""}
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                touched={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
                onChange={(e: any, i: any) => {
                  setFieldValue(item.name, i);
                }}
                module={item.module}
                key={index}
                label={item.label}
                sx={{ width: "100%" }}
              />
            );
          }
          if (item?.isMultiple) {
            return (
              <MultipleAutoComplete
                value={values[item?.name] || []}
                onChange={(e: any, i: any) => {
                  if (!e?.target?.checked) {
                    let clone = [...i];
                    const delItem = clone?.filter(
                      (m: any) => m?.id !== i[i?.length - 1]?.id
                    );
                    if (delItem) {
                      setFieldValue(item.name, delItem);
                    }
                  } else {
                    setFieldValue(item.name, i);
                  }
                }}
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                touched={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
                label={item.label}
                key={index}
                module={item?.module}
              />
            );
          }
          return (
            <TextField
              {...getFieldProps(item.name)}
              key={index}
              type={item?.type}
              label={item?.label}
              multiline={item?.isMultiline}
              rows={item?.isMultiline && 4}
              value={values[item?.name]}
              error={Boolean(
                (touched as any)[item.name] && (errors as any)[item?.name]
              )}
              helperText={
                (touched as any)[item.name] && (errors as any)[item?.name]
              }
            />
          );
        })}
        <SubmitButton />
      </Box>
    </Form>
  );
}

export default FormAdd;
