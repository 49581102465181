import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import {
  useNavigate,
  NavigateFunction,
  Outlet,
  useLocation,
  Location,
} from "react-router-dom";
import SpaceBetween from "../../../Component/SpaceBetween";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import TableComponent from "../../../Component/TableComponent";
import Detail from "./Detail";
import moment from "moment";
import { FormatCurrency } from "../../../Component/FormatCurrency";
import "moment/locale/id";
import { useTranslation } from "react-i18next";
import ListItems from "../../../Component/ListItems";
import BasicSpeedDial from "../../../Component/BasicSpeedDial";
import Searching from "../../../Utils/Search/useSearch";
import FilterUtility from "../../../Component/FilterUtility";
import UpdateStatus from "./UpdateStatus";
import Filter from "../../Dashboard/Filter";

interface OPTION {
  label?: string;
  variant?: string;
  value?: any;
}

interface TABLEHEAD {
  id: string;
  label: string | JSX.Element;
  isCheckBox?: boolean;
  isDate?: boolean;
  isCurrency?: boolean;
  isStatus?: boolean;
  option?: OPTION[];
}

function Sales(): JSX.Element {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const { setDialog } = useContext(ConfirmDialog);
  const [data, setData] = React.useState<[]>([]);
  const location: Location = useLocation();

  const { dataFetch, isLoading, setPage, pageCount, isFetching, total } =
    useDataFetch({
      module: "sales",
    });

  const { mutationDelete } = useMutationDelete({
    module: "sales",
    errorMsg: t("delete_sales_error"),
    successMsg: t("delete_sales_success"),
  });
  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-sales-id=${item?.id}`);
  };

  const handleDelete: (item: any) => void = (item: any) => {
    setDialog({
      msg: `${t("confirm_delete")} ${item?.number}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
      },
    });
  };
  const handleChangeStatus = (item: any) => {
    navigate(`?update-status-sales-id=${item?.id}`);
  };
  const dataRebuildForMobile = dataFetch?.map((i: any) => ({
    ...i,
    contact: i?.contact?.name,
  }));
  const tableHead: TABLEHEAD[] = [
    {
      id: "isCheckBox",
      label: "",
      isCheckBox: true,
    },
    {
      id: "number",
      label: t("number"),
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      isDate: true,
    },
    {
      id: "buyer",
      label: t("Customer"),
    },
    {
      id: "total",
      label: "Total",
      isCurrency: true,
    },
    // {
    //   id: "isOnline",
    //   label: t("order_type"),
    //   isStatus: true,
    //   option: [
    //     {
    //       label: t("offline"),
    //       variant: "orange",
    //       value: false,
    //     },
    //     {
    //       label: t("online"),
    //       variant: "green",
    //       value: true,
    //     },
    //   ],
    // },
  ];

  const isCreate = location.pathname.includes("/create-sales");
  React.useEffect(() => {
    const salesItem = dataFetch?.map((i: any) => {
      return {
        ...i,
        buyyer: i?.isOnline ? i.user?.name : i?.contact?.name,
        isCheckBox: false,
      };
    });
    setData(salesItem);
  }, [dataFetch]);
  return (
    <Box>
      <Box
        sx={{
          display: isCreate ? "none" : "block",
        }}
      >
        <SpaceBetween>
          <Box sx={{ display: "grid" }}>
            <Typography fontWeight={700} variant="h5">
              {t("sales")}
            </Typography>
            <Typography fontSize={14}>
              Total : {total} {isLoading && <CircularProgress size={13} />} {t("sales")}
            </Typography>
          </Box>
          <FilterUtility isFetching={isFetching} setPage={setPage} />
          <Box
            sx={{
              display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
              gap: 2,
            }}
          >
            <Searching
              size="small"
              label={t("search")}
              setPage={setPage}
              isFetching={isFetching}
            />
            <Filter hideDate />
            <Button
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "block" },
              }}
              onClick={() =>
                navigate(
                  "/kasir-usaha/app/transaction/sales/create-sales?sales"
                )
              }
              variant="contained"
            >
              {t("create")}
            </Button>
          </Box>
        </SpaceBetween>
        <Box mt={3}>
          <TableComponent
            setData={setData}
            isOption
            module="sales"
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            handleDelete={handleDelete}
            handleDetail={handleDetail}
            handleChangeStatus={handleChangeStatus}
            isLoading={isLoading}
            tableHead={tableHead}
            tableBody={data}
          />
          <Box sx={{ display: "grid", rowGap: 2 }}>
            <ListItems
              isLoading={isLoading}
              pageCount={pageCount}
              onChange={(event: any, page: number) => setPage(page)}
              body={dataRebuildForMobile}
              handleClick={handleDetail}
              head={tableHead}
            />
          </Box>
        </Box>
        <Detail />
        <UpdateStatus />
        <BasicSpeedDial
          title={t("create_sales")}
          handleClick={() =>
            navigate("/kasir-usaha/app/transaction/sales/create-sales?sales")
          }
        />
      </Box>
      <Outlet />
    </Box>
  );
}

export default Sales;
