import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import Qs from "../../Utils/QS/qss";
import BasicModal from "../../Component/BasicModal";
import Form from "./Form";
import { useTranslation } from "react-i18next";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import useMutationUpdate from "../../Hooks/Mutation/useMutationUpdate";
import { InitialValue } from "./Create";
import useNumberConverter from "../../Hooks/useNumberConverter";
import { initialValue } from "./Create";

function Update() {
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const { formatCurrency } = useNumberConverter();
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  const customerId: any = Qs();
  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `discounts/${customerId["update-discount-id"]}`,
    enabled: location.search.includes("?update-discount-id"),
  });
  console.log("re render update");
  const { mutationUpdate, isLoading: btnLoading } = useMutationUpdate({
    module: `discounts`,
    id: customerId["update-discount-id"],
    errorMsg: t("update_branch_error"),
    successMsg: t("update_branch_success"),
    isBack: true,
  });

  return (
    <BasicModal
      isLoading={isLoading || btnLoading}
      title={t("update_branch")}
      open={location.search.includes("?update-discount-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("save")}
    >
      <Formik
        innerRef={formRef}
        initialValues={
          dataFetchNotPagination
            ? { ...dataFetchNotPagination }
            : { ...initialValue }
        }
        onSubmit={(values) => {
          const body = {
            name: values?.name,
            amount:
              values?.type === "Nominal" ? formatCurrency(values.amount) : 0,
            maxAmount: formatCurrency(values.maxAmount),
            percentage:
              values?.type === "Nominal"
                ? 0
                : formatCurrency(values.percentage),
            type: values?.type === "Nominal" ? "Nominal" : "Percentage",
          };
          mutationUpdate.mutate(body);
        }}
        enableReinitialize
      >
        {(props: FormikProps<InitialValue>) => <Form {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Update;
