import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import SpaceBetween from "../../../Component/SpaceBetween";
import Create from "./Create";
import TableComponent from "../../../Component/TableComponent";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";
import Update from "./Update";
import { useTranslation } from "react-i18next";
import ListItems from "../../../Component/ListItems";
import FilterUtility from "../../../Component/FilterUtility";
import BasicSpeedDial from "../../../Component/BasicSpeedDial";
import Detail from "./Detail";

interface OPTION {
  label?: string;
  variant?: string;
  value?: any;
}

interface TABLEHEAD {
  id: string;
  label: string;
  isStatus?: boolean;
  option?: OPTION[];
}
interface TABLEBODY {
  name: string;
  description: string;
}

export default function Banner(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const { openDialog, setDialog } = useContext(ConfirmDialog);
  const { mutationDelete } = useMutationDelete({
    module: "banners",
    successMsg: t("delete_banner_success"),
    errorMsg: t("delete_banner_error"),
  });

  const { dataFetch, isLoading, setPage, pageCount, total, isFetching } =
    useDataFetch({
      module: "banners",
    });

  const tableBody: any = dataFetch;
  const tableHead: TABLEHEAD[] = [
    {
      id: "name",
      label: t("name"),
    },
    {
      id: "type",
      label: t("type"),
    },
    {
      id: "to",
      label: t("to_href"),
    },
    {
      id: "isActive",
      label: t("status"),
      isStatus: true,
      option: [
        {
          label: t("active"),
          variant: "green",
          value: true,
        },
        {
          label: t("not_active"),
          variant: "red",
          value: false,
        },
      ],
    },
  ];

  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-banner-id=${item?.id}`);
  };

  const handleDelete: (item: any) => void = (item: any) => {
    setDialog({
      id: item?.id,
      msg: `${t("confirm_delete")} ${item?.name}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
      },
    });
  };
  const handleUpdate: (item: any) => void = (item: any) => {
    navigate(`?update-banner-id=${item?.id}`);
  };
  const handleClickDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-banner=${item?.id}`);
  };
  return (
    <Box>
      <SpaceBetween>
        <Box sx={{ display: "grid" }}>
          <Typography fontWeight={700} variant="h5">
            {t("banner")}
          </Typography>
          <Typography fontSize={14}>
            Total : {total} {isLoading && <CircularProgress size={13} />} {t("banner")}
          </Typography>
        </Box>
        <FilterUtility isFetching={isFetching} setPage={setPage} />
        <Button
          sx={{
            display: { xs: "none", sm: "block", md: "block", lg: "block" },
          }}
          onClick={() => navigate("?create-new-banner")}
          variant="contained"
        >
          {t("create_banner")}
        </Button>
      </SpaceBetween>
      <Box mt={3}>
        <TableComponent
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          handleDetail={handleDetail}
          pageCount={pageCount}
          onChange={(event: any, page: number) => setPage(page)}
          isLoading={isLoading}
          tableHead={tableHead}
          tableBody={tableBody}
        />
        <Box sx={{ display: "grid", rowGap: 2 }}>
          <ListItems
            isLoading={isLoading}
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            body={tableBody}
            head={tableHead}
            handleClick={handleClickDetail}
          />
        </Box>
      </Box>
      <Create />
      <Update />
      <Detail />
      <BasicSpeedDial
        title={t("create_banner")}
        handleClick={() => navigate("?create-new-banner")}
      />
    </Box>
  );
}
