import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import BasicModal from "../../Component/BasicModal";
import Qs from "../../Utils/QS/qss";
import { ConfirmDialog } from "../../Utils/Context/useContextHelper";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import { useTranslation } from "react-i18next";
import DetailItem from "../../Component/DetailItem";
import useMutationDelete from "../../Hooks/Mutation/useMutationDelete";

function Detail() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const { setDialog } = React.useContext(ConfirmDialog);
  const navigate: NavigateFunction = useNavigate();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  const supplierId = Qs();
  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `branchs/${supplierId["detail-branchs-id"]}`,
    enabled: location.search.includes("?detail-branchs-id="),
  });

  const itemRebuild = [
    {
      label: t("name"),
      value: dataFetchNotPagination?.name,
    },
    {
      label: t("city"),
      value: dataFetchNotPagination?.city,
    },
    {
      label: t("phone"),
      value: dataFetchNotPagination?.phone,
    },
    {
      label: t("card_number"),
      value: dataFetchNotPagination?.cardNumber,
    },
    {
      label: t("address"),
      value: dataFetchNotPagination?.address,
    },
    {
      label: t("createdAt"),
      value: dataFetchNotPagination?.createdAt,
      isDate: true,
    },
  ];

  const { mutationDelete } = useMutationDelete({
    module: "branchs",
    errorMsg: t("delete_branch_error"),
    successMsg: t("delete_branch_success"),
  });

  const handleDelete = () => {
    setDialog({
      msg: `${t("confirm_delete")} ${dataFetchNotPagination?.name}`,
      handleClick: () => {
        mutationDelete.mutate(supplierId["detail-branchs-id"]);
        navigate(-1);
      },
    });
  };
  return (
    <BasicModal
      isMobile
      handleUpdate={() => navigate("?update-branchs-id=")}
      handleDelete={handleDelete}
      title={t("detail_branch")}
      isDetail
      isLoading={isLoading}
      open={location.search.includes("?detail-branchs-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("close")}
    >
      <DetailItem loading={isLoading} item={itemRebuild} />
    </BasicModal>
  );
}

export default Detail;
