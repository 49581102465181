import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Box, Autocomplete, TextField } from "@mui/material";

function MapSearch({ setPositionCenter }: any) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  return (
    <Box>
      <Autocomplete
        size="small"
        freeSolo
        id="combo-box-demo"
        getOptionLabel={(data: any) => data.description}
        onChange={(x: any, y: any) => {
          getGeocode({ placeId: y?.place_id }).then((res) => {
            const { lat, lng } = getLatLng(res[0]);
            setPositionCenter({ lat, lng });
          });
        }}
        options={data}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="search"
            value={value}
            onChangeCapture={(i: any) => setValue(i.target.value)}
          />
        )}
      />
    </Box>
  );
}

export default MapSearch;
