import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

interface CONTENT {
  title: string;
  tag: string;
  btn: string;
  src: string;
  isOrder: boolean;
}

function SectionOne() {
  const { t } = useTranslation<"translation", undefined>();
  const content: CONTENT[] = [
    {
      title: t("save_sales_product_customer"),
      tag: t("save_sales_product_customer_tag"),
      btn: "learn more",
      src: "/Asset/Section/section-one_1.svg",
      isOrder: false,
    },
    {
      title: t("send_invoice"),
      tag: t("send_invoice_tag"),
      btn: "learn more",
      src: "/Asset/Section/section-one_3.svg",
      isOrder: true,
    },
    {
      title: t("all_in_one"),
      tag: t("all_in_one_tag"),
      btn: "learn more",
      src: "/Asset/Section/section-one_2.svg",
      isOrder: false,
    },
  ];
  return (
    <Box sx={{ p: 3 }}>
      <Typography textAlign="center" variant="h3" fontWeight={600}>
        {t("work_smart_everyware")}
      </Typography>
      <Box mt={10}>
        {content?.map((item: CONTENT, index: number) => (
          <Grid
            container
            direction={{
              xs: "column",
              sm: "column",
              md: item.isOrder ? "row-reverse" : "row",
              lg: item.isOrder ? "row-reverse" : "row",
            }}
            justifyContent="center"
            alignItems="center"
            key={index}
            sx={{ mb: 5 }}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: item.isOrder ? "flex-start" : "flex-end",
              }}
            >
              <Box sx={{ maxWidth: "100%" }}>
                <img width="100%" src={item.src} />
              </Box>
            </Grid>
            <Grid xs={6} item>
              <Box sx={{ textAlign: item.isOrder ? "right" : "left" }}>
                <Typography variant="h5">{item?.title}</Typography>
                <Typography>{item?.tag}</Typography>
                <Button sx={{ mt: 3 }} color="secondary" variant="outlined">
                  {item?.btn}
                </Button>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box
        sx={{
          bgcolor: "#004C80",
          display: { sm: "grid", md: "flex" },
          justifyContent: "space-evenly",
          p: { xs: 3, sm: 3, md: 10, lg: 10 },
        }}
      >
        <Typography textAlign="center" variant="h5" color="#fff">
          {t("promotion")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            mt: { xs: 3, sm: 3, md: 0, lg: 0 },
            justifyContent: { xs: "center", sm: "center" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "#fff",
              color: "#00D3FF",
              "&:hover": {
                bgcolor: grey[100],
              },
            }}
          >
            {t("register_now")}
          </Button>
          <Button variant="contained" sx={{ color: "#fff" }} color="secondary">
            {t("learn_more")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionOne;
