import React from "react";
import { useSnackbar } from "notistack";
import axios, { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

function useDataFetch({
  module,
  enabled = true,
  s,
}: {
  module: string;
  s?: any;
  enabled?: boolean;
}) {
  const TOKEN: string | null = localStorage.getItem("accessToken");
  const COMPANY_ID: string | any = localStorage.getItem("companyID");
  const [page, setPage] = React.useState<number>(1);
  const dataFetch: UseQueryResult<AxiosResponse<any, any>, unknown> = useQuery(
    [module, enabled, page],
    (values: any): Promise<AxiosResponse<any, any>> => {
      return axios.get(
        `${process.env.REACT_APP_NOT_SECRET_CODE}/${module}${module.includes('?') ? '&' : '?'}limit=10&page=${
          page || ""
        }${Boolean(s) ? `&s=${s}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            slug: COMPANY_ID,
          },
        }
      );
    },
    { enabled: Boolean(enabled) }
  );
  React.useEffect(() => {
    if (s) {
      dataFetch.refetch();
    }
  }, [s]);

  return {
    dataFetch: dataFetch.data?.data?.data,
    total: dataFetch.data?.data?.total,
    dataFetchNotPagination: dataFetch.data?.data,
    setPage,
    page,
    pageCount: dataFetch.data?.data?.pageCount,
    ...dataFetch,
  };
}

export default useDataFetch;
