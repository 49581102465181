import { Box, Card, Typography } from "@mui/material";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import Qs from "../../Utils/QS/qss";
import DashboardLineChart from "../../Component/DashboardLineChart";
import moment from "moment";


export default function SalesChart(props: any) {
    const query = Qs();
    const s = query?.s ? JSON.parse(query.s) : {};
    let startDate = s?.createdAt?.['$gte'] || 'week';
    if (startDate === 'today') {
        startDate = 'week';
    }
    const endDate = s?.createdAt?.['$lte'] || '';
    const branchId = s?.branchId || '';
    const params = new URLSearchParams({
        startDate,
        endDate,
        branchId
    });
    const queryString = params.toString();
    const { data } = useDataFetch({
        module: `dashboard/sales_group_by_day?${queryString}`,
    });

    const day = data?.data ? data.data.map((item: any) => ({ ...item, name: moment(item.date).format('D MMM'), total: Number(item.total) })) : [];

    return (
        <Card>
            <Box height={500} m={2} >
                <Typography variant="h6">Penjualan</Typography>
                <DashboardLineChart
                    data={day}
                    lines={[{ dataKey: 'total', unit: ' transaksi', fill: '#52c8ff', stroke: 'blue' }]}
                />
            </Box>
        </Card>
    );
}