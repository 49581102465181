import {
  Box,
  Button,
  Typography,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import moment from "moment";
import "moment/locale/id";
import SpaceBetween from "../../../Component/SpaceBetween";
import Create from "./Create";
import Qs from "../../../Utils/QS/qss";
import ListItems from "../../../Component/ListItems";
import Searching from "../../../Utils/Search/useSearch";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";
import TableComponent from "../../../Component/TableComponent";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import Update from "./Update";
import Detail from "./Detail";
import AsyncAutoComplete from "../../../Component/AsyncAutoComplete";
import { useTranslation } from "react-i18next";
import FilterUtility from "../../../Component/FilterUtility";
import BasicSpeedDial from "../../../Component/BasicSpeedDial";

interface OPTION {
  label?: string;
  variant?: string;
  value?: any;
}

interface TABLEHEAD {
  id: string;
  label: string;
  isDate?: boolean;
  isCurrency?: boolean;
  isStatus?: boolean;
  option?: OPTION[];
}
export interface ACTION {
  icon: JSX.Element;
  name: string;
  action: () => void;
}

interface TABLEBODY {
  code: string;
  name: string;
  createdAt: string;
  purchasePrice: number;
  sellPrice: number;
  unit: string;
}

export default function Product(): JSX.Element {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const { setDialog } = useContext(ConfirmDialog);
  const productCategory: any = Qs();
  const [category, setCategory] = useState("");
  const { dataFetch, isLoading, setPage, pageCount, isFetching, total } =
    useDataFetch({
      module: "products",
      s: productCategory?.s,
    });
  const { mutationDelete } = useMutationDelete({
    module: "products",
    errorMsg: t("delete_product_error"),
    successMsg: t("delete_product_success"),
  });
  const handleUpdate: (item: any) => void = (item: any) => {
    navigate(`?update-product-id=${item?.id}`);
  };
  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-product-id=${item?.id}`);
  };
  const handleDelete: (item: any) => void = (item: any) => {
    setDialog({
      msg: `${t("confirm_delete")} ${item?.name}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
      },
    });
  };

  const itemRebuild: TABLEBODY[] = dataFetch?.map((i: any) => ({
    ...i,
    category: i?.category?.name,
  }));

  const tableHead: TABLEHEAD[] = [
    {
      id: "code",
      label: t("code"),
    },
    {
      id: "name",
      label: t("name"),
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      isDate: true,
    },
    {
      id: "purchasePrice",
      label: t("purchase_price"),
      isCurrency: true,
    },
    {
      id: "sellPrice",
      label: t("sell_price"),
      isCurrency: true,
    },
    {
      id: "unit",
      label: t("unit"),
      isStatus: true,
      option: [
        {
          label: "PCS",
          variant: "cyan",
          value: "pcs",
        },
        {
          label: "UNIT",
          variant: "purple",
          value: "unit",
        },
      ],
    },
  ];
  return (
    <Box>
      <SpaceBetween>
        <Box sx={{ display: "grid" }}>
          <Typography fontWeight={700} variant="h5">
            {t("product")}
          </Typography>
          <Typography fontSize={14}>
            Total : {total} {isLoading && <CircularProgress size={13} />} {t("product")}
          </Typography>
        </Box>
        <FilterUtility
          enableCategory
          isFetching={isFetching}
          setPage={setPage}
        />
        <Box
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            gap: 2,
          }}
        >
          <AsyncAutoComplete
            onChange={(e: any, i: any) => {
              setCategory(i?.name);
              console.log(i);
              const sortByCategory: string = JSON.stringify({
                categoryId: i?.id,
              });
              navigate(`?s=${sortByCategory}`);
            }}
            module="product_categories"
            value={category}
            label={t("sort_by_category")}
            size="small"
            sx={{ width: "300px" }}
          />
          <Searching
            size="small"
            label={t("search")}
            setPage={setPage}
            isFetching={isFetching}
          />
          <Button
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            onClick={() => navigate("?create-product")}
            variant="contained"
          >
            {t("create_product")}
          </Button>
        </Box>
      </SpaceBetween>
      <Box mt={3}>
        <TableComponent
          pageCount={pageCount}
          onChange={(event: any, page: number) => setPage(page)}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          handleDetail={handleDetail}
          isLoading={isLoading}
          tableHead={tableHead}
          tableBody={itemRebuild}
        />
        <Box sx={{ display: "grid", rowGap: 2 }}>
          <ListItems
            isLoading={isLoading}
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            body={itemRebuild}
            head={tableHead}
            handleClick={handleDetail}
          />
        </Box>
      </Box>
      <Create />
      <Update />
      <Detail />
      <BasicSpeedDial
        title={t("create_product")}
        handleClick={() => navigate("?create-product")}
      />
    </Box>
  );
}
