import React from "react";
import { Box, BoxProps } from "@mui/material";
import { SxProps } from "@mui/system";

interface BOX extends BoxProps {
  children: any;
  sx?: SxProps;
}

function SpaceBetween({ children, sx }: BOX): JSX.Element {
  return (
    <Box
      sx={{
        position: {
          xs: "sticky",
          sm: "relative",
          md: "relative",
          lg: "relative",
        },
        top: {
          xs: 50,
          sm: 0,
          md: 0,
          lg: 0,
        },
        py: {
          xs: 1,
          sm: 0,
          md: 0,
          lg: 0,
        },
        px: {
          xs: 2,
          sm: 0,
          md: 0,
          lg: 0,
        },
        zIndex: 10,
        bgcolor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default SpaceBetween;
