import React from "react";
import QueryString from "qs";
import { useLocation, Location } from "react-router-dom";

function Qs(): QueryString.ParsedQs | any {
  const location: Location = useLocation();
  const UrlParams: string = location.search.replace("?", "");
  const qs: QueryString.ParsedQs = QueryString.parse(UrlParams) || "qs";
  return qs;
}

export default Qs;
