import React from "react";
import { FormatCurrency } from "./FormatCurrency";
import { useRef } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function Print58mm({
  companyProfile,
  addressCompany,
  phoneCompany,
  data,
  itemRebuild,
  total,
  moneyCustomer,
  change,
  preview,
  value,
  waterMark,
}: {
  companyProfile?: any;
  addressCompany?: any;
  phoneCompany?: any;
  data?: any;
  itemRebuild?: never[];
  total?: any;
  moneyCustomer?: any;
  change?: any;
  preview?: boolean;
  value?: string;
  waterMark?: boolean;
}) {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const dateNow = moment(new Date().getTime()).format("Do MMMM YYYY");
  const timeNow = moment(new Date().getTime()).format("h:mm:ss a");
  const productItem = itemRebuild || [
    {
      productName: "Ayam Geprek + minum",
      quantity: "2",
      unitPrice: "15.000",
      total: "30.000",
    },
    {
      productName: "Potato strip",
      quantity: "3",
      unitPrice: "7.000",
      total: "21.000",
    },
  ];
  return (
    <Box>
      <Button
        sx={{ display: preview ? "none" : "block" }}
        variant="contained"
        onClick={handlePrint}
      >
        {t("print_invoice")}
      </Button>
      <div style={{ display: preview ? "block" : "none" }}>
        <div ref={printRef} style={{ width: "100%", padding: "10px" }}>
          <p
            style={{
              textAlign: "center",
              marginTop: "3px",
              fontWeight: 700,
              fontSize: preview ? "25px" : "",
              fontFamily: "Poppins",
            }}
          >
            {companyProfile?.name || "Company name"}
          </p>
          <p
            style={{
              fontFamily: "monospace",
              textAlign: "center",
              marginTop: "-15px",
              fontSize: preview ? "16px" : "6px",
            }}
          >
            {addressCompany || "Jl. Universe no.66"}
          </p>
          <p
            style={{
              fontFamily: "monospace",
              textAlign: "center",
              marginTop: preview ? "-17px" : "-7px",
              fontSize: preview ? "16px" : "6px",
            }}
          >
            {phoneCompany || "081213221343"}
          </p>
          <div
            style={{
              display: "grid",
              marginTop: "-5px",
            }}
          >
            {(
              [
                { title: "Pelanggan", price: data?.contactName || "People" },
                { title: "Tanggal", price: dateNow },
                { title: "Pukul", price: timeNow },
                {
                  title: "Pembayaran",
                  price: (data as any)?.paymentMethod || "tunai",
                },
              ] as const
            ).map((item: any, index: number) => (
              <div
                style={{
                  display: "flex",
                  fontSize: preview ? "" : "7px",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <p style={{ fontFamily: "monospace", marginBottom: "-7px" }}>
                  {item.title}
                </p>
                <p style={{ fontFamily: "monospace", marginBottom: "-7px" }}>
                  {item.price}
                </p>
              </div>
            ))}
          </div>
          <div style={{ borderTop: "1px dashed black", marginTop: "10px" }} />
          <p
            style={{
              fontFamily: "monospace",
              textAlign: "center",
              marginTop: "3px",
              fontSize: preview ? "21px" : "11px",
            }}
          >
            Product
          </p>
          <div style={{ marginTop: "-10px" }}>
            {productItem?.map((item: any, index: number) => {
              const unitPrice: any = FormatCurrency(item.unitPrice);
              const total: any = FormatCurrency(item.total);
              return (
                <div
                  style={{
                    fontSize: preview ? "17px" : "7px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    marginBottom: "7px",
                  }}
                  key={index}
                >
                  <div style={{ display: "grid" }}>
                    <p
                      style={{
                        fontFamily: "monospace",
                        marginBottom: preview ? "-17px" : "-7px",
                      }}
                    >
                      {item.productName}
                    </p>
                    <div style={{ display: "flex", gap: 2 }}>
                      <p
                        style={{
                          fontFamily: "monospace",
                          fontSize: preview ? "" : "6px",
                          marginBottom: "-7px",
                        }}
                      >
                        <i>{unitPrice}</i>
                      </p>
                      <p
                        style={{
                          fontFamily: "monospace",
                          fontSize: preview ? "" : "6px",
                          marginBottom: "-7px",
                        }}
                      >
                        x <i>{item.quantity}</i>
                      </p>
                    </div>
                  </div>
                  <p style={{ fontFamily: "monospace", marginBottom: "-7px" }}>
                    {total}
                  </p>
                </div>
              );
            })}
          </div>
          <div style={{ borderTop: "1px dashed black", marginTop: "10px" }} />
          <div>
            <div
              style={{
                display: "grid",
                marginTop: "-5px",
              }}
            >
              {(
                [
                  { title: "Subtotal", price: total || "Rp41.000" },
                  { title: "Diskon", price: "-" },
                ] as const
              ).map((item: any, index: number) => (
                <div
                  style={{
                    display: "flex",
                    fontSize: preview ? "17px" : "7px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  key={index}
                >
                  <p
                    style={{
                      fontFamily: "monospace",
                      marginBottom: "-7px",
                      width: "60%",
                      textAlign: "right",
                    }}
                  >
                    {item.title}:
                  </p>
                  <p
                    style={{
                      fontFamily: "monospace",
                      marginBottom: "-7px",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {item.price}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div style={{ borderTop: "1px dashed black", marginTop: "10px" }} />
          <div>
            <div
              style={{
                marginTop: "-5px",
              }}
            >
              {(
                [
                  { title: "Total", price: total || "Rp41.000" },
                  {
                    title: "Uang Diterima",
                    price: moneyCustomer || "Rp50.000",
                  },
                  { title: "Kembalian", price: change || "Rp8.000" },
                ] as const
              ).map((item: any, index: number) => (
                <div
                  style={{
                    display: "flex",
                    fontSize: preview ? "" : "7px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  key={index}
                >
                  <p
                    style={{
                      fontFamily: "monospace",
                      marginBottom: "-7px",
                      width: "60%",
                      textAlign: "right",
                    }}
                  >
                    {item.title}:
                  </p>
                  <p
                    style={{
                      fontFamily: "monospace",
                      marginBottom: "-7px",
                      display: "inline",
                      textAlign: "left",
                    }}
                  >
                    {item.price}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <p
              style={{
                fontFamily: "monospace",
                textAlign: "center",
                marginTop: "40px",
                fontSize: preview ? "" : "6px",
              }}
            >
              {value || "-- Terima kasih atas kunjungan anda --"}
            </p>
            <div
              style={{
                textAlign: "center",
                display: !waterMark ? "none" : "block",
              }}
            >
              <p
                style={{
                  fontFamily: "monospace",
                  marginTop: "-2px",
                  fontSize: preview ? "20px" : "10px",
                }}
              >
                Powered By
              </p>
              <p
                style={{
                  fontFamily: "monospace",
                  marginTop: preview ? "-24px" : "-14px",
                  fontSize: preview ? "21px" : "11px",
                }}
              >
                KASIR USAHA WEB
              </p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Print58mm;
