import React from "react";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Box, CircularProgress, Button } from "@mui/material";
import { useNavigate, NavigateFunction } from "react-router-dom";
import Qs from "../../Utils/QS/qss";

function Searching({
  isFetching,
  size = "large",
  label,
  setPage,
  ...props
}: {
  fullWidth?: boolean;
  autoFocus?: any;
  isFetching?: boolean;
  size?: any;
  setPage?: any;
  label: string;
}) {
  const location = useLocation();
  const query: any = Qs();
  const filterByCategory: any = query?.s ? JSON.parse(query?.s) : null;
  const navigate: NavigateFunction = useNavigate();
  const [value, setValue] = React.useState<string>("");
  return (
    <TextField
      {...props}
      size={size}
      label={label}
      value={value || ""}
      InputProps={{
        endAdornment: (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => {
                setValue("");
                navigate(
                  `?s=${JSON.stringify({
                    name: { $cont: "" },
                  })}`
                );
              }}
              sx={{
                minHeight: 0,
                minWidth: 0,
                display: value?.length <= 0 ? "none" : "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "gray",
                scale: "0.8",
              }}
            >
              <CloseIcon />
            </Button>
            {/* <CircularProgress sx={{ opacity: isFetching ? 1 : 0 }} size={22} /> */}
          </Box>
        ),
      }}
      onChange={(i) => {
        setValue(i?.target?.value);
        setTimeout(() => {
          setPage(1);
          navigate(
            `?s=${JSON.stringify({
              ...filterByCategory,
              name: { $cont: i?.target.value },
            })}`
          );
        }, 500);
      }}
    />
  );
}

export default Searching;
