import React from "react";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import useDataFetch from "../Hooks/Query/useDataFetch";
import { useLocation, Location } from "react-router-dom";

function AutoCompleteForm({
  module,
  value,
  id,
  error,
  touched,
  onChange,
  label,
  hardCodeOption = false,
  size,
}: {
  module?: any;
  hardCodeOption?: any;
  onChange: any;
  value: any;
  label: any;
  error?: any;
  touched?: any;
  id?: string;
  size?: "small" | "medium"
}) {
  const location: Location = useLocation();
  const { dataFetchNotPagination, isLoading, refetch } = useDataFetch({
    module: module,
    enabled: false,
  });

  const isHardCode =
    hardCodeOption || dataFetchNotPagination?.map((i: any) => i?.name);

  return (
    <Autocomplete
      fullWidth
      onOpen={() => refetch()}
      options={isHardCode || []}
      value={value || null}
      onChange={(e, value) => {
        onChange(e, value)
      }}
      loading={isLoading}
      id={id}
      size={size}
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={touched}
          {...params}
          label={label}
        />
      )}
    />
  );
}

export default AutoCompleteForm;
