import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import "./index.css";
import "./i18n";
import App from "./App";
import MaterialUiTheme from "./Utils/Theme";
import Routing from "./Utils/Routing";
import Query from "./Utils/Query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MaterialUiTheme>
      <Query>
        <Routing>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </Routing>
      </Query>
    </MaterialUiTheme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
