import { Autocomplete, Box, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AutoCompleteData from "../../Component/AutoCompleteData";
import moment from "moment";
import ModalDateRangePicker from "../../Component/ModalDateRangePicker";
import { useState } from "react";
import ReactDatePicker from "../../Component/ReactDatePicker";

interface FilterProps {
    hideDate?: boolean;
}

export default function Filter({ hideDate }: FilterProps) {
    const [option, setOption] = useState<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const s = searchParams.get('s');
    const filter = JSON.parse(s || '{}');

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <AutoCompleteData
                module="branchs"
                onChange={(e: any, option: any) => {
                    navigate(
                        `${location.pathname}?s=${JSON.stringify({
                            ...filter,
                            branchId: option.id,
                        })}`
                    );
                }}
                defaultValue={{ label: 'Semua', value: 'all' }}
                label="Cabang"
                size="small"
            />
            {
                !hideDate && (
                    <Box
                        sx={{
                            display: 'flex',

                        }}
                    >
                                            <Autocomplete
                        sx={{ width: 250 }}
                        defaultValue={{ label: 'Hari ini', value: 'today' }}
                        options={[
                            { label: 'Hari ini', value: 'today' },
                            { label: 'Minggu ini', value: 'week' },
                            { label: 'Bulan ini', value: 'month' },
                            { label: 'Pilih Tanggal', value: 'custom' },
                        ]}
                        size='small'
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                            />
                        )}
                        onChange={(e, option) => {
                            setOption(option);
                            if (option.value !== 'custom') {
                                let gte;
                                let lte = moment().endOf('day').toISOString();
                                if (option.value === 'today') {
                                    gte = moment().startOf('day').toISOString();
                                }
    
                                if (option.value === 'week') {
                                    gte = moment().startOf('week').toISOString();
                                }
    
                                if (option.value === 'month') {
                                    gte = moment().startOf('month').toISOString();
                                }
                                navigate(
                                    `${location.pathname}?s=${JSON.stringify({
                                        ...filter,
                                        createdAt: { $gte: gte, $lte: lte },
                                    })}`
                                );
    
                            }
                        }}
                    />
                    {
                        option?.value === 'custom' && (
                            <Box sx={{ width: 230 }}>
                                <ReactDatePicker modal />
                            </Box>
                        )
                    }
                    </Box>
                )
            }
        </Box>
    )
}