import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../Utils/Context/useContextHelper";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
  handleClose: any;
  handleClickOpen: any;
  handleClick: any;
  msg: string;
  isLoading?: boolean;
  variant?: string;
}

export default function Confirm({
  handleClickOpen,
  handleClose,
  handleClick,
  msg,
  isLoading,
  variant = "delete",
}: Props) {
  const { t } = useTranslation<"translation", undefined>();
  const { openDialog } = React.useContext(ConfirmDialog);
  return (
    <div>
      <Dialog
        open={handleClickOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t("cancel")}
          </Button>
          <Button
            onClick={handleClick}
            disabled={openDialog?.isLoading}
            variant="contained"
            color={variant === "confirm" ? "warning" : "error"}
            autoFocus
          >
            {variant === "delete" && t("delete")}
            {variant === "log out" && t("log_out")}
            {variant === "confirm" && t("apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
