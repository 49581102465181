import React from "react";
import { Box, Typography, Button, ListItemButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useNavigate, NavigateFunction } from "react-router-dom";

type CHILDREN = {
  subTitle: string;
  path: string;
};

interface FOOTER_MENU {
  label: string;
  children: CHILDREN[];
}
interface SOCIAL_MEDIA {
  src: string;
}

interface BTN {
  title: string;
  icon: any;
  color: string;
}
function Footer(): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const menu: FOOTER_MENU[] = [
    {
      label: t("help"),
      children: [
        {
          subTitle: t("call_center"),
          path: "#",
        },
        {
          subTitle: t("call_us"),
          path: "/kasir-usaha/contact-us",
        },
      ],
    },
    {
      label: t("about"),
      children: [
        {
          subTitle: t("about_us"),
          path: "/kasir-usaha/about-us",
        },
        {
          subTitle: t("media_partner"),
          path: "#",
        },
      ],
    },
    {
      label: t("more"),
      children: [
        {
          subTitle: t("privacy_policy"),
          path: "#",
        },
        {
          subTitle: t("terms_of_services"),
          path: "#",
        },
        {
          subTitle: t("faq"),
          path: "#",
        },
      ],
    },
  ];
  const buttonSocialMedia: SOCIAL_MEDIA[] = [
    {
      src: "/Asset/Social-Media/ant-design_instagram-filled.svg",
    },
    {
      src: "/Asset/Social-Media/akar-icons_tiktok-fill.svg",
    },
    {
      src: "/Asset/Social-Media/bi_facebook.svg",
    },
  ];
  const btnDemo: BTN[] = [
    {
      color: "success",
      title: t("contact_us"),
      icon: "/Asset/Section/akar-icons_chat-bubble.svg",
    },
    {
      color: "secondary",
      title: t("try_free"),
      icon: "/Asset/Section/akar-icons_circle-chevron-right.svg",
    },
    {
      color: "secondary",
      title: t("free_trial"),
      icon: "/Asset/Section/akar-icons_circle-triangle-right-fill.svg",
    },
  ];
  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          mt: 10,
          p: { xs: 3, sm: 3, md: 10, lg: 10 },
          bgcolor: "#004C80",
        }}
      >
        <Typography variant="h5" textAlign="center" color="#fff">
          {t("sales_slogan")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            mt: 10,
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" color="#fff" mb={8}>
              {t("register_now_tag")}
            </Typography>
            <Box sx={{ display: "grid", gap: 2 }}>
              {btnDemo?.map((item: BTN, index: number) => (
                <Button
                  key={index}
                  color={item?.color as any}
                  sx={{ color: "#fff" }}
                  variant="contained"
                  startIcon={<img width="15px" src={item?.icon} />}
                >
                  {item?.title}
                </Button>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            <img src="/Asset/Section/pic-section_two.png" />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
          justifyContent: {
            xs: "stretch",
            sm: "stretch",
            md: "space-evenly",
            lg: "space-evenly",
          },
          pt: 10,
        }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
            order: { xs: 2, sm: 2, md: 1, lg: 1 },
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            {t("kasir_usaha")}
          </Typography>
          <Typography sx={{ color: grey[700], fontSize: 15 }}>
            {t("kasir_usaha_tag")}
          </Typography>
          <Button
            startIcon={
              <img
                width="15px"
                src="/Asset/Social-Media/logos_google-play-icon.svg"
              />
            }
            sx={{ my: 2 }}
            variant="contained"
          >
            {t("download_google_play")}
          </Button>
          <Box mt={2}>
            <Typography variant="h6" fontWeight={600}>
              {t("follow_social_media")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "flex-start",
                  lg: "flex-start",
                },
              }}
            >
              {buttonSocialMedia?.map((item: SOCIAL_MEDIA, index: number) => (
                <Button key={index} sx={{ minWidth: 0, minHeight: 0 }}>
                  <img width="25px" src={item?.src} />
                </Button>
              ))}
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="h6" fontWeight={600}>
              PT Adima Digital Teknologi
            </Typography>
            <Typography>{t("copyright")}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            order: { xs: 1, sm: 1, md: 2, lg: 2 },
          }}
        >
          <Box
            sx={{
              display: {
                xs: "grid",
                sm: "flex",
                md: "flex",
                lg: "flex",
              },
              justifyContent: {
                xs: "center",
                sm: "space-between",
                md: "space-between",
              },
              width: "100%",
              gap: { xs: 3 },
              textAlign: { xs: "center", sm: "left", md: "left" },
              mb: { xs: 5, sm: 5, md: 0 },
            }}
          >
            {menu?.map((item: FOOTER_MENU, index: number) => (
              <Box key={index}>
                <Typography fontSize={18} fontWeight={600}>
                  {item?.label}
                </Typography>
                {item?.children?.map((item, index) => (
                  <Box key={index}>
                    <Typography
                      component="a"
                      fontSize={14}
                      onClick={() => navigate(item?.path)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: grey[500],
                        },
                      }}
                    >
                      {item?.subTitle}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
