import React, { useContext } from "react";
import { useSnackbar } from "notistack";
import axios, { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { ConfirmDialog } from "../../Utils/Context/useContextHelper";

function useMutationDelete({
  module,
  errorMsg,
  successMsg,
  isBack = true,
}: {
  module: string;
  errorMsg: string;
  isBack?: boolean;
  successMsg: string;
}) {
  const queryClient = useQueryClient();
  const { setDialog } = useContext(ConfirmDialog);
  const navigate: NavigateFunction = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const TOKEN: string | null = localStorage.getItem("accessToken");
  const COMPANY_ID: string | any = localStorage.getItem("companyID");
  const mutationDelete: any = useMutation(
    [module],
    (id: any): Promise<AxiosResponse<any, any>> => {
      return axios.delete(
        `${process.env.REACT_APP_NOT_SECRET_CODE}/${module}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            slug: COMPANY_ID,
          },
        }
      );
    },
    {
      onMutate: (res: AxiosResponse<any, any>) => {
        setDialog((i: any) => ({ ...i, isLoading: true }));
      },
      onSuccess: (res: AxiosResponse<any, any>) => {
        if (isBack) {
          queryClient.invalidateQueries([module]);
          navigate(-1);
          setDialog((i: any) => ({ ...i, isLoading: false }));
          enqueueSnackbar(successMsg, { variant: "success" });
        }
        if (!isBack) {
          queryClient.invalidateQueries([module]);
          enqueueSnackbar(successMsg, { variant: "success" });
        }
      },
      onError: (err: AxiosResponse<any, any>) => {
        setDialog((i: any) => ({ ...i, isLoading: false }));
        enqueueSnackbar(errorMsg, { variant: "error" });
        console.log(err);
      },
    }
  );
  return { mutationDelete: mutationDelete, ...mutationDelete };
}

export default useMutationDelete;
