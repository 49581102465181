import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import LoadingButton from "./LoadingButton";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

export default function BasicModal({
  open,
  handleClose,
  title,
  children,
  handleSubmit,
  handleUpdate,
  handleDelete,
  labelClose,
  handleSetActive,
  isLoading,
  isDetail = false,
  labelSubmit,
  disabled = false,
  isMobile = false,
  isSetActive = false,
  isError,
}: {
  open: any;
  isDetail?: boolean;
  isError?: boolean;
  disabled?: boolean;
  isMobile?: boolean;
  isSetActive?: boolean;
  isLoading?: boolean;
  title: string;
  handleClose?: () => void;
  handleSubmit?: () => void;
  children: any;
  handleUpdate?: any;
  handleDelete?: any;
  handleSetActive?: any;
  labelSubmit?: string;
  labelClose: string;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const innerHeight = window.innerHeight;
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", md: "70%", lg: "70%" },
              bgcolor: "background.paper",
              boxShadow: 1,
            }}
          >
            <Box>
              <Box
                sx={{
                  p: 2,
                  color: "#fff",
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  color="#000"
                  fontWeight={600}
                >
                  {title}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 2,
                  maxHeight: `${innerHeight - 250}px`,
                  overflow: "scroll",
                  mb: 7,
                  borderTop: `${grey[300]} solid 1px`,
                  borderBottom: `${grey[300]} solid 1px`,
                }}
              >
                {children}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  justifyContent: "flex-end",
                  gap: 2,
                  p: 1,
                }}
              >
                {handleClose ? (
                  <LoadingButton
                    variant="outlined"
                    onClick={handleClose}
                    isLoading={isLoading}
                    title={labelClose}
                  />
                ) : null}
                {isDetail ? null : (
                  <LoadingButton
                    isError={isError}
                    disabled={disabled}
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    title={labelSubmit}
                  />
                )}
                {isMobile ? (
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        lg: "none",
                      },
                      gap: 2,
                    }}
                  >
                    <Button
                      size="small"
                      disabled={isLoading}
                      onClick={handleUpdate}
                      sx={{ color: "#fff" }}
                      variant="contained"
                      color="success"
                    >
                      {t("modify")}
                    </Button>
                    <Button
                      size="small"
                      disabled={isLoading}
                      onClick={handleDelete}
                      variant="contained"
                      color="error"
                    >
                      {t("delete")}
                    </Button>
                    {isSetActive ? (
                      <Button
                        size="small"
                        disabled={isLoading}
                        onClick={handleSetActive}
                        variant="contained"
                        color="warning"
                        sx={{ color: "#fff" }}
                      >
                        {t("set_active")}
                      </Button>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
