import React from "react";
import { Button } from "@mui/material";

function SubmitButton(): JSX.Element {
  return (
    <Button sx={{ display: "none" }} type="submit">
      {null}
    </Button>
  );
}

export default SubmitButton;
