import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FormatCurrency } from "./FormatCurrency";
import { blue } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/id";
import Map from "./GoogleMap/Map";

export const ProgressCommon: () => JSX.Element = () => {
  const { t } = useTranslation<"translation", undefined>();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", my: 5 }}>
        <CircularProgress />
        <Typography variant="h6">{t("please_wait")}</Typography>
      </Box>
    </Box>
  );
};

function DetailItem({
  item,
  table,
  loading,
}: {
  item: any;
  table?: any;
  loading?: boolean;
}) {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  return (
    <Box>
      {loading ? (
        <ProgressCommon />
      ) : (
        <Box>
          <Box>
            {table?.isTable ? (
              <Box mb={1}>
                <Typography variant="h6" color={grey[600]}>
                  {table?.tableLabel}
                </Typography>
                <TableContainer sx={{ border: `#000 1px solid` }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#000" }}>
                        {table?.tableColumns?.map(
                          (head: any, index: number) => (
                            <TableCell
                              align={head?.align}
                              key={index}
                              sx={{ fontWeight: 700, color: "#fff" }}
                            >
                              {head.label}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table?.tableItem?.lenght <= 0 ? (
                        <TableRow>
                          <TableCell>{t("empty")}</TableCell>
                        </TableRow>
                      ) : (
                        table?.tableitem?.map((row: any, i: number) => (
                          <TableRow key={i}>
                            {table?.tableColumns?.map((col: any, i: number) => (
                              <TableCell align={col?.align} key={i}>
                                {col.isCurrency
                                  ? FormatCurrency(row[col.id])
                                  : col.isDate
                                  ? moment(row[col.id]).format("LLLL")
                                  : row[col.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : null}
          </Box>
          {item?.map((item: any, index: number) => {
            const isEmpty: any = item?.value || "-";
            const isCurrency = item?.isCurrency
              ? FormatCurrency(item?.value)
              : isEmpty;
            if (item.isMap) {
              return (
                <Box key={index} sx={{ position: "relative" }}>
                  <Box
                    height="400px"
                    width="100%"
                    sx={{ position: "absolute", zIndex: 12 }}
                  >
                    {null}
                  </Box>
                  <Map key={index} hideSearch coordianate={item?.value} />
                </Box>
              );
            }
            if (item.isImage) {
              return (
                <img
                  alt="pic"
                  key={index}
                  style={{ maxWidth: "100%" }}
                  src={item.value}
                />
              );
            }
            return (
              <Box mb={1} key={index}>
                {/* <Typography variant="h6" color={grey[600]}>
                  {item?.label}
                </Typography>
                <Typography ml={0.5}>
                  {item?.isDate
                    ? moment(item?.value).format("LLLL")
                    : isCurrency}
                </Typography> */}
                <ListItemText
                  primary={item?.label}
                  secondary={
                    item?.isDate
                      ? moment(item?.value).format("LLLL")
                      : isCurrency
                  }
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default DetailItem;
