import React from "react";
import { Form } from "formik";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import { useTranslation } from "react-i18next";

function FormCreateBranch({
  getFieldProps,
  errors,
  touched,
  setFieldValue,
  values,
}: {
  getFieldProps: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
}) {
  const { t } = useTranslation<"translation", undefined>();
  const [checked, setChecked] = React.useState<boolean>(true);
  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    setFieldValue(
      `role`,
      values?.role?.map((i: any) => ({ ...i, active: checked }))
    );
  };

  const handleChange2 = (index: number) => {
    const newData: any = (values as any)?.role?.[index];
    setFieldValue(`role[${index}]`, {
      ...newData,
      active: !(values as any)?.role?.[index]?.active,
    });
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {values?.role?.map((item: any, index: number) => (
        <FormControlLabel
          key={index}
          label={item?.label}
          control={
            <Checkbox
              checked={item?.active}
              onChange={() => handleChange2(index)}
            />
          }
        />
      ))}
    </Box>
  );

  return (
    <Form>
      <Box sx={{ display: "grid", rowGap: 3 }}>
        <TextField fullWidth label={t("name_staff_roles")} />
        <Box>
          <Typography variant="h6">Staff role</Typography>
          <Box>
            <FormControlLabel
              label="Pilih semua"
              control={
                <Checkbox
                  checked={
                    values?.role?.find(
                      (x: any, y: number) => x?.active !== true
                    )
                      ? false
                      : true
                  }
                  indeterminate={
                    values?.role?.[2]?.active !== values?.role?.[1]?.active
                  }
                  onChange={handleChange1}
                />
              }
            />
            {children}
          </Box>
        </Box>
      </Box>
      <SubmitButton />
    </Form>
  );
}

export default FormCreateBranch;
