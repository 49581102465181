import React from "react";
import { Button, CircularProgress, ButtonProps } from "@mui/material";
import { SxProps } from "@mui/system";

interface LoadingButtonProps extends ButtonProps {
  isLoading?: any;
  sx?: SxProps;
  title: any;
  disabled?: boolean;
  isError?: boolean;
  variant?: any;
}

function LoadingButton({
  isLoading,
  variant = "contained",
  isError = false,
  sx,
  disabled,
  title,
  ...props
}: LoadingButtonProps): JSX.Element {
  return (
    <Button
      {...props}
      color={isError ? "error" : "primary"}
      variant={variant}
      disabled={isLoading || disabled}
      sx={{ height: 40, ...sx }}
    >
      {isLoading ? <CircularProgress size={20} /> : title}
    </Button>
  );
}

export default LoadingButton;
