import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box } from "@mui/material";
import useDataFetch from "../Hooks/Query/useDataFetch";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleAutoComplete({
  module,
  label,
  onChange,
  value,
  error,
  touched,
}: {
  value?: any;
  module: any;
  error?: any;
  touched?: any;
  label?: any;
  onChange?: (e: any, i: any) => void;
}) {
  const { dataFetch, isLoading, refetch, isFetching } = useDataFetch({
    module,
    enabled: false,
  });
  return (
    <Autocomplete
      multiple
      fullWidth
      onOpen={() => refetch()}
      getOptionLabel={(dataFetch: any) => dataFetch?.name || ""}
      options={dataFetch || []}
      loading={isLoading}
      onChange={onChange}
      freeSolo
      value={value || ""}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              onChange={(o: any) => {
                console.log(o?.target?.checked);
              }}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={Boolean(value?.find((i: any) => i?.id === option?.id))}
            />
            {option?.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={touched}
          label={label}
        />
      )}
      ChipProps={{
        deleteIcon: <Box sx={{ display: "none" }} />,
      }}
      // renderTags={(value: readonly string[], getTagProps) =>
      //   value.map((option: any, index: number) => (
      //     <Chip
      //       variant="outlined"
      //       label={option?.name}
      //       onDelete
      //       {...getTagProps({ index })}
      //     />
      //   ))
      // }
    />
  );
}
