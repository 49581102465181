import React, { forwardRef, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-datepicker/dist/react-datepicker.css";
import { grey } from "@mui/material/colors";
import moment from "moment";

import IconButton from "./IconButton";

function ReactDatePicker({ iconOnly = false, modal }: { iconOnly?: boolean, modal?: boolean }) {
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const [dateRange, setDateRange] = React.useState<any>([null, null]);
  const [startDate, endDate] = dateRange;
  const dateRef: React.MutableRefObject<any> = React.useRef();
  const openDate = () => {
    dateRef.current?.onInputClick();
  };

  useEffect(() => {
    if (modal) {
      openDate();
    }
  }, []);

  const ExampleCustomInput: any = forwardRef((value: any, onClick: any) => {
    return (
      <Box>
        {iconOnly ? (
          <IconButton onClick={openDate}>
            <CalendarMonthIcon />
          </IconButton>
        ) : (
          <TextField
            size="small"
            InputProps={{
              endAdornment: <CalendarMonthIcon sx={{ color: grey[500] }} />,
            }}
            value={value?.value}
            onClick={openDate}
          />
        )}
      </Box>
    );
  });
  const isoStartDate = moment(startDate).startOf("day").toISOString();
  const isoEndDate = moment(endDate).endOf("day").toISOString();

  React.useEffect(() => {
    if (isoEndDate) {
      navigate(
        `${location.pathname}?s=${JSON.stringify({
          createdAt: { $gte: isoStartDate, $lte: isoEndDate },
        })}`
      );
    }
  }, [isoEndDate]);
  return (
    <Box>
      <DatePicker
        ref={dateRef}
        customInput={<ExampleCustomInput />}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        withPortal
      />
    </Box>
  );
}

export default ReactDatePicker;
