import React from "react";
import Header from "./Header";
import { Box } from "@mui/material";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";

function Home() {
  return (
    <Box>
      <Header />
      <SectionOne />
      <SectionTwo />
    </Box>
  );
}

export default Home;
