import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import ChooseCompany from "./Auth/ChooseCompany";
import CreateCompany from "./Auth/Company/CreateCompany";
import Login from "./Auth/Login/Login";
import NotFound from "./Auth/NotFound";
import Register from "./Auth/Register/Register";
import ResponsiveDrawer from "./Layout";
import ChangeCompany from "./Pages/Setting/ChangeCompany/ChangeCompany";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ProductCategory from "./Pages/Inventory/ProductCategory/ProductCategory";
import Product from "./Pages/Inventory/Product/Product";
import Banner from "./Pages/Inventory/Banner/Banner";
import SalesReport from "./Pages/Report/SalesReport";
import ChangeLanguage from "./Pages/Setting/ChangeLanguage/ChangeLanguage";
import Transaction from "./Pages/Transaction/Transaction";
import Cashier from "./Pages/Cashier/Cashier";
import Customer from "./Pages/Contact/Customer/Customer";
import Supplier from "./Pages/Contact/Supplier/Supplier";
import BranchList from "./Pages/Branch/BranchList/BranchList";
import Sales from "./Pages/Transaction/Sales/Sales";
import Purchase from "./Pages/Transaction/Purchase/Purchase";
import Voucher from "./Pages/CRM/Voucher/Voucher";
import Promo from "./Pages/CRM/Promo/Promo";
import Marketing from "./Pages/CRM/Marketing/Marketing";
import PurchaseReport from "./Pages/Report/PurchaseReport";
import LandingPageLayout from "./Layout/LandingPageUtils/LandingPageLayout";
import Home from "./Pages/LandingPage/Home";
import Pricing from "./Pages/LandingPage/Pricing/Pricing";
import AboutUs from "./Pages/LandingPage/Footer/AboutUs";
import PoinOfSale from "./Pages/LandingPage/Services/PoinOfSale";
import CashierApp from "./Pages/LandingPage/Services/CashierApp";
import CustomerManagement from "./Pages/LandingPage/Services/CustomerManagement";
import StockManagement from "./Pages/LandingPage/Services/StockManagement";
import Invoice from "./Pages/LandingPage/Services/Invoice";
import ContactUs from "./Pages/LandingPage/Footer/ContactUs";
import Recipe from "./Pages/RawMaterial/Recipe/Recipe";
import Employee from "./Pages/Branch/Employee/Employee";
import Struck from "./Pages/Branch/Struck/Struck";
import Shift from "./Pages/Branch/Shift/Shift";
import CashierAceesCode from "./Pages/Branch/CashierAceesCode/CashierAceesCode";
import StaffRole from "./Pages/Branch/StaffRole/StaffRole";
import CategoryRawMaterial from "./Pages/RawMaterial/CategoryRawMaterial/CategoryRawMaterial";
import RawMaterial from "./Pages/RawMaterial/RawMaterial/RawMaterialList";
import ChangePaymentMethod from "./Pages/Setting/Payment/ChangePaymentMethod";
import Discount from "./Pages/Discount/Discount";
import ManageExpenses from "./Pages/Transaction/ManageExpenses/ManageExpenses";
import CategoryExpenses from "./Pages/Transaction/ManageExpenses/CategoryExpenses/CategoryExpenses";
import ProductReport from "./Pages/Report/ProductReport";

export default function Routes(): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> | null {
  return useRoutes([
    {
      path: "kasir-usaha/app",
      element: <ResponsiveDrawer />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "report/product-report",
          element: <ProductReport />,
        },
        {
          path: "report/sales-report",
          element: <SalesReport />,
        },
        {
          path: "report/purchase-report",
          element: <PurchaseReport />,
        },
        {
          path: "branch/branch-list",
          element: <BranchList />,
        },
        {
          path: "branch/employee",
          element: <Employee />,
        },
        {
          path: "branch/cashier-access-code",
          element: <CashierAceesCode />,
        },
        {
          path: "branch/shift",
          element: <Shift />,
        },
        {
          path: "branch/struck",
          element: <Struck />,
        },
        {
          path: "branch/staff-role",
          element: <StaffRole />,
        },
        {
          path: "raw-material/recipe",
          element: <Recipe />,
        },
        {
          path: "raw-material/category-raw-material",
          element: <CategoryRawMaterial />,
        },
        {
          path: "raw-material/raw-material",
          element: <RawMaterial />,
        },
        {
          path: "transaction/sales",
          element: <Sales />,
          children: [
            {
              path: "create-sales",
              element: <Transaction />,
            },
          ],
        },
        {
          path: "transaction/purchase",
          element: <Purchase />,
          children: [
            {
              path: "create-purchase",
              element: <Transaction />,
            },
          ],
        },
        {
          path: "transaction/expenses/manage-expenses",
          element: <ManageExpenses />,
        },
        {
          path: "transaction/expenses/category-expenses",
          element: <CategoryExpenses />,
        },
        {
          path: "cashier",
          element: <Cashier />,
        },
        {
          path: "master/product-category",
          element: <ProductCategory />,
        },
        {
          path: "master/discount",
          element: <Discount />,
        },
        {
          path: "crm/promo",
          element: <Promo />,
        },
        {
          path: "crm/voucher",
          element: <Voucher />,
        },
        {
          path: "crm/marketing",
          element: <Marketing />,
        },
        {
          path: "master/products",
          element: <Product />,
        },
        {
          path: "master/banner",
          element: <Banner />,
        },
        {
          path: "contact/supplier",
          element: <Supplier />,
        },
        {
          path: "contact/customer",
          element: <Customer />,
        },
        {
          path: "setting/change-company",
          element: <ChangeCompany />,
        },
        {
          path: "setting/payment-method",
          element: <ChangePaymentMethod />,
        },
        {
          path: "setting/change-language",
          element: <ChangeLanguage />,
        },
      ],
    },
    {
      path: "/kasir-usaha/login",
      element: <Login />,
    },
    {
      path: "/kasir-usaha/register",
      element: <Register />,
    },
    {
      path: "/kasir-usaha",
      element: <LandingPageLayout />,
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "pricing",
          element: <Pricing />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "services/point-sale",
          element: <PoinOfSale />,
        },
        {
          path: "services/cashier-app",
          element: <CashierApp />,
        },
        {
          path: "services/customer-management",
          element: <CustomerManagement />,
        },
        {
          path: "services/stock-management",
          element: <StockManagement />,
        },
        {
          path: "services/invoice",
          element: <Invoice />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/kasir-usaha/home" />,
    },
    {
      path: "*",
      element: <Navigate to="/404" />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    {
      path: "/kasir-usaha/choose-company",
      element: <ChooseCompany />,
    },
    {
      path: "/kasir-usaha/create-company",
      element: <CreateCompany />,
    },
  ]);
}
