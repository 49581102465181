import React from "react";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CASHIER_PAY } from "../../Auth/Validation";
import { grey } from "@mui/material/colors";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { FormatCurrency } from "../../Component/FormatCurrency";
import AsyncAutoComplete from "../../Component/AsyncAutoComplete";
import { useNavigate, NavigateFunction } from "react-router-dom";

interface CASHIER {
  label: string;
  currency: string | any;
}

function Cart({
  dataRebuild,
  data,
  setData,
  handleDelete,
  subTotal,
  setNext,
  next,
}: {
  dataRebuild: any;
  data: any;
  setData: any;
  setNext: any;
  handleDelete: (item: any) => void;
  subTotal: number;
  next?: boolean;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const cashier: CASHIER[] = [
    {
      label: t("subtotal"),
      currency: FormatCurrency(subTotal),
    },
    {
      label: t("discount"),
      currency: "-",
    },
    {
      label: "Total",
      currency: FormatCurrency(subTotal),
    },
  ];
  React.useEffect(() => {
    if (dataRebuild?.length <= 0) {
      setNext(false);
    }
  }, [dataRebuild]);
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        position: "relative",
        height: "100vh",
      }}
    >
      <Formik
        initialValues={{
          contactId: "",
          branchId: "",
          description: "",
        }}
        validationSchema={CASHIER_PAY}
        onSubmit={(values: any) => {
          const contactId: any = values?.contactId;
          const branchs: any = values?.branchId;
          const body: {} = {
            description: values?.description,
            branchId: branchs?.id,
            contactId: contactId?.id,
            contactName: contactId?.name,
            total: dataRebuild
              ?.map((i: any) => i?.totalSum)
              ?.reduce((a: any, b: any) => a + b, 0),
            items: data?.map((i: any) => ({
              quantity: i?.quantity,
              unitPrice: i?.unitPrice,
              total: i?.unitPrice * i?.quantity,
              productId: i?.id,
              productName: i?.name,
            })),
          };
          sessionStorage.setItem("cart", JSON.stringify(body));
          navigate("?payment");
        }}
      >
        {({ getFieldProps, setFieldValue, values, errors, touched }) => (
          <Form>
            <Box sx={{ display: "grid", rowGap: 2, mb: 2 }}>
              <AsyncAutoComplete
                onChange={(e: any, i: any) => {
                  setFieldValue("contactId", i);
                }}
                size="small"
                value={values.contactId}
                module="customers"
                label={t("choose_customer")}
                sx={{ width: "100%" }}
                error={Boolean(
                  (touched as any)["contactId"] && (errors as any)["contactId"]
                )}
                touched={
                  (touched as any)["contactId"] && (errors as any)["contactId"]
                }
              />
              <AsyncAutoComplete
                size="small"
                onChange={(e: any, i: any) => {
                  setFieldValue("branchId", i);
                }}
                value={values.branchId}
                module="branchs"
                label={t("choose_branch")}
                sx={{ width: "100%" }}
                error={Boolean(
                  (touched as any)["branchId"] && (errors as any)["branchId"]
                )}
                touched={
                  (touched as any)["branchId"] && (errors as any)["branchId"]
                }
              />
            </Box>
            <Divider />
            <Box>
              {dataRebuild?.length <= 0 ? (
                <Box
                  sx={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" fontWeight={600} color={grey[600]}>
                    {t("empty_cart")}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
                  {dataRebuild?.map((item: any, index: number) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          my: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Box
                            sx={{
                              height: "30px",
                              width: "30px",
                              bgcolor: "#000",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                              borderRadius: "5px",
                            }}
                            fontWeight={600}
                            fontSize={15}
                          >
                            {item?.quantity}
                          </Box>
                          <Typography fontWeight={600} fontSize={15}>
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Typography fontSize={15}>{item?.total}</Typography>
                          <ListItemButton onClick={() => handleDelete(item)}>
                            <DeleteIcon sx={{ color: "red" }} />
                          </ListItemButton>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Divider />
            <Box>
              {cashier?.map((item: CASHIER, index: number) => (
                <Box
                  key={index}
                  sx={{
                    my: next ? 1 : 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontWeight={600} fontSize={15}>
                    {item?.label}
                  </Typography>
                  <Typography fontSize={15}>{item?.currency}</Typography>
                </Box>
              ))}
            </Box>
            <TextField
              {...getFieldProps("description")}
              fullWidth
              multiline
              label="Note"
              rows={next ? 1 : 4}
            />
            <Button
              disabled={data?.length <= 0}
              onClick={() => setData([])}
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
              }}
            >
              {t("clear_cart")}
            </Button>
            <Box
              sx={{
                pb: {
                  xs: 18,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
              }}
            />
            <Box
              sx={{
                position: {
                  xs: "fixed",
                  sm: "absolute",
                  md: "absolute",
                  lg: "absolute",
                },
                bottom: 0,
                p: 2,
                zIndex: 20,
                right: 0,
                left: 0,
                display: "flex",
                bgcolor: {
                  xs: "#fff",
                  sm: "#fff",
                  md: "#fff",
                  lg: "#fff",
                },
                gap: 2,
              }}
            >
              <Button
                disabled={data?.length <= 0}
                variant="contained"
                color="error"
                fullWidth
                sx={{
                  width: "30%",
                  py: 3,
                  display: { xs: "block", sm: "none", md: "none", lg: "none" },
                }}
                onClick={() => setNext(!next)}
              >
                {t("back")}
              </Button>
              <Button
                disabled={data?.length <= 0}
                type="submit"
                variant="contained"
                fullWidth
                sx={{ py: 3 }}
              >
                {t("pay")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default Cart;
