import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import BasicModal from "../../../Component/BasicModal";
import { ProgressCommon } from "../../../Component/DetailItem";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import useMutationUpdate from "../../../Hooks/Mutation/useMutationUpdate";
import Qs from "../../../Utils/QS/qss";
import BasicSelect from "../../../Component/BasicSelect";

function UpdateStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const salesId = Qs();
  const [initialValue, setInitialValue] = React.useState<any>("");

  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `sales/${salesId["update-status-sales-id"]}`,
    enabled: location.search.includes("?update-status-sales-id="),
  });
  const { mutationUpdate, isLoading: isLoadingMutaion } = useMutationUpdate({
    module: "sales",
    id: `${salesId["update-status-sales-id"]}`,
    errorMsg: t("update_status_sales_error"),
    successMsg: t("update_status_sales_success"),
    isBack: true,
  });

  const status = [
    {
      label: "ACCEPTED",
      value: "accepted",
    },
    {
      label: "FINISHED",
      value: "finished",
    },
    {
      label: "CANCELED",
      value: "canceled",
    },
    {
      label: "REQUESTING",
      value: "requesting",
    },
    {
      label: "ONROUTE",
      value: "onroute",
    },
    {
      label: "REJECTED",
      value: "rejected",
    },
    {
      label: "PROCESS",
      value: "process",
    },
  ];

  const handleSubmit: () => void = () => {
    const data = { status: initialValue };
    mutationUpdate.mutate(data);
  };
  React.useEffect(() => {
    if (dataFetchNotPagination) {
      setInitialValue(dataFetchNotPagination?.status);
    }
  }, [dataFetchNotPagination]);
  return (
    <BasicModal
      isLoading={isLoading || isLoadingMutaion}
      title={t("update_status")}
      handleSubmit={handleSubmit}
      open={location.search.includes("?update-status-sales-id=")}
      handleClose={() => navigate(-1)}
      labelClose={t("close")}
      labelSubmit={t("save")}
    >
      <Box>
        {isLoading ? (
          <ProgressCommon />
        ) : (
          <BasicSelect
            listMenuItem={status}
            value={initialValue}
            onChange={(i: any) => setInitialValue(i.target.value)}
            title={t("update_status")}
          />
        )}
      </Box>
    </BasicModal>
  );
}

export default UpdateStatus;
