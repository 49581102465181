import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Pagination,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  Grow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useMutationDelete from "../Hooks/Mutation/useMutationDelete";
import ModeIcon from "@mui/icons-material/Mode";
import { useTranslation } from "react-i18next";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  cyan,
  green,
  grey,
  red,
  yellow,
  orange,
  purple,
} from "@mui/material/colors";
import { ConfirmDialog } from "../Utils/Context/useContextHelper";
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import BasicModal from "./BasicModal";
import moment from "moment";
import "moment/locale/id";
import { FormatCurrency } from "./FormatCurrency";

function TableComponent({
  pageCount,
  tableHead,
  tableBody,
  handleDelete,
  handleUpdate,
  handleChoose,
  onChange,
  emptyTag,
  module,
  isPagination = true,
  isOption = true,
  isLoading,
  isPhone = false,
  handleDetail,
  setData,
  handleChangeStatus,
}: {
  pageCount: number;
  module?: string;
  tableHead: any;
  tableBody: any;
  isOption?: boolean;
  isPhone?: boolean;
  isPagination?: boolean;
  isLoading: boolean;
  handleDelete?: any;
  handleUpdate?: any;
  handleChangeStatus?: any;
  handleChoose?: any;
  handleDetail?: any;
  setData?: any;
  onChange?: (event: any, page: number) => void;
  emptyTag?: string | undefined;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  moment.locale("id");
  const { enqueueSnackbar } = useSnackbar();
  const [deleteList, setDeleteList] = React.useState<[]>([]);
  const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const queryClient = useQueryClient();
  const handleBox: (item: any) => void = (item: any) => {
    let clone: any = [...tableBody];
    const listDeleteItems: any = [...deleteList];

    if (!item?.isCheckBox) {
      const body = { ...item, isCheckBox: true };
      clone = clone?.map((i: any) => {
        if (i?.id === item?.id) {
          return {
            ...i,
            isCheckBox: true,
          };
        } else {
          return i;
        }
      });
      listDeleteItems?.push(body);
      setDeleteList(listDeleteItems);
      setData(clone);
    }
    if (item?.isCheckBox) {
      clone = clone?.map((i: any) => {
        if (i?.id === item?.id) {
          return {
            ...i,
            isCheckBox: false,
          };
        } else {
          return i;
        }
      });
      const delItems = listDeleteItems?.filter((i: any) => i?.id !== item?.id);
      setDeleteList(delItems);
      setData(clone);
    }
  };
  const handleChangeCheckBox: (item: any, event: boolean) => void = (
    item: any,
    event: boolean
  ) => {
    const newDataItemCheckBox: any = tableBody?.map((i: any) => ({
      ...i,
      isCheckBox: event,
    }));
    setData(newDataItemCheckBox);
    if (event) {
      setDeleteList(newDataItemCheckBox);
    }
    if (!event) {
      setDeleteList([]);
    }
  };
  const isDelete: boolean = Boolean(
    tableBody?.find((i: any) => i?.isCheckBox === true)
  );

  const handleMultiplyDelete: () => void = () => {
    navigate("?delete");
  };

  const handleSubmit: () => void = async () => {
    setLoadingDelete(true);
    for (let i = 0; i < deleteList?.length; i++) {
      const TOKEN: string | null = localStorage.getItem("accessToken");
      const COMPANY_ID: string | any = localStorage.getItem("companyID");
      axios
        .delete(
          `${process.env.REACT_APP_NOT_SECRET_CODE}/${module}/${
            (deleteList[i] as any)?.id
          }`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              slug: COMPANY_ID,
            },
          }
        )
        .then(() => {
          if (i === deleteList?.length - 1) {
            queryClient.invalidateQueries([module]);

            navigate(-1);
            enqueueSnackbar(
              `${deleteList?.length} ${t("delete_multiple_success")}`,
              {
                variant: "success",
              }
            );
            setDeleteList([]);
            setLoadingDelete(false);
          }
        })
        .catch(() => {
          if (i === deleteList?.length - 1) {
            navigate(-1);
            enqueueSnackbar(
              `${deleteList?.length} ${t("delete_multiple_error")}`,
              {
                variant: "error",
              }
            );
            setLoadingDelete(false);
          }
        });
    }
  };
  const [anchor, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dataCell, setDataCell] = React.useState<{}>({});
  const open = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose: () => void = () => {
    setAnchorEl(null);
  };
  const BODY_TABLE =
    tableBody?.length <= 0 ? (
      <TableRow>
        <TableCell colSpan={6} sx={{ border: "none", textAlign: "center" }}>
          <Typography variant="h6" fontWeight={600} color={grey[600]}>
            {t("empty")}
          </Typography>
          <Typography color={grey[600]}>{emptyTag}</Typography>
        </TableCell>
      </TableRow>
    ) : (
      tableBody?.map((body: any, index: any) => (
        <TableRow sx={{ border: `${grey[200]} solid 1px` }} key={index}>
          {tableHead?.map((head: any, index: any) => {
            const isZeroCurrency: any =
              body[head.id] === 0 ? "-" : FormatCurrency(body[head.id]);
            const isZeroPercent: any =
              body[head.id] === 0 ? "-" : `${body[head.id]}%`;
            const isCurrency: any = head.isCurrency
              ? isZeroCurrency
              : body[head.id];
            const isDate: any = head.isDate
              ? moment(body[head.id]).format("llll")
              : isCurrency;
            const isPercentage: any = head.isPercent ? isZeroPercent : isDate;
            const isEmpty: any = isPercentage || "-";

            const variantColor: (params: any) => void = (params: any) => {
              const isOption: any = head?.isStatus
                ? head?.option?.find((i: any) => i.value === params)
                : [];
              const colorVariant: any = [
                {
                  variant: "green",
                  color: green[700],
                  bgcolor: green[200],
                },
                {
                  variant: "orange",
                  color: orange[700],
                  bgcolor: orange[200],
                },
                {
                  variant: "red",
                  color: red[700],
                  bgcolor: red[200],
                },
                {
                  variant: "cyan",
                  color: cyan[700],
                  bgcolor: cyan[200],
                },
                {
                  variant: "purple",
                  color: purple[700],
                  bgcolor: purple[200],
                },
              ].find((i: any) => i?.variant === isOption?.variant);
              const text: any = isOption?.label;
              if (isOption) {
                return (
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    sx={{
                      color: colorVariant?.color,
                      bgcolor: colorVariant?.bgcolor,
                      width: "fit-content",
                      px: 1,
                      py: 0.5,
                      borderRadius: "25px",
                      fontWeight: 500,
                    }}
                  >
                    {text}
                  </Typography>
                );
              } else {
                return;
              }
            };
            const indicator = head.option
              ? variantColor(body[head.id] as any)
              : isEmpty;
            return (
              <TableCell
                sx={{ border: "none", textTransform: "capitalize" }}
                key={index}
              >
                {head?.isCheckBox ? (
                  <Checkbox
                    checked={body?.isCheckBox}
                    onChange={() => handleBox(body)}
                  />
                ) : (
                  indicator
                )}
              </TableCell>
            );
          })}
          <TableCell
            onClick={() => setDataCell(body)}
            align="right"
            sx={{ border: "none", display: isOption ? "block" : "none" }}
          >
            <Button onClick={handleClick}>
              <MoreVertIcon />
            </Button>
            <Menu
              PaperProps={{
                style: {
                  boxShadow: "0px 2px 5px -1px gray",
                },
              }}
              anchorEl={anchor}
              open={open}
              onClose={handleClose}
            >
              {handleChangeStatus ? (
                <MenuItem
                  onClick={() => {
                    handleChangeStatus(dataCell);
                    setAnchorEl(null);
                  }}
                >
                  <CompareArrowsIcon sx={{ color: yellow[700], mr: 1 }} />
                  {t("update_status")}
                </MenuItem>
              ) : null}
              {handleChoose ? (
                <MenuItem
                  onClick={() => {
                    handleChoose(dataCell);
                    setAnchorEl(null);
                  }}
                >
                  <CompareArrowsIcon sx={{ color: yellow[700], mr: 1 }} />
                  {t("set_active")}
                </MenuItem>
              ) : null}
              {handleDetail ? (
                <MenuItem
                  onClick={() => {
                    handleDetail(dataCell);
                    setAnchorEl(null);
                  }}
                >
                  <InfoIcon sx={{ color: cyan[600], mr: 1 }} />
                  {t("detail")}
                </MenuItem>
              ) : null}
              {handleUpdate ? (
                <MenuItem
                  onClick={() => {
                    handleUpdate(dataCell);
                    setAnchorEl(null);
                  }}
                >
                  <ModeIcon sx={{ color: green[500], mr: 1 }} />
                  {t("modify")}
                </MenuItem>
              ) : null}
              {handleDelete ? (
                <MenuItem
                  onClick={() => {
                    handleDelete(dataCell);
                    setAnchorEl(null);
                  }}
                >
                  <DeleteIcon sx={{ color: red[600], mr: 1 }} />
                  {t("delete")}
                </MenuItem>
              ) : null}
            </Menu>
          </TableCell>
        </TableRow>
      ))
    );
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        display: {
          xs: isPhone ? "block" : "none",
          sm: "block",
          md: "block",
          lg: "block",
        },
      }}
    >
      <BasicModal
        isError
        isLoading={loadingDelete}
        title={`${t("confirm_delete")}?`}
        open={location.search.includes("?delete")}
        handleClose={() => navigate(-1)}
        handleSubmit={handleSubmit}
        labelClose={t("cancel")}
        labelSubmit={t("delete")}
      >
        <Typography>{deleteList?.length} penjualan akan dihapus.</Typography>
      </BasicModal>
      <Grow in={isDelete}>
        <Box
          sx={{
            mb: 0.5,
            p: 1,
            bgcolor: red[100],
            display: isDelete ? "flex" : "none",
            transition: "0.5s",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button
            onClick={handleMultiplyDelete}
            color="error"
            variant="contained"
          >
            {t("delete")}
          </Button>
          <Typography variant="h6" color={red[800]}>
            {deleteList?.length} {t("item_selected")}
          </Typography>
        </Box>
      </Grow>
      <Table sx={{ borderRadius: "40px" }}>
        <TableHead>
          <TableRow sx={{ bgcolor: "#000" }}>
            {tableHead?.map((head: any, index: number) => {
              const isFullSpan: any =
                index === tableHead?.length - 1 ? 6 : "false";
              if (head?.isCheckBox) {
                return (
                  <TableCell key={index}>
                    <Box
                      sx={{
                        bgcolor: "#fff",
                        "&:hover": {
                          bgcolor: "#fff",
                        },
                        width: "fit-content",
                      }}
                    >
                      <Checkbox size="small" onChange={handleChangeCheckBox} />
                    </Box>
                  </TableCell>
                );
              }
              return (
                <TableCell
                  colSpan={isFullSpan}
                  sx={{ fontWeight: 600, border: "none", color: "#fff" }}
                  key={index}
                >
                  {head.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{ border: "none", textAlign: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  <CircularProgress sx={{ my: 2 }} />
                  <Typography variant="h6">{t("please_wait")}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            BODY_TABLE
          )}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: isPagination ? "flex" : "none",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          sx={{ mt: 2 }}
          onChange={onChange}
          count={pageCount}
          shape="rounded"
        />
      </Box>
    </Box>
  );
}

export default TableComponent;
