import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// stackoverflow start
// question : https://stackoverflow.com/questions/72112491/marker-not-showing-react-google-maps-api-on-localhost-next-js
// answered by Smokey Dawson :https://stackoverflow.com/users/8724160/smokey-dawson
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
// stackoverflow end
import MapSearch from "./MapSearch";
import { containerStyle } from "./style";

function MapGoogle({
  center,
  setPositionCenter,
  markerCurrentPosition,
  setFieldValue,
  hideSearch,
}: {
  center: any;
  setPositionCenter: any;
  markerCurrentPosition?: any;
  setFieldValue?: any;
  hideSearch?: boolean;
}) {
  const { t } = useTranslation<"translation", undefined>();
  const [newMarkerForNewposition, setNewMarkerForNewposition] = React.useState({
    lat: 0,
    lng: 0,
  });
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 2,
          bgcolor: "#fff",
          display: hideSearch ? "none" : "block",
        }}
      >
        <MapSearch setPositionCenter={setPositionCenter} />
      </Box>
      <GoogleMap
        options={{ disableDefaultUI: true }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onClick={(i: any) => {
          setFieldValue("new_coordinate", {
            lat: i?.latLng?.lat(),
            lng: i?.latLng?.lng(),
          });
          setNewMarkerForNewposition({
            lat: i?.latLng?.lat(),
            lng: i?.latLng?.lng(),
          });
        }}
      >
        <MarkerF
          position={markerCurrentPosition}
          label={t("current_location")}
        />
        {newMarkerForNewposition?.lat === 0 ? null : (
          <MarkerF
            position={newMarkerForNewposition}
            label={t("new_location")}
          />
        )}
      </GoogleMap>
    </Box>
  );
}

function Map({
  coordianate,
  setFieldValue,
  hideSearch,
}: {
  setFieldValue?: any;
  coordianate?: any;
  hideSearch?: boolean;
}) {
  const apiMap: any = process.env.REACT_APP_GOOGLE_MAP_API;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiMap,
    libraries: ["places"],
  });

  /// Position current before change
  const [newPositionCenter, setNewPositionCenter] = React.useState<null>(null);
  const positionCenter = {
    lat: coordianate?.lat || 0,
    lng: coordianate?.lng || 0,
  };
  const positionCenterFirstRender = React.useMemo(
    () => positionCenter,
    [coordianate?.lat, coordianate?.lng]
  );
  return (
    <Box>
      {!isLoaded ? (
        <Typography>loading</Typography>
      ) : (
        <MapGoogle
          hideSearch={hideSearch}
          setFieldValue={setFieldValue}
          center={newPositionCenter || positionCenterFirstRender}
          markerCurrentPosition={positionCenter}
          setPositionCenter={setNewPositionCenter}
        />
      )}
    </Box>
  );
}

export default Map;
