import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../../Component/BasicModal";
import FormAdd from "./FormAdd";
import useMutationPost from "../../../Hooks/Mutation/useMutationPost";
import { useTranslation } from "react-i18next";
import { CREATE_CONTACT } from "../../../Auth/Validation";

export interface FormValues {
  name: string;
  cardNumber: string;
  phone: string;
  email: string;
  address: string;
}

export const initialValues: FormValues = {
  name: "",
  cardNumber: "",
  phone: "",
  email: "",
  address: "",
};

function Create() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };

  const { mutationPost, isLoading } = useMutationPost({
    module: "customers",
    errorMsg: t("create_customer_error"),
    successMsg: t("create_customer_success"),
    isBack: true,
  });

  return (
    <BasicModal
      title={t("create_customer")}
      open={location.search.includes("?create-new-customer")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      labelClose={t("cancel")}
      labelSubmit={t("create")}
    >
      <Formik
        validationSchema={CREATE_CONTACT}
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          mutationPost.mutate(values);
        }}
      >
        {(props: FormikProps<FormValues>) => <FormAdd {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Create;
