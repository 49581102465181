import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface LANGUAGE {
  label: string;
  id: string;
}

function ChangeLanguage() {
  const { i18n } = useTranslation();
  const language: LANGUAGE[] = [
    {
      label: "English",
      id: "en",
    },
    {
      label: "Indonesia",
      id: "id",
    },
  ];
  console.log(i18n.language);
  return (
    <Box>
      {language?.map((item: any, index: number) => (
        <Button onClick={() => i18n.changeLanguage(item.id)} key={index}>
          {item?.label}
        </Button>
      ))}
    </Box>
  );
}

export default ChangeLanguage;
