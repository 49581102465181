import React from "react";
import { Button, CircularProgress, ButtonProps } from "@mui/material";
import { SxProps } from "@mui/system";

interface LoadingButtonProps extends ButtonProps {
  sx?: SxProps;
  disabled?: boolean;
  variant?: any;
  children?: any;
}

function IconButton({
  sx,
  children,
  disabled,
  ...props
}: LoadingButtonProps): JSX.Element {
  return (
    <Button {...props} sx={{ minHeight: 0, minWidth: 0, ...sx }}>
      {children}
    </Button>
  );
}

export default IconButton;
