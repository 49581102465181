import React from "react";
import { Formik, Form, FormikProps } from "formik";
import { Box, TextField } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import { formListCreateCompany } from "../../../Auth/Company/FormCreateCompany";

function FormCompany({
  getFieldProps,
  values,
}: {
  getFieldProps: any;
  values: any;
}) {
  // console.log(values, ";dari company");
  return (
    <Form>
      <Box sx={{ display: "grid", rowGap: 3 }}>
        {formListCreateCompany?.map((item, index) => (
          <Box key={index}>
            <TextField
              fullWidth
              type={item.type}
              {...getFieldProps(item.name)}
              label={item.placeholder}
            />
          </Box>
        ))}
      </Box>
      <SubmitButton />
    </Form>
  );
}

export default FormCompany;
