import React from "react";
import { Box, Link, TextField, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "../../Component/LoadingButton";
import { useTranslation } from "react-i18next";
import useAuth from "../../Hooks/Mutation/useAuth";
import { LOGIN } from "../../Auth/Validation";
import { cyan } from "@mui/material/colors";

type NavigateFunction = /*unresolved*/ any;
interface FormLogin {
  name: string;
  placeholder: string;
  type: string;
  autofocus: boolean;
  icon?: JSX.Element;
}
interface InitialValue {
  email: string;
  password: string;
}

function LoginForm(): JSX.Element {
  const { t } = useTranslation();
  const { auth, isLoading } = useAuth({
    module: "login",
    successMsg: t("login_success"),
    errorMsg: t("login_error"),
  });
  const navigate: NavigateFunction = useNavigate();
  const [show, setShow] = React.useState<boolean>(false);
  const formList: FormLogin[] = [
    { name: "email", placeholder: t("email"), type: "email", autofocus: true },
    {
      name: "password",
      autofocus: false,
      placeholder: t("password"),
      type: show ? "text" : "password",
      icon: show ? (
        <ListItemButton onClick={() => setShow(!show)}>
          <VisibilityIcon />
        </ListItemButton>
      ) : (
        <ListItemButton onClick={() => setShow(!show)}>
          <VisibilityOffIcon />
        </ListItemButton>
      ),
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: { xs: "grid", sm: "grid", lg: "flex", xl: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography variant="h5" fontWeight={600}>
            <span>
              <i>KasirUsaha</i>
              <span
                style={{ width: "10px", height: "10px", background: "#fff" }}
              ></span>
              <span>.WEB</span>
            </span>
          </Typography> */}
        </Box>
        <Box mt={1}>
          <Typography variant="h4" fontWeight={400}>
            {t("login")}
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LOGIN}
            onSubmit={async (values: InitialValue) => {
              auth.mutate(values);
            }}
          >
            {({ getFieldProps, errors, touched }) => (
              <Form>
                <Box>
                  {formList.map((item: FormLogin, index: number) => (
                    <Box key={index} sx={{ mt: 3 }}>
                      <TextField
                        autoFocus={item?.autofocus}
                        InputProps={{ endAdornment: item.icon }}
                        fullWidth
                        type={item.type}
                        {...getFieldProps(item.name)}
                        label={item.placeholder}
                        error={Boolean(
                          (touched as any)[item.name] &&
                            (errors as any)[item?.name]
                        )}
                        helperText={
                          (touched as any)[item.name] &&
                          (errors as any)[item?.name]
                        }
                      />
                    </Box>
                  ))}
                  <LoadingButton
                    type="submit"
                    fullWidth
                    isLoading={isLoading}
                    sx={{ mt: 3 }}
                    title={t("login")}
                  />
                  <Box mt={3}>
                    <Typography textAlign="center">
                      {t("doest_have_account")}{" "}
                      <a
                        style={{
                          cursor: "pointer",
                          color: cyan[600],
                          fontWeight: 600,
                        }}
                        onClick={() => navigate("/kasir-usaha/register")}
                      >
                        {t("register")}
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginForm;
