import BarChartIcon from "@mui/icons-material/BarChart";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PaymentsIcon from "@mui/icons-material/Payments";
import LogoutIcon from "@mui/icons-material/Logout";
import BuildIcon from "@mui/icons-material/Build";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LayersIcon from "@mui/icons-material/Layers";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { LanguageChange } from "../../Utils/LanguageChange";

type Children = {
  path: string;
  label: any;
};

interface Router {
  path: string;
  label: any;
  children?: Children[];
}
const t: (params: any) => any = (params) => <LanguageChange params={params} />;

export const NavigatorLandingPage: Router[] = [
  {
    path: "?crm",
    label: t("services"),
    children: [
      {
        path: "/kasir-usaha/services/point-sale",
        label: t("poin_of_sale"),
      },
      {
        path: "/kasir-usaha/services/cashier-app",
        label: t("cashier_app"),
      },
      {
        path: "/kasir-usaha/services/customer-management",
        label: t("customer_management"),
      },
      {
        path: "/kasir-usaha/services/stock-management",
        label: t("stock_management"),
      },
      {
        path: "/kasir-usaha/services/invoice",
        label: t("invoice"),
      },
    ],
  },
  {
    path: "/kasir-usaha/pricing",
    label: t("pricing"),
  },
  {
    path: "/kasir-usaha/pricing",
    label: t("bisnis_solution"),
  },
  {
    path: "/kasir-usaha/pricing",
    label: t("contact_us"),
  },
];
