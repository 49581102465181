import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../../Component/BasicModal";
import FormAdd from "./FormAdd";
import { useTranslation } from "react-i18next";
import Qs from "../../../Utils/QS/qss";
import useMutationUpdate from "../../../Hooks/Mutation/useMutationUpdate";
import { CREATE_PRODUCT } from "../../../Auth/Validation";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import useNumberConverter from "../../../Hooks/useNumberConverter";
import { initialValues, FormValues } from "./Create";

function Update() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };

  const { formatCurrency } = useNumberConverter();

  const productId: any = Qs();
  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `products/${productId["update-product-id"]}`,
    enabled: location.search.includes("?update-product"),
  });
  const itemRebuild = {
    ...dataFetchNotPagination,
    categoryId: dataFetchNotPagination?.category,
    branchs: dataFetchNotPagination?.branchs,
    variants: dataFetchNotPagination?.variants?.map((i: any, e: number) => ({
      ...i
    })),
  };
  const { mutationUpdate, isLoading: btnLoading } = useMutationUpdate({
    module: `products`,
    id: productId["update-product-id"],
    errorMsg: t("update_product_error"),
    successMsg: t("update_product_success"),
    isBack: true,
  });

  return (
    <BasicModal
      isLoading={isLoading || btnLoading}
      title={t("update_product")}
      open={location.search.includes("?update-product")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("save")}
    >
      <Formik
        validationSchema={CREATE_PRODUCT}
        innerRef={formRef}
        initialValues={
          dataFetchNotPagination ? { ...itemRebuild } : { ...initialValues }
        }
        enableReinitialize
        onSubmit={(values) => {
          const variants: any = values?.variants.map((item: any) => {
            const purchasePrice: any = formatCurrency(item?.purchasePrice);
            const sellPrice: any = formatCurrency(item?.sellPrice);
            if (typeof item.id === 'number') {
              delete item.id;
            }
            return {
              ...item,
              purchasePrice,
              sellPrice
            }
          });
          const categoryId: string = (values as any)?.categoryId?.id;
          const branchs: string = (values as any)?.branchs;
          const unitId: string = (values as any)?.unit?.id;
          const purchasePrice: any = formatCurrency(values?.purchasePrice);
          const sellPrice: any = formatCurrency(values?.sellPrice);
          const body: FormValues = {
            ...values,
            variants,
            branchs: branchs,
            unit: unitId,
            categoryId: categoryId,
            purchasePrice: purchasePrice,
            sellPrice: sellPrice,
          };
          mutationUpdate.mutate(body);
        }}
      >
        {(props: FormikProps<FormValues>) => <FormAdd {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Update;
