import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import BasicModal from "../../../../Component/BasicModal";
import Qs from "../../../../Utils/QS/qss";
import { ConfirmDialog } from "../../../../Utils/Context/useContextHelper";
import useDataFetch from "../../../../Hooks/Query/useDataFetch";
import { useTranslation } from "react-i18next";
import DetailItem from "../../../../Component/DetailItem";
import useMutationDelete from "../../../../Hooks/Mutation/useMutationDelete";

function Detail() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const { setDialog } = React.useContext(ConfirmDialog);
  const navigate: NavigateFunction = useNavigate();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  const expenseCategoryId = Qs();
  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `expense_categories/${expenseCategoryId["detail-category-expenses"]}`,
    enabled: location.search.includes("?detail-category-expenses="),
  });

  const itemRebuild = [
    {
      label: t("name"),
      value: dataFetchNotPagination?.name,
    },
    {
      label: t("description"),
      value: dataFetchNotPagination?.description,
    },
  ];

  const { mutationDelete } = useMutationDelete({
    module: "expense_categories",
    errorMsg: t("delete_category_expenses_error"),
    successMsg: t("delete_category_expenses_success"),
  });

  const handleDelete = () => {
    setDialog({
      msg: `${t("confirm_delete")} ${dataFetchNotPagination?.name}`,
      handleClick: () => {
        mutationDelete.mutate(expenseCategoryId["detail-category-expenses"]);
        navigate(-1);
      },
    });
  };
  return (
    <BasicModal
      isMobile
      handleUpdate={() =>
        navigate(
          `${location.pathname}?update-category-expenses=${expenseCategoryId["detail-category-expenses"]}`
        )
      }
      handleDelete={handleDelete}
      title={t("detail_expense")}
      isDetail
      isLoading={isLoading}
      open={location.search.includes("?detail-category-expenses=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("close")}
    >
      <DetailItem loading={isLoading} item={itemRebuild} />
    </BasicModal>
  );
}

export default Detail;
