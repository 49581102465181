import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import FormCreateCompany from "./FormCreateCompany";
import { useTranslation } from "react-i18next";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { grey } from "@mui/material/colors";

function CreateCompany(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  React.useEffect(() => {
    const companyID: string | null = localStorage.getItem("companiID");
    if (companyID) {
      navigate("/kasir-usaha/app/dashboard");
    }
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            backgroundImage: "url(/Asset/create-company.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            width: "100%",
            height: "100vh",
            position: "relative",
            display: { xs: "none", sm: "none", lg: "block", xl: "block" },
          }}
        >
          <Box
            sx={{
              bgcolor: "#000",
              width: "100%",
              height: "100%",
              opacity: 0.6,
              position: "absolute",
            }}
          />
          <Box sx={{ height: "100vh" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box sx={{ zIndex: 100 }}>
                <Typography variant="h4" fontWeight={700} color={"#fff"}>
                  {t("official_website")}{" "}
                  <span
                    style={{
                      backgroundColor: "#000",
                      color: "#fff",
                      padding: "0px 5px",
                    }}
                  >
                    {t("kasir_usaha_web")}
                  </span>
                </Typography>
                <Typography variant="h6" color={"#fff"}>
                  {t("slogan")}
                </Typography>
                <Box>
                  {/* <img maxwidth="100%" src="/Auth/rafiki.svg" /> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to right,${grey[100]}, ${grey[50]})`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "relative",
            width: { xs: "100%", sm: "100%", lg: "80%", xl: "80%" },
          }}
        >
          <Box
            sx={{
              backgroundImage: "url(/Asset/bg-login.svg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              width: "100%",
              height: "100%",
              opacity: 0.02,
              position: "absolute",
            }}
          />
          <Paper
            elevation={3}
            sx={{
              px: 3,
              py: 3,
              zIndex: 99,
              width: { xs: "80%", sm: "80%", lg: "50%", xl: "50%" },
              display: "grid",
            }}
          >
            <FormCreateCompany />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateCompany;
