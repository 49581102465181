import { useTranslation } from "react-i18next";

export const LanguageChange: (params: any) => any = ({
  params,
}: {
  params: any;
}) => {
  const { t } = useTranslation<"translation", undefined>();
  return t(params);
};
