import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../../Component/BasicModal";
import FormAdd from "./FormAdd";
import Qs from "../../../Utils/QS/qss";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import { useTranslation } from "react-i18next";
import useMutationPost from "../../../Hooks/Mutation/useMutationPost";
import { initialValues, FormValues } from "./Create";

function Update() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const bannerId: any = Qs();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };

  const { dataFetchNotPagination, isLoading: loadingFetch } = useDataFetch({
    module: `banners/${bannerId["update-banner-id"]}`,
    enabled: location.search.includes("?update-banner-id"),
  });

  const { mutationPost, isLoading } = useMutationPost({
    module: "banners",
    errorMsg: t("update_banner_error"),
    successMsg: t("update_banner_success"),
    isBack: true,
  });

  return (
    <BasicModal
      isLoading={loadingFetch || isLoading}
      title={t("update_banner")}
      open={location.search.includes("?update-banner-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("update")}
    >
      <Formik
        innerRef={formRef}
        initialValues={
          dataFetchNotPagination
            ? {
                ...dataFetchNotPagination,
                isActive: dataFetchNotPagination?.isActive
                  ? t("active")
                  : t("not_active"),
              }
            : { ...initialValues }
        }
        onSubmit={(values) => {
          mutationPost.mutate({
            ...values,
            isActive: values?.isActive?.includes("Active"),
          });
        }}
        enableReinitialize
      >
        {(props: FormikProps<FormValues>) => <FormAdd {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Update;
