import * as yup from "yup";
import { AnyObject, Assign, ObjectShape } from "yup/lib/object";
import { RequiredStringSchema } from "yup/lib/string";
import { MixedSchema } from "yup/lib/mixed";
import { LanguageChange } from "../Utils/LanguageChange";

const t: (params: any) => any = (params) => <LanguageChange params={params} />;

export const LOGIN: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      email: RequiredStringSchema<string | undefined, AnyObject>;
      password: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  email: yup.string().required(t("email_validation")).email(t("mustbe_email")),
  password: yup.string().required(t("password_validation")),
});

export const CATEGORY: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      name: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  name: yup.string().required(t("name_validation")),
});

export const CREATE_SALES: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      categoryId: MixedSchema<any, AnyObject, any>;
      productId: MixedSchema<any, AnyObject, any>;
      branchId: MixedSchema<any, AnyObject, any>;
      quantity: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  categoryId: yup
    .mixed()
    .test("required", t("category_validation"), (params) => {
      if (params) return true;
      return false;
    }),
  productId: yup.mixed().test("required", t("product_validation"), (params) => {
    if (params) return true;
    return false;
  }),
  branchId: yup.mixed().test("required", t("branch_validation"), (params) => {
    if (params) return true;
    return false;
  }),
  quantity: yup.string().required("quantity is required"),
});

export const CASHIER_PAY: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      contactId: MixedSchema<any, AnyObject, any>;
      branchId: MixedSchema<any, AnyObject, any>;
    }
  >
> = yup.object().shape({
  contactId: yup.mixed().test("required", t("product_validation"), (params) => {
    if (params) return true;
    return false;
  }),
  branchId: yup.mixed().test("required", t("branch_validation"), (params) => {
    if (params) return true;
    return false;
  }),
});

export const REGISTER: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      name: RequiredStringSchema<string | undefined, AnyObject>;
      jobTitle: RequiredStringSchema<string | undefined, AnyObject>;
      email: RequiredStringSchema<string | undefined, AnyObject>;
      password: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  name: yup.string().required(t("name_validation")),
  jobTitle: yup.string().required(t("jobTitle_validation")),
  email: yup.string().required(t("email_validation")).email(t("mustbe_email")),
  password: yup.string().required(t("password_validation")),
});

export const CREATE_COMPANY: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      name: RequiredStringSchema<string | undefined, AnyObject>;
      address: RequiredStringSchema<string | undefined, AnyObject>;
      phone: RequiredStringSchema<string | undefined, AnyObject>;
      city: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  name: yup.string().required(t("name_validation")),
  address: yup.string().required(t("address_validation")),
  phone: yup.string().required(t("phone_validation")),
  city: yup.string().required(t("city_validation")),
});
export const CREATE_DISCOUNTS: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      name: RequiredStringSchema<string | undefined, AnyObject>;
      amount: RequiredStringSchema<string | undefined, AnyObject>;
      maxAmount: RequiredStringSchema<string | undefined, AnyObject>;
      percentage: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  name: yup.string().required(t("name_validation")),
  amount: yup.string().required(t("amount_validation")),
  maxAmount: yup.string().required(t("maxAmount_validation")),
  percentage: yup.string().required(t("percentage_validation")),
});

export const CREATE_CONTACT: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      name: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >
> = yup.object().shape({
  name: yup.string().required(t("name_validation")),
});

export const CREATE_PRODUCT: yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      code: RequiredStringSchema<string | undefined, AnyObject>;
      name: RequiredStringSchema<string | undefined, AnyObject>;
      unit: RequiredStringSchema<string | undefined, AnyObject>;
      purchasePrice: RequiredStringSchema<string | undefined, AnyObject>;
      sellPrice: RequiredStringSchema<string | undefined, AnyObject>;
      categoryId: MixedSchema<any, AnyObject, any>;
    }
  >
> = yup.object().shape({
  code: yup.string().required(t("code_validation")),
  name: yup.string().required(t("name_validation")),
  unit: yup.string().required(t("unit_validation")),
  purchasePrice: yup.string().required(t("purchasePrice_validation")),
  sellPrice: yup.string().required(t("sellPrice_validation")),
  categoryId: yup
    .mixed()
    .test("required", t("category_validation"), (params) => {
      if (params) return true;
      return false;
    }),
});
