import React from "react";
import { useSnackbar } from "notistack";
import axios, { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate, NavigateFunction } from "react-router-dom";

function useMutationPost({
  module,
  errorMsg,
  successMsg,
  isBack = false,
  onSuccess,
}: {
  isBack?: boolean;
  module: string;
  errorMsg: string;
  successMsg: string;
  onSuccess?: () => void;
}) {
  const queryClient = useQueryClient();
  const navigate: NavigateFunction = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const TOKEN: string | null = localStorage.getItem("accessToken");
  const COMPANY_ID: string | any = localStorage.getItem("companyID");
  const mutationPost: UseMutationResult = useMutation(
    [module],
    (values: any): Promise<AxiosResponse<any, any>> => {
      return axios.post(
        `${process.env.REACT_APP_NOT_SECRET_CODE}/${module}`,
        {
          ...values,
        },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            slug: COMPANY_ID,
          },
        }
      );
    },
    {
      onSuccess: (res: AxiosResponse<any, any>) => {
        if (isBack) {
          queryClient.invalidateQueries([module]);
          enqueueSnackbar(successMsg, { variant: "success" });
          navigate("?success");
        }
        if (!isBack) {
          queryClient.invalidateQueries([module]);
          enqueueSnackbar(successMsg, { variant: "success" });
        }

        onSuccess?.();
      },
      onError: (err: AxiosResponse<any, any>) => {
        enqueueSnackbar(errorMsg, { variant: "error" });
        console.log(err);
      },
    }
  );
  return { mutationPost: mutationPost, ...mutationPost };
}

export default useMutationPost;
