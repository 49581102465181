import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

interface CONTENT {
  title: string;
  tag: string;
  src: string;
}

function SectionTwo() {
  const { t } = useTranslation<"translation", undefined>();
  const content: CONTENT[] = [
    {
      title: t("one_app"),
      tag: t("one_app_tag"),
      src: "/Asset/Section/section-two_1.svg",
    },
    {
      title: t("customer_service"),
      tag: t("customer_service_tag"),
      src: "/Asset/Section/section-two_2.svg",
    },
    {
      title: t("easy_app"),
      tag: t("easy_app_tag"),
      src: "/Asset/Section/section-two_3.svg",
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography textAlign="center" variant="h3" fontWeight={600}>
        {t("why_us")}
      </Typography>
      <Typography textAlign="center" variant="h6">
        {t("wahy_us_tag")}
      </Typography>
      <Box mt={10}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        >
          {content?.map((item: CONTENT, index: number) => (
            <Grid item xs={4} key={index}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img src={item?.src} />
                </Box>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Typography fontWeight={600} variant="h6">
                    {item?.title}
                  </Typography>
                  <Typography>{item?.tag}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default SectionTwo;
