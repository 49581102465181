import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SideMenuApp } from "./SideMenuApp";
import { ConfirmDialog } from "../Utils/Context/useContextHelper";
import { grey } from "@mui/material/colors";

const drawerWidth = 340;

interface Props {
  window?: () => Window;
}
type NavigateFunction = /*unresolved*/ any;
type Location = /*unresolved*/ any;

export default function ResponsiveDrawer(props: Props) {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const [expanded, setExpanded] = React.useState<number | string | false>(
    false
  );
  const [subExpanded, setSubExpanded] = React.useState<number | string | false>(
    false
  );
  const { setDialog } = React.useContext(ConfirmDialog);
  const handleChange =
    (panel: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handleChangeSubExpanded =
    (panel: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSubExpanded(isExpanded ? panel : false);
    };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };
  const handleClickSubMenu = (child: any): void => {
    navigate(child?.path);
    setMobileOpen(false);
  };
  const handleClickSubMenuExpanded = (child: any): void => {
    navigate(child?.path);
    setMobileOpen(false);
  };
  const isPadding: any = location?.pathname?.includes("cashier");
  React.useEffect(() => {
    const accessToken: string | null = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/kasir-usaha/login");
    }
  }, []);

  React.useEffect(() => {
    const companyID: string | null = localStorage.getItem("companyID");
    if (location.pathname.includes("master")) {
      setExpanded("?master");
    }
    if (
      location.pathname.includes("sales") ||
      location.pathname.includes("manage-expenses") ||
      location.pathname.includes("category-expenses") ||
      location.pathname.includes("purchase")
    ) {
      setExpanded("?transaction");
      setSubExpanded("?expenses");
    }
    if (
      location.pathname.includes("branch-list") ||
      location.pathname.includes("employee") ||
      location.pathname.includes("cashier-access-code") ||
      location.pathname.includes("shift") ||
      location.pathname.includes("struck") ||
      location.pathname.includes("staff-role")
    ) {
      setExpanded("?branch");
    }
    if (
      location.pathname.includes("raw-material") ||
      location.pathname.includes("category-raw-material") ||
      location.pathname.includes("recipe")
    ) {
      setExpanded("?master");
      setSubExpanded("?raw-material");
    }
    if (
      location.pathname.includes("supplier") ||
      location.pathname.includes("customer")
    ) {
      setExpanded("?contact");
    }
    if (location.pathname.includes("change-company")) {
      setExpanded("?setting");
    }
    if (location.pathname.includes("report")) {
      setExpanded("?report");
    }
    if (!companyID) {
      navigate("/kasir-usaha/choose-company");
    }
  }, []);

  const drawer = (
    <Box>
      <Toolbar>
        <Typography color="#fff" variant="h5" fontWeight={600}>
          <span>
            <i>KasirUsaha</i>
            <span
              style={{ width: "10px", height: "10px", background: "#fff" }}
            ></span>
          </span>
        </Typography>
      </Toolbar>
      <List>
        {SideMenuApp?.map<JSX.Element>((item: any, index: number) => {
          if (item?.children) {
            return (
              <Accordion
                disableGutters
                sx={{ boxShadow: "none" }}
                key={index}
                expanded={expanded === item?.path}
                onChange={handleChange(item?.path)}
              >
                <AccordionSummary
                  sx={{ bgcolor: "#000" }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                >
                  <ListItemButton
                    sx={{
                      bgcolor: item.path.includes(location.pathname)
                        ? "#fff"
                        : "",
                      p: 0,
                      "&:hover": {
                        bgcolor: item.path.includes(location.pathname)
                          ? "#fff"
                          : grey[800],
                      },
                    }}
                  >
                    <ListItem>
                      <ListItemIcon sx={{ color: "#fff" }}>
                        {item?.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "#fff" }}
                        primary={
                          <React.Fragment>
                            <Typography>{item?.label}</Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </ListItemButton>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    bgcolor: grey[900],
                    boxShadow: "none",
                  }}
                >
                  {item?.children?.map((child: any, index: number) => {
                    if (child?.subChildren) {
                      return (
                        <Accordion
                          disableGutters
                          sx={{ boxShadow: "none" }}
                          key={index}
                          expanded={subExpanded === child?.path}
                          onChange={handleChangeSubExpanded(child?.path)}
                        >
                          <AccordionSummary
                            sx={{ bgcolor: grey[900] }}
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#fff" }} />
                            }
                          >
                            <ListItemButton
                              sx={{
                                bgcolor: child.path.includes(location.pathname)
                                  ? "#fff"
                                  : "",
                                p: 0,
                                "&:hover": {
                                  bgcolor: child.path.includes(
                                    location.pathname
                                  )
                                    ? "#fff"
                                    : grey[800],
                                },
                              }}
                            >
                              <ListItem>
                                <ListItemIcon
                                  sx={{
                                    color: item.path.includes(location.pathname)
                                      ? "#000"
                                      : "#fff",
                                  }}
                                >
                                  {child?.icon}
                                </ListItemIcon>
                                <ListItemText
                                  sx={{ color: "#fff", p: 0 }}
                                  primary={
                                    <React.Fragment>
                                      <Typography>{child?.label}</Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            </ListItemButton>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              bgcolor: grey[900],
                              boxShadow: "none",
                            }}
                          >
                            {child?.subChildren?.map(
                              (child: any, index: number) => {
                                return (
                                  <ListItem key={index}>
                                    <ListItemButton
                                      sx={{
                                        bgcolor: child.path.includes(
                                          location.pathname
                                        )
                                          ? "#fff"
                                          : "",
                                        "&:hover": {
                                          bgcolor: child.path.includes(
                                            location.pathname
                                          )
                                            ? "#fff"
                                            : grey[800],
                                        },
                                      }}
                                      onClick={() =>
                                        handleClickSubMenuExpanded(child)
                                      }
                                    >
                                      <ListItemText
                                        sx={{
                                          color: child.path.includes(
                                            location.pathname
                                          )
                                            ? "#000"
                                            : "#fff",
                                        }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              fontSize={14}
                                              fontWeight={300}
                                            >
                                              {child?.label}
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              }
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                    return (
                      <ListItem key={index}>
                        <ListItemButton
                          sx={{
                            bgcolor: child.path.includes(location.pathname)
                              ? "#fff"
                              : "",
                            "&:hover": {
                              bgcolor: child.path.includes(location.pathname)
                                ? "#fff"
                                : grey[800],
                            },
                          }}
                          onClick={() => handleClickSubMenu(child)}
                        >
                          <ListItemText
                            sx={{
                              color: child.path.includes(location.pathname)
                                ? "#000"
                                : "#fff",
                            }}
                            primary={
                              <React.Fragment>
                                <Typography fontSize={14} fontWeight={300}>
                                  {child?.label}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          }
          return (
            <ListItem key={index}>
              <ListItemButton
                sx={{
                  bgcolor: item.path.includes(location.pathname) ? "#fff" : "",
                  "&:hover": {
                    bgcolor: item.path.includes(location.pathname)
                      ? "#fff"
                      : grey[800],
                  },
                }}
                onClick={() => {
                  if (item.path === "?log-out") {
                    setDialog({
                      msg: `${t("log_out")}?`,
                      variant: "log out",
                      handleClick: () => {
                        localStorage.clear();
                        navigate("/");
                      },
                    });
                    return false;
                  }
                  navigate(item?.path);
                  setMobileOpen(!mobileOpen);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: item.path.includes(location.pathname)
                      ? "#000"
                      : "#fff",
                  }}
                >
                  {item?.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: item.path.includes(location.pathname)
                      ? "#000"
                      : "#fff",
                  }}
                  primary={
                    <React.Fragment>
                      <Typography>{item?.label}</Typography>
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          display: { sm: "block", md: "none" },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <Typography color="#fff" variant="h5" fontWeight={600}>
              <span>
                <i>KasirUsaha</i>
                <span
                  style={{ width: "10px", height: "10px", background: "#fff" }}
                ></span>
              </span>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#000",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#000",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: isPadding
            ? 0
            : {
                xs: 0,
                sm: 3,
                md: 3,
                lg: 3,
              },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{ display: { sm: "block", md: "none" } }} />
        <Outlet />
      </Box>
    </Box>
  );
}
