import React from "react";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import AutoCompleteForm from "../../../Component/AutoCompleteForm";

interface OPTION {
  label?: string;
}

interface FormAddCategory {
  name: string;
  label: string;
  isAutoComplete?: boolean;
  option?: OPTION[];
}
function FormAdd({
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  values,
}: {
  getFieldProps: any;
  setFieldValue: any;
  values: any;
  errors: any;
  touched: any;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  console.log(values);
  const formList: FormAddCategory[] = [
    {
      name: "name",
      label: t("name"),
    },
    {
      name: "type",
      label: t("type"),
      isAutoComplete: true,
      option: [{ label: "External" }, { label: "Internal" }],
    },
    {
      name: "to",
      label: t("to_href"),
    },
    {
      name: "imageUrl",
      label: t("image"),
    },
    {
      name: "isActive",
      label: t("status"),
      isAutoComplete: true,
      option: [{ label: t("active") }, { label: t("not_active") }],
    },
  ];
  return (
    <Form>
      <Box sx={{ display: "grid", gap: 3 }}>
        {formList?.map((item, index) => {
          if (item?.isAutoComplete) {
            return (
              <AutoCompleteForm
                key={index}
                label={item?.label}
                value={values[item?.name]}
                hardCodeOption={item.option?.map((i: any) => i?.label)}
                id={item?.name}
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                touched={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
                onChange={(e: any, i: any) => {
                  setFieldValue(item.name, i);
                }}
              />
            );
          }
          return (
            <TextField
              {...getFieldProps(item.name)}
              key={index}
              label={item?.label}
              error={Boolean(
                (touched as any)[item.name] && (errors as any)[item?.name]
              )}
              helperText={
                (touched as any)[item.name] && (errors as any)[item?.name]
              }
            />
          );
        })}
        <SubmitButton />
      </Box>
    </Form>
  );
}

export default FormAdd;
