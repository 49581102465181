import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import BasicModal from "../../../Component/BasicModal";
import Qs from "../../../Utils/QS/qss";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import DetailItem from "../../../Component/DetailItem";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";

function Detail() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const { setDialog } = React.useContext(ConfirmDialog);
  const navigate: NavigateFunction = useNavigate();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  const supplierId = Qs();
  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `company_users/${supplierId["detail-company-id"]}`,
    enabled: location.search.includes("?detail-company-id="),
  });

  const itemRebuild = [
    {
      label: t("name"),
      value: dataFetchNotPagination?.company?.name,
    },

    {
      label: t("phone"),
      value: dataFetchNotPagination?.company?.phone,
    },

    {
      label: t("address"),
      value: dataFetchNotPagination?.company?.address,
    },
    {
      label: t("createdAt"),
      value: dataFetchNotPagination?.company?.createdAt,
      isDate: true,
    },
  ];

  const { mutationDelete } = useMutationDelete({
    module: "companies",
    errorMsg: t("delete_company_error"),
    successMsg: t("delete_company_success"),
  });

  const handleDelete = () => {
    setDialog({
      msg: `${t("confirm_delete")} ${dataFetchNotPagination?.company?.name}`,
      handleClick: () => {
        mutationDelete.mutate(supplierId["detail-company-id"]);
        navigate(-1);
      },
    });
  };
  return (
    <BasicModal
      isMobile
      isSetActive
      handleSetActive={() => {
        localStorage.setItem("companyID", dataFetchNotPagination?.company?.id);
        navigate("/");
      }}
      handleUpdate={() =>
        navigate(
          `${location.pathname}?update-company-id=${supplierId["detail-company-id"]}`
        )
      }
      handleDelete={handleDelete}
      title={t("detail_company")}
      isDetail
      isLoading={isLoading}
      open={location.search.includes("?detail-company-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("close")}
    >
      <DetailItem loading={isLoading} item={itemRebuild} />
    </BasicModal>
  );
}

export default Detail;
