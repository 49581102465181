import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Button, CircularProgress } from "@mui/material";
import { SxProps } from "@mui/system";
import useDataFetch from "../Hooks/Query/useDataFetch";
import { debounce } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import useMutationPost from "../Hooks/Mutation/useMutationPost";

interface Film {
  title: string;
  year: number;
}

interface FIELD {
  sx?: SxProps;
  label?: string;
  size?: any;
  module: any;
  onChange: (e: any, i: any) => void;
  touched?: any;
  error?: any;
  value?: any;
  isCreate?: any;
}

export default function AsyncAutoComplete({
  sx,
  label,
  size = "large",
  module,
  onChange,
  touched,
  error,
  value,
  isCreate,
}: FIELD) {
  const { t } = useTranslation<"translation", undefined>();
  const [search, setSearch] = React.useState<string>("");
  const { dataFetch, isLoading, refetch, isFetching } = useDataFetch({
    module,
    enabled: false,
    s: search ? `&s={"name":{"$cont":"${search}"}}` : null,
  });
  return (
    <Box>
      <Autocomplete
        sx={{ ...sx }}
        onOpen={() => refetch()}
        onInputChange={debounce((e) => {
          if (e?.target) {
            setSearch(e.target.value);
          }
        }, 500)}
        onChange={onChange}
        loading={isLoading}
        getOptionLabel={(dataFetch: any) => dataFetch.name || ""}
        options={dataFetch || []}
        value={value || ""}
        size={size}
        renderInput={(params) => (
          <TextField
            label={label}
            error={error}
            {...params}
            helperText={touched}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
