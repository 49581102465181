import { green, grey, orange, red } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#00D3FF",
    },
    info: {
      main: grey[500],
    },
    error: {
      main: red[500],
    },
    warning: {
      main: orange[500],
    },
    success: {
      main: green[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: 600,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
  },
});

export default function MaterialUiTheme({
  children,
}: {
  children: any;
}): JSX.Element {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
