import {
  Box,
  Typography,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import React from "react";

import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import TableComponent from "../../Component/TableComponent";
import moment from "moment";
import Qs from "../../Utils/QS/qss";
import "moment/locale/id";
import ListItems from "../../Component/ListItems";
import SpaceBetween from "../../Component/SpaceBetween";
import { useTranslation } from "react-i18next";
import FilterUtility from "../../Component/FilterUtility";
import Export from "../../Component/Export";
import Filter from "../Dashboard/Filter";

interface TABLEHEAD {
  id: string;
  label: string;
  isCheckBox?: boolean;
  isCurrency?: boolean;
  isDate?: boolean;
}

function SalesReport(): JSX.Element {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const reportSales: any = Qs();
  const { dataFetch, isLoading, setPage, pageCount, isFetching, total } =
    useDataFetch({
      module: "sales_items",
      s: reportSales?.s,
    });
  const salesItem = dataFetch?.map((i: any) => ({
    ...i,
    contact: i?.contact?.name,
    productName: i?.product?.name,
    isCheckBox: false,
  }));


  const tableHead: TABLEHEAD[] = [
    {
      id: "createdAt",
      label: t("createdAt"),
      isDate: true,
    },
    {
      id: "productName",
      label: t("product"),
    },
    {
      id: "quantity",
      label: t("quantity"),
    },
    {
      id: "total",
      label: "Total",
      isCurrency: true,
    },
  ];
  const isCreate = location.pathname.includes("/create-sales");
  // const handleClick: (item: any) => void = (item: any) => {
  //   navigate(`?detail-sales-id=${item?.id}`);
  // };

  return (
    <Box>
      <Box
        sx={{
          display: isCreate ? "none" : "block",
        }}
      >
        <SpaceBetween>
          <Box sx={{ display: "grid" }}>
            <Typography fontWeight={700} variant="h5">
              {t("sales_report")}
            </Typography>
            <Typography fontSize={14}>
              Total : {total} {isLoading && <CircularProgress size={13} />} {t("sales")}
            </Typography>
          </Box>
          <FilterUtility
            isFetching={isFetching}
            enableSortDate
            setPage={setPage}
          />

          <Box
            sx={{
              display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
              alignItems: "center",
              gap: 2,
            }}
          >
            <Export
              isLoading={isLoading}
              titleReport={t("sales_report")}
              tableHead={tableHead}
              data={salesItem}
            />
            <Filter />
          </Box>
        </SpaceBetween>
        <Box mt={3}>
          <TableComponent
            isOption={false}
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            isLoading={isLoading}
            tableHead={tableHead}
            tableBody={salesItem}
          />
          <Box sx={{ display: "grid", rowGap: 2 }}>
            <ListItems
              isLoading={isLoading}
              pageCount={pageCount}
              onChange={(event: any, page: number) => setPage(page)}
              body={salesItem}
              head={tableHead}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SalesReport;
