import React from "react";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";

interface FormAddCategory {
  name: string;
  label: string;
}
function FormAdd({
  getFieldProps,
  touched,
  errors,
}: {
  getFieldProps: any;
  errors: any;
  touched: any;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const formList: FormAddCategory[] = [
    {
      name: "name",
      label: t("promo_name"),
    },
    {
      name: "description",
      label: t("date_expired"),
    },
    {
      name: "description",
      label: t("minimum_purchase"),
    },
  ];
  return (
    <Form>
      <Box sx={{ display: "grid", gap: 3 }}>
        {formList?.map((item, index) => (
          <TextField
            {...getFieldProps(item.name)}
            key={index}
            label={item?.label}
            error={Boolean(
              (touched as any)[item.name] && (errors as any)[item?.name]
            )}
            helperText={
              (touched as any)[item.name] && (errors as any)[item?.name]
            }
          />
        ))}
        <SubmitButton />
      </Box>
    </Form>
  );
}

export default FormAdd;
