import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { useNavigate, NavigateFunction } from "react-router-dom";

function NotFound(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <GppMaybeIcon sx={{ color: grey[700] }} />
          <Typography variant="h5" fontWeight={700} color={grey[700]}>
            404 tidak ditemukan
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="h6" color={grey[800]}>
            cobalah untuk:{" "}
          </Typography>
          <Box>
            <Typography variant="h6" color={grey[800]}>
              - cek koneksi internet
            </Typography>
            <Typography variant="h6" color={grey[800]}>
              - pastikan menulis alamat URL dengan benar
            </Typography>
            <Typography variant="h6" color={grey[800]}>
              - sesuaikan alamat dengan parameter URL
            </Typography>
          </Box>
        </Box>
        <Button
          onClick={() => navigate("/")}
          sx={{ mt: 3 }}
          fullWidth
          variant="contained"
        >
          Beranda
        </Button>
      </Box>
    </Box>
  );
}

export default NotFound;
