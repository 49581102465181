import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FormatCurrency } from "../../Component/FormatCurrency";
import useNumberConverter from "../../Hooks/useNumberConverter";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent } from "react";
import {
  useLocation,
  Location,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import NumberFormat from "react-number-format";
import useMutationPost from "../../Hooks/Mutation/useMutationPost";
import BasicModal from "../../Component/BasicModal";
import SuccessPayment from "./SuccessPayment";
import TabComponent from "../../Component/TabComponent";

interface MONEY_TEMPLATE_NON_CASH {
  src: string;
  value: string;
}
interface MONEY_TEMPLATE {
  value: number;
  label: string;
}
function Payment({ data, setData }: { data: any; setData: any }): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const [money, setMoney] = React.useState<number | any>(0);
  const [image, setImage] = React.useState<any>({ index: 0, name: "" });
  const [tabValue, setTabValue] = React.useState<number>(1);
  const { formatCurrency } = useNumberConverter();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const items: any = sessionStorage.getItem("cart");
  const cart: any = JSON.parse(items) || [];
  const total: any = FormatCurrency(cart?.total);
  const passMoney: any = cart?.total;
  const isDisabled: boolean = passMoney - 1 >= money;
  const change: number = money - passMoney;
  const moneyChangeFromCustomer: void = FormatCurrency(
    change <= 0 ? 0 : change
  );
  const customerMoneyTemplate: MONEY_TEMPLATE[] = [
    {
      value: 2000,
      label: "Rp2.000,00",
    },
    {
      value: 5000,
      label: "Rp5.000,00",
    },
    {
      value: 10000,
      label: "Rp10.000,00",
    },
    {
      value: 20000,
      label: "Rp20.000,00",
    },
    {
      value: 50000,
      label: "Rp50.000,00",
    },
    {
      value: 75000,
      label: "Rp75.000,00",
    },
    {
      value: 100000,
      label: "Rp100.000,00",
    },
    {
      value: passMoney || 0,
      label: t("pass_money"),
    },
  ];
  const customerMoneyTemplateNonCash: MONEY_TEMPLATE_NON_CASH[] = [
    {
      src: "/Asset/Payment/BANK-BNI.png",
      value: "bank-bni",
    },
    {
      src: "/Asset/Payment/BANK-BRI.png",
      value: "bank-bri",
    },
    {
      src: "/Asset/Payment/BANK-BSI.png",
      value: "bank-bsi",
    },
    {
      src: "/Asset/Payment/BCA.png",
      value: "bank-bca",
    },
    {
      src: "/Asset/Payment/dana.png",
      value: "Ewallet-dana",
    },
    {
      src: "/Asset/Payment/Gopay.png",
      value: "Ewallet-gopay",
    },
    {
      src: "/Asset/Payment/Link-Aja.png",
      value: "Ewallet-linkAja",
    },
    {
      src: "/Asset/Payment/Mandiri.png",
      value: "bank-mandiri",
    },
    {
      src: "/Asset/Payment/Ovo.png",
      value: "Ewallet-ovo",
    },
    {
      src: "/Asset/Payment/Shopeepay.png",
      value: "Ewallet-shoopePay",
    },
  ];

  const { mutationPost, isLoading } = useMutationPost({
    module: "sales",
    errorMsg: t("create_sales_error"),
    successMsg: t("create_sales_success"),
    isBack: true,
  });
  const handleClick: (item: any) => void = (item: any) => {
    setMoney(item.value);
  };

  const handleClose: () => void = () => {
    navigate(-1);
    sessionStorage.clear();
    setMoney(0);
  };

  const handleChange: (i: ChangeEvent<HTMLInputElement>) => void = (
    i: ChangeEvent<HTMLInputElement>
  ) => {
    setMoney(formatCurrency(i.target.value));
  };
  const handleSubmit: () => void = () => {
    const body: any = {
      ...cart,
      change: moneyChangeFromCustomer,
      paymentMethod: image.name,
    };
    localStorage.setItem("dataPayment", JSON.stringify(body));
    mutationPost.mutate(cart);
  };

  return (
    <Box>
      <BasicModal
        isLoading={isLoading}
        disabled={isDisabled && Boolean(tabValue)}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        title={t("payment")}
        open={location.search.includes("?payment")}
        labelClose={t("cancel")}
        labelSubmit={t("pay")}
      >
        <Box>
          <Box>
            <Typography
              variant="h6"
              fontWeight={600}
              textAlign="center"
              color={grey[600]}
            >
              Total
            </Typography>
            <Typography variant="h5" fontWeight={600} textAlign="center">
              {total}
            </Typography>
            <TabComponent
              setTabValue={setTabValue}
              labelOne="Tunai"
              labelTwo="Non Tunai"
              itemTwo={
                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                    gap={2}
                    p={2}
                  >
                    {customerMoneyTemplateNonCash?.map(
                      (item: any, index: number) => (
                        <Grid xs={2} item key={index}>
                          <Box
                            component="span"
                            onClick={() =>
                              setImage({ index: index, name: item.value })
                            }
                            sx={{
                              filter:
                                image.index === index
                                  ? "none"
                                  : "grayscale(100%)",
                              cursor: "pointer",
                              P: 2,
                            }}
                          >
                            <img width="100%" src={item.src} />
                            {/* <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <Box
                                sx={{
                                  width: "50%",
                                  height: "1px",
                                  bgcolor: "#000",
                                  boxShadow:  image === index ? "0px 0px 20px 5px #000":"none",
                                }}
                              />
                            </Box> */}
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              }
              itemOne={
                <Box>
                  <Typography variant="h6" sx={{ mt: 2 }} color={grey[600]}>
                    {t("customer_money")}
                  </Typography>
                  <NumberFormat
                    customInput={TextField}
                    thousandSeparator
                    value={money}
                    size="small"
                    onChange={handleChange}
                    fullWidth
                    prefix="Rp"
                  />
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    p={2}
                  >
                    {customerMoneyTemplate?.map((item: any, index: number) => (
                      <Grid item key={index}>
                        <Button
                          disabled={item.value + 1 <= passMoney}
                          onClick={() => handleClick(item)}
                          variant="contained"
                        >
                          {item?.label}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              }
            />
          </Box>
        </Box>
      </BasicModal>
      <SuccessPayment
        data={cart}
        customerMoney={money}
        setData={setData}
        change={moneyChangeFromCustomer}
      />
    </Box>
  );
}

export default Payment;
