import React from "react";
import { Formik, Form, FormikProps } from "formik";
import { Box, TextField, Typography } from "@mui/material";
import SubmitButton from "../../Component/SubmitButton";
import { useTranslation } from "react-i18next";
import TabComponent from "../../Component/TabComponent";
import PercentIcon from "@mui/icons-material/Percent";
import NumberFormat from "react-number-format";

function FormCreateBranch({
  getFieldProps,
  errors,
  touched,
  setTabValue,
  values,
}: {
  getFieldProps: any;
  errors: any;
  touched: any;
  setTabValue?: any;
  values?: any;
}) {
  const { t } = useTranslation<"translation", undefined>();
  return (
    <Form>
      <Box>
        <TabComponent
          values={values}
          setTabValue={setTabValue}
          labelOne="Diskon rupiah"
          labelTwo="Diskon persen"
          itemTwo={
            <Box sx={{ display: "grid", gap: 2 }}>
              <TextField {...getFieldProps("name")} label={t("name")} />
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  InputProps={{ endAdornment: <PercentIcon /> }}
                  type="number"
                  label="maksimal 100%"
                  {...getFieldProps("percentage")}
                />
                <NumberFormat
                  customInput={TextField}
                  label="Masukan nilai maksimum harga dalam rupiah."
                  thousandSeparator
                  prefix="Rp"
                  fullWidth
                  {...getFieldProps("maxAmount")}
                />
              </Box>
            </Box>
          }
          itemOne={
            <Box sx={{ display: "grid", gap: 2 }}>
              <TextField {...getFieldProps("name")} label={t("name")} />
              <Box sx={{ display: "flex", gap: 2 }}>
                <NumberFormat
                  customInput={TextField}
                  label="Jumlah."
                  thousandSeparator
                  prefix="Rp"
                  fullWidth
                  {...getFieldProps("amount")}
                />
                <NumberFormat
                  customInput={TextField}
                  label="Jumlah maksimal."
                  thousandSeparator
                  prefix="Rp"
                  fullWidth
                  {...getFieldProps("maxAmount")}
                />
              </Box>
            </Box>
          }
        />
      </Box>
      <SubmitButton />
    </Form>
  );
}

export default FormCreateBranch;
