import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../Component/BasicModal";
import Form from "./Form";
import { useTranslation } from "react-i18next";
import useNumberConverter from "../../Hooks/useNumberConverter";
import useMutationPost from "../../Hooks/Mutation/useMutationPost";

export interface InitialValue {
  name: string;
  amount: string;
  maxAmount: string;
  percentage: string;
  type: string;
}
export const initialValue: InitialValue = {
  name: "",
  amount: "",
  maxAmount: "",
  percentage: "",
  type: "",
};
type FormValues = /*unresolved*/ any;

function Create(): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const location: Location = useLocation();
  const [tabValue, setTabValue] = React.useState<number>(1);
  const navigate: NavigateFunction = useNavigate();
  const { formatCurrency } = useNumberConverter();
  const { mutationPost, isLoading } = useMutationPost({
    module: "discounts",
    errorMsg: t("create_discount_error"),
    successMsg: t("create_discount_success"),
    isBack: true,
  });

  /// stackoverflow start
  /// asked : https://stackoverflow.com/questions/66145167/object-is-possibly-null-typescript-react-useref-formik-innerref
  /// answered by Влад : https://stackoverflow.com/users/5463775/%d0%92%d0%bb%d0%b0%d0%b4
  const formRef = React.useRef<FormikProps<FormValues>>(null);
  ///stackoverflow ends

  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  return (
    <BasicModal
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      handleClose={() => navigate(-1)}
      title={t("create_discount")}
      open={location.search.includes("?create-discount")}
      labelClose={t("cancel")}
      labelSubmit={t("create")}
    >
      <Formik
        innerRef={formRef}
        // validationSchema={CREATE_DISCOUNTS}
        initialValues={initialValue}
        onSubmit={(values) => {
          const body = {
            name: values?.name,
            amount: Boolean(tabValue) ? formatCurrency(values.amount) : 0,
            maxAmount: formatCurrency(values.maxAmount),
            percentage: Boolean(tabValue)
              ? 0
              : formatCurrency(values.percentage),
            type: Boolean(tabValue) ? "nominal" : "percentage",
          };
          mutationPost.mutate(body);
        }}
        enableReinitialize
      >
        {(props) => <Form {...props} setTabValue={setTabValue} />}
      </Formik>
    </BasicModal>
  );
}

export default Create;
