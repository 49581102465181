import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../../Component/BasicModal";
import FormAdd from "./FormAdd";
import { useTranslation } from "react-i18next";
import useMutationPost from "../../../Hooks/Mutation/useMutationPost";
import { CATEGORY } from "../../../Auth/Validation";

export interface FormValues {
  productId: string;
  ingredient: any;
}

export const initialValues: FormValues = {
  productId: "",
  ingredient: [
    {
      id: Math.floor(Math.random() * 19998 + 1),
      ingredientName: null,
      variantQuantity: "",
    },
  ],
};

function Create() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };

  const { mutationPost, isLoading } = useMutationPost({
    module: "product_categories",
    errorMsg: t("create_category_error"),
    successMsg: t("create_category_success"),
    isBack: true,
  });

  return (
    <BasicModal
      title={t("create_recipe")}
      open={location.search.includes("?create-new-recipe")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("create")}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values);
        }}
        enableReinitialize
      >
        {(props: FormikProps<FormValues>) => <FormAdd {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Create;
