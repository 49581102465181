import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  useLocation,
  Location,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import BasicModal from "../../Component/BasicModal";
import { useState, useRef } from "react";

import "moment/locale/id";
import { FormatCurrency } from "../../Component/FormatCurrency";
import Print58mm from "../../Component/Print58mm";

function SuccessPayment({
  change,
  customerMoney,
  data,
  setData,
}: {
  change: any;
  data: any;
  setData: any;
  customerMoney: number | any;
}): JSX.Element {
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  const handleClose: () => void = () => {
    navigate(-2);
    sessionStorage.clear();
    localStorage.removeItem("dataPayment");
    setData([]);
  };
  const dataPaymentTemperory: any = localStorage.getItem("dataPayment");
  const dataJsonParse: any = JSON.parse(dataPaymentTemperory);
  const itemRebuild: any = dataJsonParse?.items;
  const total: any = FormatCurrency(dataJsonParse?.total);
  const moneyCustomer: any = FormatCurrency(customerMoney);
  const dataStorange: any = localStorage.getItem("profileCompany");
  const companyProfile: any = JSON.parse(dataStorange);
  const addressCompany: string = `${companyProfile?.address},${companyProfile?.city}`;
  const phoneCompany: string = `phone:${companyProfile?.phone}`;

  return (
    <BasicModal
      isDetail
      handleClose={handleClose}
      title={t("create_sales_success")}
      open={location.search.includes("?success")}
      labelClose={t("close")}
      labelSubmit={t("pay")}
    >
      <Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box>
              <img src="/Asset/success.png" />
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {/* <Typography fontSize={20} fontWeight="bold">
            {t("create_sales_success")}
          </Typography> */}
            <Typography fontSize={20} fontWeight="bold">
              {t("change")} : {dataJsonParse?.change}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "grid", rowGap: 2, mt: 2 }}>
          {/* <Button variant="contained">make new transaction</Button> */}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Print58mm
            companyProfile={companyProfile}
            addressCompany={addressCompany}
            phoneCompany={phoneCompany}
            data={dataJsonParse}
            itemRebuild={itemRebuild}
            total={total}
            moneyCustomer={moneyCustomer}
            change={change}
          />
        </Box>
      </Box>
    </BasicModal>
  );
}

export default SuccessPayment;
