import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext, memo } from "react";
import { ConfirmDialog } from "../../Utils/Context/useContextHelper";
import { useNavigate, NavigateFunction } from "react-router-dom";
import SpaceBetween from "../../Component/SpaceBetween";
import Create from "./Create";
import Qs from "../../Utils/QS/qss";
import useMutationDelete from "../../Hooks/Mutation/useMutationDelete";
import { useTranslation } from "react-i18next";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import TableComponent from "../../Component/TableComponent";
import Update from "./Update";
import Searching from "../../Utils/Search/useSearch";
import ListItems from "../../Component/ListItems";
import Detail from "./Detail";
import FilterUtility from "../../Component/FilterUtility";
import BasicSpeedDial from "../../Component/BasicSpeedDial";

interface TABLEHEAD {
  id: string;
  label: string;
  isDate?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
}
interface TABLEBODY {
  code: string;
  name: string;
  createdAt: string;
  purchasePrice: number;
  sellPrice: number;
  unit: string;
}

function Discount(): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const { setDialog } = useContext(ConfirmDialog);
  const addBranch = (): void => {
    navigate("?create-discount");
  };
  const discount: any = Qs();
  const { dataFetch, isLoading, setPage, pageCount, isFetching, total } =
    useDataFetch({
      module: "discounts",
      s: discount?.s,
    });
  const { mutationDelete } = useMutationDelete({
    module: "discounts",
    errorMsg: t("delete_branch_error"),
    successMsg: t("delete_branch_success"),
  });
  const handleUpdate: (item: any) => void = (item: any) => {
    navigate(`?update-discount-id=${item?.id}`);
  };
  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-discount-id=${item?.id}`);
  };
  const handleDelete: (item: any) => void = (item: any) => {
    setDialog({
      msg: `are you sure wanna delete ${item?.name}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
      },
    });
  };
  const itemRebuild: TABLEBODY[] = dataFetch?.map((i: any) => ({
    ...i,
  }));

  const tableHead: TABLEHEAD[] = [
    {
      id: "name",
      label: t("name"),
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      isDate: true,
    },
    {
      id: "amount",
      label: t("amount"),
      isCurrency: true,
    },
    {
      id: "percentage",
      label: t("percentage"),
      isPercent: true,
    },
    {
      id: "maxAmount",
      label: t("maxAmount"),
      isCurrency: true,
    },
    {
      id: "type",
      label: t("type"),
    },
  ];
  return (
    <Box>
      <SpaceBetween>
        <Box sx={{ display: "grid" }}>
          <Typography fontWeight={700} variant="h5">
            {t("discount")}
          </Typography>
          <Typography fontSize={14}>
            Total : {total} {isLoading && <CircularProgress size={13} />} {t("discount")}
          </Typography>
        </Box>
        <FilterUtility isFetching={isFetching} setPage={setPage} />
        <Box
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            gap: 2,
          }}
        >
          <Searching size="small" label={t("search")} isFetching={isFetching} />
          <Button
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            onClick={addBranch}
            variant="contained"
          >
            {t("create_discount")}
          </Button>
        </Box>
      </SpaceBetween>
      <Box mt={3}>
        <TableComponent
          isOption
          pageCount={pageCount}
          onChange={(event: any, page: number) => setPage(page)}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          handleDetail={handleDetail}
          isLoading={isLoading}
          tableHead={tableHead}
          tableBody={itemRebuild}
        />
        <Box sx={{ display: "grid", rowGap: 2 }}>
          <ListItems
            isLoading={isLoading}
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            body={itemRebuild}
            head={tableHead}
            handleClick={handleDetail}
          />
        </Box>
      </Box>
      <Update />
      <Create />
      <Detail />
      <BasicSpeedDial
        title={t("create_discount")}
        handleClick={() => navigate("?create-discount")}
      />
    </Box>
  );
}

export default Discount;
