import React from "react";
import { Formik, Form, FormikProps } from "formik";
import { Box, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import SubmitButton from "../../../Component/SubmitButton";
import AsyncAutoComplete from "../../../Component/AsyncAutoComplete";
import NumberFormat from "react-number-format";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import Create from "../../Inventory/ProductCategory/Create";

function FormCreateBranch({
  getFieldProps,
  errors,
  touched,
  setFieldValue,
  values,
}: {
  getFieldProps: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  values: any;
}) {
  const formField: {
    label: string;
    name: string;
    isAutoCompleteAsync?: boolean;
    module?: string;
    isCurrency?: boolean;
    isDate?: boolean;
    isCreate?: boolean;
  }[] = [
    {
      label: "nama pengeluaran",
      name: "name",
    },
    {
      label: "Tanggal pengeluaran",
      name: "date",
      isDate: true,
    },
    {
      label: "Kategori pengeluaran",
      name: "category",
      isAutoCompleteAsync: true,
      isCreate: true,
      module: "expense_categories",
    },
    {
      label: "Jumlah pengeluaran",
      name: "amount",
      isCurrency: true,
    },
    {
      label: "Cabang",
      name: "branchId",
      module: "branchs",
      isAutoCompleteAsync: true,
    },
  ];

  const { t } = useTranslation<"translation", undefined>();
  const [from, setFrom] = React.useState<Dayjs | null>(
    dayjs(values?.date || null)
  );
  const handleChangeFrom = (newValue: Dayjs | null) => {
    const dateFrom: any = (newValue as any)?.$d;
    setFrom(dateFrom.toISOString());
    setFieldValue("date", dateFrom.toISOString());
  };
  console.log(values?.date, "dataFetchNotPagination");
  return (
    <Form>
      <Box sx={{ display: "grid", gap: 3 }}>
        {formField.map((item: any, index: number) => {
          if (item?.isDate) {
            return (
              <LocalizationProvider key={index} dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label={t("from")}
                  inputFormat="MM/DD/YYYY"
                  value={values?.date || from}
                  onChange={handleChangeFrom}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <CalendarMonthIcon sx={{ color: grey[500] }} />
                        ),
                      }}
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }
          if (item?.isCurrency) {
            return (
              <NumberFormat
                customInput={TextField}
                key={index}
                {...getFieldProps(item.name)}
                value={values[item?.name]}
                label={item?.label}
                thousandSeparator
                prefix="Rp"
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                helperText={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
              />
            );
          }
          if (item?.isAutoCompleteAsync) {
            return (
              <AsyncAutoComplete
                isCreate={item?.isCreate}
                value={values[item?.name] || ""}
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                touched={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
                onChange={(e: any, i: any) => {
                  setFieldValue(item.name, i);
                }}
                module={item.module}
                key={index}
                label={item.label}
                sx={{ width: "100%" }}
              />
            );
          }
          return (
            <TextField
              key={index}
              label={item.label}
              {...getFieldProps(item.name)}
            />
          );
        })}
      </Box>
      <SubmitButton />
    </Form>
  );
}

export default FormCreateBranch;
