function useNumberConverter() {
  const formatCurrency: (params: string) => number | undefined = (
    params: string
  ) => {
    if (typeof params === "string") {
      const num: number = Number(params?.replace("Rp", "").replace(/,/g, ""));
      return num;
    }
    if (typeof params === "number") {
      return params;
    }
  };
  return { formatCurrency };
}

export default useNumberConverter;
