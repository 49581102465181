import { Box, Card, Typography } from "@mui/material";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import Qs from "../../Utils/QS/qss";


export default function TopProduct(props: any) {
    const query = Qs();
    const s = query?.s ? JSON.parse(query.s) : {};
    const startDate = s?.createdAt?.['$gte'] || '';
    const endDate = s?.createdAt?.['$lte'] || '';
    const branchId = s?.branchId || '';
    const params = new URLSearchParams({
        startDate,
        endDate,
        branchId
    });
    const queryString = params.toString();
    const { data } = useDataFetch({
        module: `dashboard/top_products?${queryString}`,
    });

    const items = data?.data || [];

    return (
        <Card>
            <Box m={2} >
                <Typography variant="h6">Produk Terlaris</Typography>
                {
                    items.map((item: any) => (
                        <Box key={item.productId}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography>{item.productName}</Typography>
                            <Typography>{item.totalQuantity}</Typography>
                        </Box>
                    ))
                }
            </Box>
        </Card>
    )
}