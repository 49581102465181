import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabComponent({
  itemOne,
  itemTwo,
  labelOne,
  labelTwo,
  setTabValue,
  values,
}: {
  itemOne?: any;
  itemTwo?: any;
  labelTwo: string;
  setTabValue?: any;
  values?: any;
  labelOne: string;
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setTabValue(() => {
      if (newValue === 0) {
        return 1;
      }
      if (newValue === 1) {
        return 0;
      }
    });
  };
  console.log("tesloop");
  React.useEffect(() => {
    if (values?.type === "Percentage") {
      setValue(1);
    }
    if (values?.type === "nominal") {
      setValue(0);
    }
  }, [values?.type]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={labelOne} {...a11yProps(0)} />
          <Tab label={labelTwo} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box component={TabPanel} value={value} index={0}>
        {itemOne}
      </Box>
      <Box component={TabPanel} value={value} index={1}>
        {itemTwo}
      </Box>
    </Box>
  );
}
