import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import BasicModal from "../../../Component/BasicModal";
import Qs from "../../../Utils/QS/qss";
import { useTranslation } from "react-i18next";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import DetailItem from "../../../Component/DetailItem";
import moment from "moment";
import "moment/locale/id";
import { FormatCurrency } from "../../../Component/FormatCurrency";

function Detail() {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };

  const productId = Qs();
  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `purchases/${productId["detail-purchases-id"]}`,
    enabled: location.search.includes("?detail-purchases-id="),
  });
  const dataRebuild = {
    ...dataFetchNotPagination,
    contactName: dataFetchNotPagination?.contact?.name,
    items: dataFetchNotPagination?.items?.map((i: any) => ({
      ...i,
      productName: i?.product?.name,
    })),
  };
  const itemRebuild = [
    {
      label: t("name"),
      value: dataRebuild?.contactName,
    },
    {
      label: t("createdAt"),
      value: moment(dataRebuild?.createdAt).format("LLLL"),
    },
    {
      label: t("number"),
      value: dataRebuild?.number,
    },
    {
      label: t("payment_status"),
      value: dataRebuild?.paymentStatus,
    },
    {
      label: "Total",
      value: FormatCurrency(dataRebuild?.total),
    },
  ];

  return (
    <BasicModal
      title={t("detail_purchase")}
      isDetail
      open={location.search.includes("?detail-purchases-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("close")}
    >
      <DetailItem
        loading={isLoading}
        table={{
          isTable: true,
          tableitem: dataRebuild?.items,
          tableLabel: t("items"),
          tableColumns: [
            { id: "productName", label: t("name") },
            { id: "quantity", label: "Qty", align: "right" },
            {
              id: "unitPrice",
              label: t("purchase_price"),
              align: "right",
              isCurrency: true,
            },
            { id: "total", label: "Total", isCurrency: true, align: "right" },
          ],
        }}
        item={itemRebuild}
      />
    </BasicModal>
  );
}

export default Detail;
