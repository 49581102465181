import React from "react";
import { Box, Button, Typography, ListItemButton } from "@mui/material";
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Open_Drawer } from "../Utils/Context/useContextHelper";
import SearchIcon from "@mui/icons-material/Search";
import ReactDatePicker from "./ReactDatePicker";
import CategoryIcon from "@mui/icons-material/Category";
import DrawerSlideBottom from "./DrawerSlideBottom";

function FilterUtility({
  setPage,
  isFetching,
  enableCategory = false,
  enableSortDate = false,
}: {
  isFetching: boolean;
  enableCategory?: boolean;
  enableSortDate?: any;
  setPage: any;
}): JSX.Element {
  const { open, setOpen } = React.useContext(Open_Drawer);
  const navigate: NavigateFunction = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "none", md: "none", lg: "none" },
          alignItem: "center",
          gap: 1,
        }}
      >
        <ListItemButton
          onClick={() =>
            setOpen((i: any) => ({ ...i, search: "search", open: true }))
          }
        >
          <SearchIcon />
        </ListItemButton>
        {enableCategory ? (
          <ListItemButton
            onClick={() =>
              setOpen((i: any) => ({
                ...i,
                sortByCategory: "sortByCategory",
                open: true,
              }))
            }
          >
            <CategoryIcon />
          </ListItemButton>
        ) : null}
        {enableSortDate ? <ReactDatePicker iconOnly /> : null}
      </Box>
      <DrawerSlideBottom
        setPage={setPage}
        isFetching={isFetching}
        open={open.open}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
}

export default FilterUtility;
