import React from "react";
import { Formik, Form, FormikProps } from "formik";
import { Box, TextField } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import { formListCreateCompany } from "../../../Auth/Company/FormCreateCompany";
import Map from "../../../Component/GoogleMap/Map";

function FormCreateBranch({
  getFieldProps,
  setFieldValue,
  errors,
  touched,
  values,
}: {
  getFieldProps: any;
  setFieldValue?: any;
  errors: any;
  touched: any;
  values?: any;
}) {
  return (
    <Form>
      <Box sx={{ display: "grid", rowGap: 3 }}>
        {formListCreateCompany?.map((item, index) => {
          if (item?.isMap) {
            return (
              <Map
                key={index}
                setFieldValue={setFieldValue}
                coordianate={values}
              />
            );
          }
          return (
            <Box key={index}>
              <TextField
                fullWidth
                type={item.type}
                {...getFieldProps(item.name)}
                label={item.placeholder}
                error={Boolean(
                  (touched as any)[item.name] && (errors as any)[item?.name]
                )}
                helperText={
                  (touched as any)[item.name] && (errors as any)[item?.name]
                }
              />
            </Box>
          );
        })}
      </Box>
      <SubmitButton />
    </Form>
  );
}

export default FormCreateBranch;
