import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import BasicModal from "../../../Component/BasicModal";
import FormAdd from "./FormAdd";
import useMutationPost from "../../../Hooks/Mutation/useMutationPost";
import useNumberConverter from "../../../Hooks/useNumberConverter";
import { CREATE_PRODUCT } from "../../../Auth/Validation";

export interface FormValues {
  name: string;
  description: string;
  // imageUrl: string;
  code: string;
  unit: string;
  purchasePrice: string;
  sellPrice: string;
  categoryId: string;
  branchs: any;
  variants?: any;
}

export const initialValues: FormValues = {
  name: "",
  description: "",
  // imageUrl: "",
  code: "",
  unit: "",
  purchasePrice: "",
  sellPrice: "",
  categoryId: "",
  branchs: "",
  variants: [],
};

function Create() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { formatCurrency } = useNumberConverter();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };

  const { mutationPost, isLoading } = useMutationPost({
    module: "products",
    errorMsg: t("create_product_error"),
    successMsg: t("create_product_success"),
  });

  return (
    <BasicModal
      isLoading={isLoading}
      title={t("create_product")}
      open={location.search.includes("?create-product")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("create")}
    >
      <Formik
        validationSchema={CREATE_PRODUCT}
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          const variants: any = values?.variants?.map((item: any) => {
            delete item.id;
            const purchasePrice: any = formatCurrency(item?.purchasePrice);
            const sellPrice: any = formatCurrency(item?.sellPrice);
            return {
              ...item,
              purchasePrice,
              sellPrice
            };
          });
          const categoryId: string = (values as any)?.categoryId?.id;
          const purchasePrice: any = formatCurrency(values?.purchasePrice);
          const sellPrice: any = formatCurrency(values?.sellPrice);
          const body: FormValues = {
            ...values,
            variants,
            categoryId: categoryId,
            purchasePrice: purchasePrice,
            sellPrice: sellPrice,
          };
          try {
            await mutationPost.mutateAsync(body);
            resetForm();
            navigate(-1);
          } catch (error) {

          }
        }}
      >
        {(props: FormikProps<FormValues>) => <FormAdd {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Create;
