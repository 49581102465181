import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";

export default function BasicSpeedDial({
  handleClick,
  title = "create",
}: {
  handleClick: any;
  title?: string;
}) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        bgcolor: "#000",
        display: { xs: "block", sm: "none", md: "none", lg: "none" },
      }}
    >
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ py: 2 }}
        fullWidth
      >
        {title}
      </Button>
    </Box>
  );
}
