import React from "react";
import {
  useLocation,
  Location,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import BasicModal from "./Component/BasicModal";
import { Typography } from "@mui/material";

function PrivacyPolicy() {
  const { t } = useTranslation<"translation", undefined>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  return (
    <BasicModal
      title={t("user_agreement")}
      open={location.search.includes("?privacy&policy-kasir-usaha-web")}
      handleClose={() => navigate(-1)}
      isDetail
      labelClose={t("close")}
    >
      <Typography variant="h6" fontWeight={600}>
        PRIVACY and POLICY
      </Typography>
      <Typography>
        Adipisicing proident reprehenderit nulla sint nostrud labore mollit.
        Reprehenderit ut occaecat non ut anim. Reprehenderit eiusmod veniam
        dolore laboris anim voluptate aliquip irure occaecat qui est.
        Exercitation laborum minim consectetur officia in nisi. Nisi est id
        cillum aliquip voluptate irure incididunt anim. Consequat do eu sint
        laboris commodo irure consequat. Aliqua elit amet tempor fugiat aliquip
        amet adipisicing voluptate sunt cillum in. Proident consectetur
        adipisicing voluptate do cillum eiusmod mollit veniam aliquip. Ipsum
        irure qui in eu irure nulla excepteur ea quis pariatur. Veniam laboris
        cupidatat deserunt nostrud laboris cupidatat. Cupidatat amet Lorem ex
        dolor. Laborum veniam sit id nisi aute consequat fugiat sint excepteur
        exercitation. Adipisicing proident reprehenderit nulla sint nostrud
        labore mollit. Reprehenderit ut occaecat non ut anim. Reprehenderit
        eiusmod veniam dolore laboris anim voluptate aliquip irure occaecat qui
        est. Exercitation laborum minim consectetur officia in nisi. Nisi est id
        cillum aliquip voluptate irure incididunt anim. Consequat do eu sint
        laboris commodo irure consequat. Aliqua elit amet tempor fugiat aliquip
        amet adipisicing voluptate sunt cillum in. Proident consectetur
        adipisicing voluptate do cillum eiusmod mollit veniam aliquip. Ipsum
        irure qui in eu irure nulla excepteur ea quis pariatur. Veniam laboris
        cupidatat deserunt nostrud laboris cupidatat. Cupidatat amet Lorem ex
        dolor. Laborum veniam sit id nisi aute consequat fugiat sint excepteur
        exercitation.
      </Typography>
      <Typography variant="h6" mt={2} fontWeight={600}>
        Handling Cookie
      </Typography>
      <Typography>
        Adipisicing proident reprehenderit nulla sint nostrud labore mollit.
        Reprehenderit ut occaecat non ut anim. Reprehenderit eiusmod veniam
        dolore laboris anim voluptate aliquip irure occaecat qui est.
        Exercitation laborum minim consectetur officia in nisi. Nisi est id
        cillum aliquip voluptate irure incididunt anim. Consequat do eu sint
        laboris commodo irure consequat. Aliqua elit amet tempor fugiat aliquip
        amet adipisicing voluptate sunt cillum in. Proident consectetur
        adipisicing voluptate do cillum eiusmod mollit veniam aliquip. Ipsum
        irure qui in eu irure nulla excepteur ea quis pariatur. Veniam laboris
        cupidatat deserunt nostrud laboris cupidatat. Cupidatat amet Lorem ex
      </Typography>
      <Typography variant="h6" mt={2} fontWeight={600}>
        Using Data For Thirth Party
      </Typography>
      <Typography>
        Adipisicing proident reprehenderit nulla sint nostrud labore mollit.
        Reprehenderit ut occaecat non ut anim. Reprehenderit eiusmod veniam
        dolore laboris anim voluptate aliquip irure occaecat qui est.
        Exercitation laborum minim consectetur officia in nisi. Nisi est id
        cillum aliquip voluptate irure incididunt anim. Consequat do eu sint
      </Typography>
      <Typography variant="h6" mt={2} fontWeight={600}>
        Payment
      </Typography>
      <Typography>
        Labore est aliquip aliqua quis ea dolore. Enim ipsum pariatur sunt
        excepteur eiusmod sunt aute elit dolore. Magna est voluptate amet quis
        nulla sit eiusmod. Est excepteur sint tempor velit. Quis amet do ullamco
        qui voluptate et duis esse laborum laboris amet exercitation commodo
        cillum. Tempor reprehenderit et qui laborum tempor laboris enim elit ea
        minim ut exercitation in magna. Proident nostrud sit enim nisi tempor
        mollit Lorem ex consectetur. Aute consequat fugiat commodo deserunt.
        Aliqua cillum Lorem magna eu tempor amet aliquip non amet incididunt
        commodo fugiat id voluptate. Proident laborum tempor elit magna amet
        aute proident. Enim cillum irure ad nisi commodo magna fugiat amet quis
        proident cupidatat proident aliqua. Minim ex officia cupidatat velit
        laboris eiusmod Lorem. Sunt Lorem ullamco sint irure fugiat velit non.
        Ut do nulla velit cillum fugiat cillum commodo eiusmod amet esse laboris
        elit tempor aute. Fugiat anim proident mollit nisi consequat officia
        esse ex. Laborum cillum commodo tempor pariatur. Aliqua in excepteur
        aute consequat anim ad pariatur incididunt ea enim non laboris officia.
        Do est esse irure id irure laborum eu velit aute proident sit eu culpa
        elit. Aute aliqua proident sit consequat ad deserunt cillum. Dolor irure
        occaecat dolore cupidatat est veniam minim minim eiusmod velit occaecat.
      </Typography>
      <Typography variant="h6" mt={2} fontWeight={600}>
        Compensation
      </Typography>
      <Typography>
        Labore est aliquip aliqua quis ea dolore. Enim ipsum pariatur sunt
        excepteur eiusmod sunt aute elit dolore. Magna est voluptate amet quis
        nulla sit eiusmod. Est excepteur sint tempor velit. Quis amet do ullamco
        qui voluptate et duis esse laborum laboris amet exercitation commodo
        cillum. Tempor reprehenderit et qui laborum tempor laboris enim elit ea
        minim ut exercitation in magna. Proident nostrud sit enim nisi tempor
        mollit Lorem ex consectetur. Aute consequat fugiat commodo deserunt.
        Aliqua cillum Lorem magna eu tempor amet aliquip non amet incididunt
        commodo fugiat id voluptate. Proident laborum tempor elit magna amet
        aute proident. Enim cillum irure ad nisi commodo magna fugiat amet quis
        proident cupidatat proident aliqua. Minim ex officia cupidatat velit
        laboris eiusmod Lorem. Sunt Lorem ullamco sint irure fugiat velit non.
        Ut do nulla velit cillum fugiat cillum commodo eiusmod amet esse laboris
        elit tempor aute. Fugiat anim proident mollit nisi consequat officia
        esse ex. Laborum cillum commodo tempor pariatur. Aliqua in excepteur
        aute consequat anim ad pariatur incididunt ea enim non laboris officia.
        Do est esse irure id irure laborum eu velit aute proident sit eu culpa
        elit. Aute aliqua proident sit consequat ad deserunt cillum. Dolor irure
        occaecat dolore cupidatat est veniam minim minim eiusmod velit occaecat.
      </Typography>
      <Typography mt={2}>
        Labore est aliquip aliqua quis ea dolore. Enim ipsum pariatur sunt
        excepteur eiusmod sunt aute elit dolore. Magna est voluptate amet quis
        nulla sit eiusmod. Est excepteur sint tempor velit. Quis amet do ullamco
        qui voluptate et duis esse laborum laboris amet exercitation commodo
      </Typography>
      <Typography mt={2}>
        Labore est aliquip aliqua quis ea dolore. Enim ipsum pariatur sunt
        excepteur eiusmod sunt aute elit dolore. Magna est voluptate amet quis
        nulla sit eiusmod. Est excepteur sint tempor velit. Quis amet do ullamco
        qui voluptate et duis esse laborum laboris amet exercitation commodo
      </Typography>
      <Typography mt={2}>
        Labore est aliquip aliqua quis ea dolore. Enim ipsum pariatur sunt
        excepteur eiusmod sunt aute elit dolore. Magna est voluptate amet quis
        nulla sit eiusmod. Est excepteur sint tempor velit. Quis amet do ullamco
        qui voluptate et duis esse laborum laboris amet exercitation commodo
      </Typography>
    </BasicModal>
  );
}

export default PrivacyPolicy;
