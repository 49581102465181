import React from "react";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import useDataFetch from "../Hooks/Query/useDataFetch";
import { useLocation, Location } from "react-router-dom";

function AutoCompleteData({
  module,
  defaultValue,
  id,
  onChange,
  label,
  size,
}: {
  module?: any;
  onChange: any;
  defaultValue?: any;
  label?: any;
  id?: string;
  size?: "small" | "medium"
}) {
  const location: Location = useLocation();
  const { dataFetch, isLoading, refetch } = useDataFetch({
    module: module,
    enabled: false,
  });

  const options = dataFetch?.map((i: any) => ({ ...i, label: i.name }));

  return (
    <Autocomplete
      fullWidth
      sx={{
        minWidth: 180
      }}
      defaultValue={defaultValue}
      onOpen={() => refetch()}
      options={options || []}
      onChange={(e, value) => {
        onChange(e, value)
      }}
      loading={isLoading}
      id={id}
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
        />
      )}
    />
  );
}

export default AutoCompleteData;
