import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type DashboardBarChartProps = {
  data: any[];
  lines: { dataKey: string, unit: string, stroke?: string, fill?: string }[];
}

const DashboardLineChart = ({ data, lines }: DashboardBarChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 0,
          bottom: 30,
        }}
      >
        <CartesianGrid vertical={false} stroke="#DDD" />
        <XAxis dataKey="name" style={{ fontSize: 12 }} height={40} />
        <YAxis width={40} style={{ fontSize: 12 }}   />
        <Tooltip />
        <Legend />
        {
          lines.map((line) => (
            <Area
              dot={false}
              legendType="none"
              dataKey={line.dataKey}
              stroke={line.stroke || "#F0063B"}
              strokeWidth={2}
              fill={line.fill ||  "#F0063B"}
              type="monotone" unit={line.unit} strokeLinecap="round"
            />
          ))
        }
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashboardLineChart;
