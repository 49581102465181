import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  ListItemIcon,
  AccordionDetails,
  Paper,
} from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { NavigatorLandingPage } from "./NavigatorLandingPage";
import { grey } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../Pages/LandingPage/Footer/Footer";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

export default function LandingPageLayout(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const [openDropDown, setOpenDropDown] = React.useState<any>(false);
  const [expanded, setExpanded] = React.useState<number | string | false>(
    false
  );
  const { t } = useTranslation<"translation", undefined>();
  const handleChange =
    (panel: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };
  const handleClickSubMenu = (child: any): void => {
    navigate(child?.path);
    setMobileOpen(false);
  };
  const drawer = (
    <Box sx={{ textAlign: "center", bgcolor: "#fff" }}>
      <Toolbar>
        <Typography color="#000" variant="h5" fontWeight={600}>
          <span>
            <i>KasirUsaha</i>
            <span
              style={{ width: "10px", height: "10px", background: "#fff" }}
            ></span>
          </span>
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {NavigatorLandingPage.map((item, index) => {
          if (item?.children) {
            return (
              <Accordion
                disableGutters
                sx={{
                  boxShadow: "none",
                  "& .css-ai1ezy-MuiPaper-root-MuiAccordion-root:before": {
                    backgroundColor: "rgb(251 0 0 / 12%)",
                  },
                }}
                key={index}
                expanded={expanded === item?.path}
                onChange={handleChange(item?.path)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#000" }} />}
                >
                  <ListItemButton
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItem>
                      <ListItemText primary={item?.label} />
                    </ListItem>
                  </ListItemButton>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    bgcolor: grey[300],
                    boxShadow: "none",
                  }}
                >
                  {item?.children?.map((child: any, index: number) => {
                    return (
                      <ListItem key={index}>
                        <ListItemButton
                          sx={{
                            "&:hover": {
                              bgcolor: child.path.includes(location.pathname)
                                ? "#fff"
                                : grey[800],
                            },
                          }}
                          onClick={() => handleClickSubMenu(child)}
                        >
                          <ListItemText
                            sx={{
                              color: child.path.includes(location.pathname)
                                ? "#000"
                                : "#fff",
                            }}
                            primary={child?.label}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          }
          return (
            <ListItem key={index}>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                  setMobileOpen(!mobileOpen);
                }}
                sx={{
                  "&:hover": {
                    bgcolor: item.path.includes(location.pathname)
                      ? grey[800]
                      : "#fff",
                  },
                }}
              >
                <ListItemText primary={item?.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ bgcolor: "#004C80", boxShadow: "none" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Box>
                <Typography
                  variant="h6"
                  component="a"
                  onClick={() => navigate("/")}
                  fontWeight={600}
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "block" },
                    cursor: "pointer",
                  }}
                >
                  KASIR USAHA
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex", alignItems: "flex-start" },
                  gap: 6,
                }}
              >
                {NavigatorLandingPage.map((item, index) => {
                  if (item?.children) {
                    return (
                      <Box key={index}>
                        <Button
                          onMouseOver={() => setOpenDropDown(index)}
                          onMouseLeave={() => setOpenDropDown(99)}
                          key={index}
                          sx={{ color: "#fff" }}
                        >
                          {item?.label}
                        </Button>
                        <Paper
                          elevation={3}
                          sx={{
                            display: openDropDown === index ? "block" : "none",
                            position: "absolute",
                            bgcolor: "#fff",
                          }}
                        >
                          {item?.children?.map((x, y) => (
                            <Box
                              sx={{ p: 1, px: 2 }}
                              onMouseOver={() => setOpenDropDown(index)}
                              onMouseLeave={() => setOpenDropDown(99)}
                              key={y}
                            >
                              <Typography
                                component="a"
                                fontSize={15}
                                onClick={() => {
                                  navigate(x?.path);
                                  setOpenDropDown(99);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  color: "#000",

                                  "&:hover": {
                                    transition: "0.5 all",
                                    color: grey[500],
                                  },
                                }}
                              >
                                {x?.label}
                              </Typography>
                            </Box>
                          ))}
                        </Paper>
                      </Box>
                    );
                  }
                  return (
                    <Button
                      onMouseOver={() => setOpenDropDown(99)}
                      key={index}
                      onClick={() => navigate(item.path)}
                      sx={{ color: "#fff" }}
                    >
                      {item?.label}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex", alignItems: "flex-start" },
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              onClick={() => navigate("/kasir-usaha/login")}
              variant="contained"
              sx={{
                bgcolor: "#fff",
                color: "#00D3FF",
                "&:hover": {
                  bgcolor: grey[100],
                },
              }}
            >
              {t("login")}
            </Button>
            <Button
              onClick={() => navigate("/kasir-usaha/register")}
              variant="contained"
              sx={{ color: "#fff" }}
              color="secondary"
            >
              {t("register")}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%" }}>
        <Toolbar />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}
