import React from "react";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import AsyncAutoComplete from "../../../Component/AsyncAutoComplete";
import Searching from "../../../Utils/Search/useSearch";
import CreateRecipeItem from "./CreateRecipeItem";

interface FormAddRecipe {
  name: string;
  label: string;
  isAsync: boolean;
  module: string;
  title: string;
}
function FormAdd({
  getFieldProps,
  touched,
  errors,
  values,
  setFieldValue,
}: {
  getFieldProps: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const [recipe, setRecipe] = React.useState<any>([
    {
      id: 1,
      ingredientName: "",
      variantQuantity: "",
    },
  ]);
  const formList: FormAddRecipe[] = [
    {
      label: t("product"),
      name: "productId",
      isAsync: true,
      module: "products",
      title: t("product_recipe"),
    },
  ];
  const handleAddRecipeItem: () => void = () => {
    const clone: any = [...recipe];
    const body: {
      id: number;
      ingredientName: string;
      variantQuantity: string;
    } = {
      id: clone?.length + 1,
      ingredientName: "",
      variantQuantity: "",
    };

    clone.push(body);
    setRecipe(clone);
  };
  const handleDeleteRecipeItem: (item: any) => void = (item: any) => {
    const clone: any = [...recipe];
    const delRecipeItem: any = clone.filter((i: any) => i.id !== item.id);
    setRecipe(delRecipeItem);
  };
  return (
    <Form>
      <Box sx={{ display: "grid", gap: 3 }}>
        <AsyncAutoComplete
          value={(values as any)?.productId || ""}
          error={Boolean(
            (touched as any)?.productId && (errors as any)?.productId
          )}
          touched={(touched as any)?.productId && (errors as any)?.productId}
          onChange={(e: any, i: any) => {
            setFieldValue("productId", i);
          }}
          size="small"
          module="products"
          label="Product"
          sx={{ width: "100%" }}
        />
        {/* <Searching /> */}
        <Box mt={3}>
          <CreateRecipeItem
            values={values}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
          />
        </Box>
        <SubmitButton />
      </Box>
    </Form>
  );
}

export default FormAdd;
