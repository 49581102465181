import React from "react";
import { Formik, Form, FormikProps } from "formik";
import { Box, TextField } from "@mui/material";
import SubmitButton from "../../../Component/SubmitButton";
import { formListCreateCompany } from "../../../Auth/Company/FormCreateCompany";
import AsyncAutoComplete from "../../../Component/AsyncAutoComplete";
import { useTranslation } from "react-i18next";
import MultipleAutoComplete from "../../../Component/MultipleAutoComplete";

function FormCreateBranch({
  getFieldProps,
  errors,
  touched,
  setFieldValue,
  values,
}: {
  getFieldProps: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
}) {
  const { t } = useTranslation<"translation", undefined>();
  return (
    <Form>
      <Box sx={{ display: "grid", rowGap: 3 }}>

        <TextField
          name="name"
          fullWidth
          label="Nama"
          {...getFieldProps("name")}
        />
        <AsyncAutoComplete
          label="Pilih Cabang"
          value={values?.branch}
          module="branchs"
          onChange={(e: any, i: any) => {
            setFieldValue(`branch`, i);
          }}
        />
        <TextField
          name="password"
          {...getFieldProps("password")}
          fullWidth label="Password"  />
      </Box>
      <SubmitButton />
    </Form>
  );
}

export default FormCreateBranch;
