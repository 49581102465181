import { Box, Divider, Grid, ListItemButton, Skeleton } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import React from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import AsyncAutoComplete from "../../Component/AsyncAutoComplete";
import Searching from "../../Utils/Search/useSearch";
import CardComponent from "../../Component/CardComponent";
import { useTranslation } from "react-i18next";
import { FormatCurrency } from "../../Component/FormatCurrency";

function MobileResponsive({
  isFetching,
  itemRebuild,
  handleClick,
  isLoading,
  data,
}: {
  itemRebuild: any;
  data: any;
  handleClick?: any;
  isFetching: boolean;
  isLoading: boolean;
}): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: 2,
          bgcolor: "#fff",
          position: "sticky",
          top: 50,
          zIndex: 100,
          pt: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Searching
            fullWidth
            isFetching={isFetching}
            label={t("search")}
            size="small"
          />
          {/* <ListItemButton onClick={() => alert("belum")}>
            <QrCodeScannerIcon />
          </ListItemButton> */}
        </Box>
        <AsyncAutoComplete
          onChange={(e: any, i: any) => {
            const sortByCategory: string = JSON.stringify({
              categoryId: i?.id,
            });
            navigate(`?s=${sortByCategory}`);
          }}
          size="small"
          module="product_categories"
          label={t("sort_by_category")}
          sx={{ width: "100%" }}
        />
        <Divider />
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={2}
          mt={1}
          sx={{
            " .css-6h36vo-MuiGrid-root": {
              ml: 0,
            },
          }}
        >
          {isLoading ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              columnSpacing={2}
              sx={{
                width: "100%",
              }}
            >
              {Array(8)
                .fill(1)
                .map((item: number, index: number) => (
                  <Grid item xs={6} key={index} sx={{ width: "100%" }}>
                    <Skeleton animation="wave" sx={{ height: "200px" }} />
                  </Grid>
                ))}
            </Grid>
          ) : (
            itemRebuild?.map((item: any, index: number) => {
              const counter: any = data?.find((o: any) => o?.id === item?.id);
              return (
                <Grid item xs={6} key={index}>
                  <CardComponent
                    bgChange={Boolean(counter)}
                    counter={counter?.quantity}
                    handleClick={() => handleClick(item)}
                    name={item?.name}
                    sellPrice={FormatCurrency(item?.sellPrice)}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default MobileResponsive;
