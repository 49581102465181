import React, { useEffect } from "react";
import Routes from "./routes";
import { ConfirmDialog, Open_Drawer } from "./Utils/Context/useContextHelper";
import { useNavigate, NavigateFunction, useLocation } from "react-router-dom";
import Confirm from "./Component/Confirm";
import { Box } from "@mui/material";
import PrivacyPolicy from "./Privacy&Policy";
import { useTranslation } from "react-i18next";

function App() {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const [openDialog, setDialog] = React.useState<any>({
    variant: "delete",
    msg: "Lorem ipsum",
    handleClick: null,
    isLoading: false,
  });
  const [open, setOpen] = React.useState<any>({
    open: false,
    search: null,
    sortDate: null,
    sortByCategory: null,
    export: null,
  });
  React.useEffect(() => {
    if (Boolean(openDialog.handleClick)) {
      navigate("?confirm");
    }
    if (location.search.includes("?confirm")) {
      navigate(location.pathname);
    }
  }, [openDialog.handleClick]);

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("id");
  }, []);
  return (
    <Box>
      <ConfirmDialog.Provider value={{ openDialog, setDialog }}>
        <Open_Drawer.Provider value={{ open, setOpen }}>
          <Routes />
          <Confirm
            variant={openDialog.variant}
            isLoading={openDialog.isLoading}
            msg={openDialog.msg}
            handleClick={openDialog.handleClick}
            handleClickOpen={location.search.includes("?confirm")}
            handleClose={() => {
              navigate(-1);
            }}
          />
        </Open_Drawer.Provider>
      </ConfirmDialog.Provider>
      <PrivacyPolicy />
    </Box>
  );
}

export default App;
