import React from "react";
import {
  Box,
  Paper,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Typography,
} from "@mui/material";
import Print58mm from "../../../Component/Print58mm";

function Struck() {
  const [value, setValue] = React.useState<string>("");
  const [waterMark, setWatermark] = React.useState<boolean>(true);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "50%" }}>
        <Typography mb={2} variant="h6">
          Setting Struck
        </Typography>
        <Paper elevation={4} sx={{ p: 2 }}>
          <FormControlLabel
            onChange={(x, y) => setWatermark(y)}
            control={<Switch checked={waterMark} />}
            label="Watermark"
          />
          <TextField
            label="Detail"
            onChange={(i) => setValue(i.target.value)}
            fullWidth
            multiline
            rows={4}
            sx={{ mt: 3 }}
          />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={{ mt: 3 }} variant="contained">
            Simpan
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography mb={2} variant="h6">
          Preview Struck
        </Typography>
        <Paper elevation={4} sx={{ width: "450px" }}>
          <Print58mm preview value={value} waterMark={waterMark} />
        </Paper>
      </Box>
    </Box>
  );
}

export default Struck;
