import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, NavigateFunction } from "react-router-dom";

function Header() {
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation<"translation", undefined>();
  return (
    <Box sx={{ bgcolor: "#004C80", p: 3, py: 5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box color="#fff">
          <Typography variant="h3" fontWeight={600}>
            {t("header_title")}
          </Typography>
          <Typography
            variant="h5"
            sx={{ maxWidth: { xs: "100%", md: "70%" } }}
            mt={2}
          >
            {t("header_tag")}
          </Typography>
          <Box sx={{ display: { xs: "grid", md: "flex" }, gap: 3, mt: 3 }}>
            <Button
              onClick={() => navigate("/kasir-usaha/register")}
              variant="contained"
              color="secondary"
              sx={{ color: "#fff" }}
            >
              {t("register_now")}
            </Button>
            <Button
              startIcon={
                <img
                  width="15px"
                  src="/Asset/Social-Media/logos_google-play-icon.svg"
                />
              }
              variant="contained"
            >
              {t("download_google_play")}
            </Button>
          </Box>
        </Box>
        <Box sx={{ maxWidth: "100%", display: { xs: "none", md: "block" } }}>
          <img width="100%" src="/Asset/template-1.svg" />
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
