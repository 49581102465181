import * as React from "react";
import ListItem from "@mui/material/ListItem";
import {
  Paper,
  Typography,
  Box,
  Pagination,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import "moment/locale/id";
import { useTranslation } from "react-i18next";
import { green, grey, orange } from "@mui/material/colors";

import { FormatCurrency } from "./FormatCurrency";

export default function ListItems({
  head,
  body,
  handleClick,
  onChange,
  isLoading,
  pageCount,
}: {
  head: any;
  body: any;
  isLoading?: boolean;
  pageCount: number;
  handleClick?: any;
  onChange?: (event: any, page: number) => void;
}) {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  return (
    <Box
      sx={{
        display: { xs: "block", sm: "none", md: "none", lg: "none" },
      }}
    >
      <Box>
        <Box width="100%" sx={{ display: "grid", rowGap: 2 }}>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
              }}
            >
              <CircularProgress sx={{ my: 2 }} />
              <Typography variant="h6">{t("please_wait")}</Typography>
            </Box>
          ) : (
            body?.map((item: any, index: number) => (
              <ListItem
                sx={{ px: 2, pb: 3 }}
                onClick={() => handleClick(item)}
                key={index}
              >
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    width: "100%",
                    "&:active": {
                      bgcolor: grey[100],
                    },
                    bgcolor: grey[50],
                  }}
                >
                  <Box>
                    {head?.map((x: any, y: number) => {
                      const value: any = item[x?.id as any];
                      /// Status Variant Color Changer
                      const variantColor: (params: any) => void = (
                        params: any
                      ) => {
                        const isOption: any = x?.isStatus
                          ? x?.option?.find((i: any) => i.value === params)
                          : [];
                        const colorVariant: any = [
                          {
                            variant: "green",
                            color: green[700],
                            bgcolor: green[200],
                          },
                          {
                            variant: "blue",
                            color: orange[700],
                            bgcolor: orange[200],
                          },
                        ].find((i: any) => i?.variant === isOption?.variant);
                        const text: any = isOption?.label;
                        if (isOption) {
                          return (
                            <Typography
                              sx={{
                                color: colorVariant?.color,
                                bgcolor: colorVariant?.bgcolor,
                                width: "fit-content",
                                px: 1,
                                py: 0.5,
                                borderRadius: "25px",
                                fontWeight: 500,
                              }}
                            >
                              {text}
                            </Typography>
                          );
                        } else {
                          return;
                        }
                      };
                      const isDate: any = x.isDate
                        ? moment(value).format("LLLL")
                        : value;
                      const isCurrency: any = x.isCurrency
                        ? FormatCurrency(value)
                        : isDate;
                      const indicator: any = x.option
                        ? variantColor(value)
                        : isCurrency;
                      return (
                        <ListItemText
                          key={y}
                          primary={x?.label}
                          secondary={indicator}
                        />
                      );
                    })}
                  </Box>
                </Paper>
              </ListItem>
            ))
          )}
        </Box>
        <Box>
          <Pagination
            sx={{ mt: 2 }}
            onChange={onChange}
            count={pageCount}
            shape="rounded"
          />
        </Box>
      </Box>
    </Box>
  );
}
