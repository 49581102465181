import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import SectionTwo from "../Home/SectionTwo";

interface FIELD {
  name: string;
  placeholder: string;
}

function ContactUs() {
  const { t } = useTranslation();
  const inputField: FIELD[] = [
    {
      name: "name",
      placeholder: t("name"),
    },
    {
      name: "email",
      placeholder: t("email"),
    },
    {
      name: "phone",
      placeholder: t("phone_number"),
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          bgcolor: "#004C80",
          display: "flex",
          px: 3,
          py: 10,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "80%", display: "flex", justifyContent: "center" }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src="/Asset/call-center.svg" />
            </Box>
            <Box sx={{ color: "#fff", textAlign: "center", mt: 2 }}>
              <Typography variant="h5">{t("contact_us_title")}</Typography>
              <Typography>{t("contact_us_tag")}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            width: "80%",
            bgcolor: "#fff",
            p: 2,
            borderRadius: "6px",
          }}
        >
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              address: "",
              description: "",
            }}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ values, getFieldProps }) => (
              <Box>
                <Typography variant="h5" mb={2} fontWeight={600}>
                  {t("contact_us")}
                </Typography>
                <Form>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <Box sx={{ display: "grid", gap: 2, width: "100%" }}>
                      {inputField.map((item: FIELD, index: number) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...getFieldProps(item.name)}
                          label={item.placeholder}
                          key={index}
                        />
                      ))}
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <TextField
                        size="small"
                        fullWidth
                        {...getFieldProps("address")}
                        multiline
                        rows={6}
                        label={t("address")}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      size="small"
                      fullWidth
                      {...getFieldProps("description")}
                      multiline
                      rows={8}
                      label={t("description")}
                    />
                  </Box>
                  <Button
                    type="submit"
                    color="success"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, color: "#fff" }}
                  >
                    {t("send")}
                  </Button>
                </Form>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
      <SectionTwo />
    </Box>
  );
}

export default ContactUs;
