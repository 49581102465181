import React, { useContext } from "react";
import { Box, Link, TextField, Typography } from "@mui/material";
import { grey, green, cyan } from "@mui/material/colors";
import ListItemButton from "@mui/material/ListItemButton";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "../../Component/LoadingButton";
import useAuth from "../../Hooks/Mutation/useAuth";
import { useTranslation } from "react-i18next";
import { REGISTER } from "../../Auth/Validation";

type NavigateFunction = /*unresolved*/ any;
interface FormRegister {
  name: string;
  placeholder: string;
  type: string;
  icon?: JSX.Element;
}
interface InitialValue {
  name: string;
  jobTitle: string;
  email: string;
  password: string;
}

function RegisterForm(): JSX.Element {
  const { t } = useTranslation();
  const { auth, isLoading } = useAuth({
    module: "register",
    successMsg: "register success",
    errorMsg: "Register fail",
  });
  const navigate: NavigateFunction = useNavigate();
  const [show, setShow] = React.useState<boolean>(false);

  const formList: FormRegister[] = [
    {
      name: "name",
      placeholder: t("name"),
      type: "text",
    },
    {
      name: "jobTitle",
      placeholder: t("company_name"),
      type: "text",
    },
    {
      name: "email",
      placeholder: t("email"),
      type: "email",
    },
    {
      name: "password",
      placeholder: t("password"),
      type: show ? "text" : "password",
      icon: show ? (
        <ListItemButton onClick={() => setShow(!show)}>
          <VisibilityIcon />
        </ListItemButton>
      ) : (
        <ListItemButton onClick={() => setShow(!show)}>
          <VisibilityOffIcon />
        </ListItemButton>
      ),
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: { xs: "grid", sm: "grid", lg: "flex", xl: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography variant="h5" fontWeight={600}>
            <span>
              <i>KasirUsaha</i>
              <span
                style={{ width: "10px", height: "10px", background: "#fff" }}
              ></span>
              <span>.WEB</span>
            </span>
          </Typography> */}
        </Box>
        <Box mt={1}>
          <Typography variant="h4" fontWeight={400}>
            {t("register")}
          </Typography>
          <Formik
            validationSchema={REGISTER}
            initialValues={{
              name: "",
              jobTitle: "",
              email: "",
              password: "",
            }}
            onSubmit={async (values: InitialValue) => {
              auth.mutate(values);
            }}
          >
            {({ getFieldProps, errors, touched }) => (
              <Form>
                <Box>
                  {formList.map(
                    (
                      item:
                        | {
                            name: string;
                            placeholder: string;
                            type: string;
                            icon?: undefined;
                          }
                        | {
                            name: string;
                            placeholder: string;
                            type: string;
                            icon: JSX.Element;
                          },
                      index: number
                    ) => (
                      <Box key={index} sx={{ mt: 3 }}>
                        <TextField
                          InputProps={{ endAdornment: item.icon }}
                          fullWidth
                          type={item.type}
                          {...getFieldProps(item.name)}
                          label={item.placeholder}
                          error={Boolean(
                            (touched as any)[item.name] &&
                              (errors as any)[item?.name]
                          )}
                          helperText={
                            (touched as any)[item.name] &&
                            (errors as any)[item?.name]
                          }
                        />
                      </Box>
                    )
                  )}
                  <Typography
                    textAlign="center"
                    variant="subtitle2"
                    mt={2}
                    mb={-1}
                  >
                    {t("by_registering_you_agree")}{" "}
                    <a
                      onClick={() =>
                        navigate("?privacy&policy-kasir-usaha-web")
                      }
                      style={{
                        cursor: "pointer",
                        color: cyan[600],
                        fontWeight: 600,
                      }}
                    >
                      {t("privacy_and_policy")}
                    </a>{" "}
                    {t("kasir_usaha_web")}
                  </Typography>
                  <LoadingButton
                    type="submit"
                    isLoading={isLoading}
                    fullWidth
                    sx={{ mt: 3 }}
                    title={t("register")}
                  />
                  <Box mt={3}>
                    <Typography textAlign="center">
                      {t("already_have_account")}{" "}
                      <a
                        style={{
                          cursor: "pointer",
                          color: cyan[600],
                          fontWeight: 600,
                        }}
                        onClick={() => navigate("/kasir-usaha/login")}
                      >
                        {t("login")}
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default RegisterForm;
