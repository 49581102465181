import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useContext } from "react";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import { useNavigate, NavigateFunction } from "react-router-dom";
import SpaceBetween from "../../../Component/SpaceBetween";
import TableComponent from "../../../Component/TableComponent";
import Create from "./Create";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";
import Update from "./Update";
import ListItems from "../../../Component/ListItems";
import Detail from "./Detail";
import { useTranslation } from "react-i18next";
import Qs from "../../../Utils/QS/qss";
import FilterUtility from "../../../Component/FilterUtility";
import BasicSpeedDial from "../../../Component/BasicSpeedDial";
import Searching from "../../../Utils/Search/useSearch";

interface CREATE_SALES {
  label: string;
  name: string;
  isAsync: boolean;
  type: string;
  module?: string;
}
interface TABLE_HEAD {
  label: string;
  id: string;
}

function ChangeCompany(): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const navigate: NavigateFunction = useNavigate();
  const { setDialog } = useContext(ConfirmDialog);
  const addCompany = (): void => {
    navigate("?create-company");
  };
  const companyId = Qs();
  const { dataFetch, isLoading, setPage, pageCount, total, isFetching } =
    useDataFetch({
      module: "company_users",
      s: companyId.s,
    });
  const { mutationDelete } = useMutationDelete({
    module: "companies",
    errorMsg: t("delete_company_error"),
    successMsg: t("delete_company_success"),
  });

  const handleDelete: (item: any) => void = (item: any) => {
    setDialog({
      msg: `${t("confirm_delete")} ${item?.name}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
      },
    });
  };

  const itemRebuild = dataFetch?.map((i: any) => ({
    ...i?.company,
    companyId: i?.id,
  }));
  const tableHead: TABLE_HEAD[] = [
    {
      id: "name",
      label: t("name"),
    },
    {
      id: "city",
      label: t("city"),
    },
    {
      id: "address",
      label: t("address"),
    },
    {
      id: "phone",
      label: t("phone"),
    },
  ];

  const handleUpdate: (item: any) => void = (item: any) => {
    navigate(`?update-company-id=${item?.companyId}`);
  };
  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-company-id=${item?.companyId}`);
  };

  const handleChoose: (item: any) => void = (item: any) => {
    setDialog({
      msg: `${t("confirm_change_company")} ${item?.name}`,
      variant: "confirm",
      handleClick: () => {
        localStorage.setItem("companyID", item?.id);
        localStorage.setItem("profileCompany", JSON.stringify(item));
        navigate("/");
      },
    });
  };

  return (
    <Box>
      <SpaceBetween>
        <Box sx={{ display: "grid" }}>
          <Typography fontWeight={700} variant="h5">
            {t("change_company")}
          </Typography>
          <Typography fontSize={14}>
            Total : {total} {isLoading && <CircularProgress size={13} />} {t("company")}
          </Typography>
        </Box>
        <FilterUtility isFetching={isFetching} setPage={setPage} />
        <Box
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            alignItems: "center",
            gap: 2,
          }}
        >
          <Searching
            size="small"
            label={t("search")}
            setPage={setPage}
            isFetching={isFetching}
          />
          <Button onClick={addCompany} variant="contained">
            {t("create_company")}
          </Button>
        </Box>
      </SpaceBetween>
      <Box sx={{ mt: 3 }}>
        <TableComponent
          isLoading={isLoading}
          pageCount={pageCount}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          handleChoose={handleChoose}
          tableHead={tableHead}
          tableBody={itemRebuild}
        />
        <Box sx={{ display: "grid", rowGap: 2 }}>
          <ListItems
            isLoading={isLoading}
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            body={itemRebuild}
            head={tableHead}
            handleClick={handleDetail}
          />
        </Box>
      </Box>
      <Create />
      <Update />
      <Detail />
      <BasicSpeedDial
        title={t("create_company")}
        handleClick={() => navigate("?create-company")}
      />
    </Box>
  );
}

export default ChangeCompany;
