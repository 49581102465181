import { Box } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import { Formik, FormikProps } from "formik";
import BasicModal from "../../../Component/BasicModal";
import FormCompany from "./Form";
import Qs from "../../../Utils/QS/qss";
import { useTranslation } from "react-i18next";
import useMutationUpdate from "../../../Hooks/Mutation/useMutationUpdate";
import { CREATE_COMPANY } from "../../../Auth/Validation";
import { useQueryClient, QueryClient } from "@tanstack/react-query";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import { InitialValue } from "../../../Auth/Company/FormCreateCompany";
import { initialValues } from "../../../Auth/Company/FormCreateCompany";

function Update() {
  const { t } = useTranslation<"translation", undefined>();
  const formRef = React.useRef<any>();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const handleSubmit = (): void => {
    formRef.current?.handleSubmit();
  };
  const client: QueryClient = useQueryClient();
  const companyId: any = Qs();

  const { dataFetchNotPagination, isLoading } = useDataFetch({
    module: `company_users/${companyId["update-company-id"]}`,
    enabled: location.search.includes("?update-company-id"),
  });
  const itemRebuild = dataFetchNotPagination?.company;
  console.log(companyId);
  const { mutationUpdate, isLoading: btnLoading } = useMutationUpdate({
    module: `companies`,
    id: dataFetchNotPagination?.companyId,
    errorMsg: t("update_company_error"),
    successMsg: t("update_company_success"),
    isBack: true,
  });

  return (
    <BasicModal
      isLoading={isLoading || btnLoading}
      title={t("update_company")}
      open={location.search.includes("?update-company-id=")}
      handleClose={() => navigate(-1)}
      handleSubmit={handleSubmit}
      labelClose={t("cancel")}
      labelSubmit={t("create")}
    >
      <Formik
        validationSchema={CREATE_COMPANY}
        innerRef={formRef}
        initialValues={
          dataFetchNotPagination ? { ...itemRebuild } : { ...initialValues }
        }
        enableReinitialize
        onSubmit={(values) => {
          mutationUpdate.mutate(values);
          client.invalidateQueries(["company_users"]);
        }}
      >
        {(props: FormikProps<InitialValue>) => <FormCompany {...props} />}
      </Formik>
    </BasicModal>
  );
}

export default Update;
