import {
  Box,
  Button,
  Typography,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import React, { useContext } from "react";
import { ConfirmDialog } from "../../../Utils/Context/useContextHelper";
import {
  useNavigate,
  NavigateFunction,
  Outlet,
  useLocation,
  Location,
} from "react-router-dom";
import SpaceBetween from "../../../Component/SpaceBetween";
import useMutationDelete from "../../../Hooks/Mutation/useMutationDelete";
import useDataFetch from "../../../Hooks/Query/useDataFetch";
import TableComponent from "../../../Component/TableComponent";
import Detail from "./Detail";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FormatCurrency } from "../../../Component/FormatCurrency";
import "moment/locale/id";
import ListItems from "../../../Component/ListItems";
import DrawerSlideBottom from "../../../Component/DrawerSlideBottom";
import BasicSpeedDial from "../../../Component/BasicSpeedDial";
import Searching from "../../../Utils/Search/useSearch";
import FilterUtility from "../../../Component/FilterUtility";

interface TABLEHEAD {
  id: string;
  label: string | JSX.Element;
  isCheckBox?: boolean;
  isDate?: boolean;
  isCurrency?: boolean;
}
interface TABLEBODY {
  code: string;
  name: string;
  createdAt: string;
  purchasePrice: number;
  sellPrice: number;
  unit: string;
}

function Purchase(): JSX.Element {
  moment.locale("id");
  const { t } = useTranslation<"translation", undefined>();
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const { setDialog } = useContext(ConfirmDialog);
  const [data, setData] = React.useState<[]>([]);
  const location: Location = useLocation();

  const { dataFetch, isLoading, setPage, pageCount, isFetching, total } =
    useDataFetch({
      module: "purchases",
    });

  const { mutationDelete } = useMutationDelete({
    module: "purchases",
    errorMsg: t("delete_purchase_error"),
    successMsg: t("delete_purchase_success"),
  });
  const handleDetail: (item: any) => void = (item: any) => {
    navigate(`?detail-purchases-id=${item?.id}`);
  };

  const handleDelete: (item: any) => void = (item: any) => {
    setDialog({
      msg: `${t("confirm_delete")} ${item?.number}`,
      handleClick: () => {
        mutationDelete.mutate(item?.id);
      },
    });
  };
  const tableHead: TABLEHEAD[] = [
    {
      id: "isCheckBox",
      label: "",
      isCheckBox: true,
    },
    {
      id: "number",
      label: t("number"),
    },
    {
      id: "createdAt",
      label: t("createdAt"),
      isDate: true,
    },
    {
      id: "contact",
      label: t("customer"),
    },
    {
      id: "total",
      label: "Total",
      isCurrency: true,
    },
    {
      id: "paymentStatus",
      label: t("payment_status"),
    },
  ];
  const isCreate = location.pathname.includes("/create-purchase");

  React.useEffect(() => {
    const purchaseItem = dataFetch?.map((i: any) => ({
      ...i,
      contact: i?.contact?.name,
      isCheckBox: false,
    }));
    setData(purchaseItem);
  }, [isLoading, isFetching]);
  return (
    <Box>
      <Box
        sx={{
          display: isCreate ? "none" : "block",
        }}
      >
        <SpaceBetween>
          <Box sx={{ display: "grid" }}>
            <Typography fontWeight={700} variant="h5">
              {t("purchase")}
            </Typography>
            <Typography fontSize={14}>
              Total : {total} {isLoading && <CircularProgress size={13} />} {t("sales")}
            </Typography>
          </Box>
          <FilterUtility isFetching={isFetching} setPage={setPage} />
          <Box
            sx={{
              display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
              gap: 2,
            }}
          >
            <Searching
              size="small"
              label={t("search")}
              setPage={setPage}
              isFetching={isFetching}
            />
            <Button
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "block" },
              }}
              onClick={() =>
                navigate(
                  "/kasir-usaha/app/transaction/purchase/create-purchase?purchase"
                )
              }
              variant="contained"
            >
              {t("create_purchase")}
            </Button>
          </Box>
        </SpaceBetween>
        <Box mt={3}>
          <TableComponent
            setData={setData}
            isOption
            module="purchases"
            pageCount={pageCount}
            onChange={(event: any, page: number) => setPage(page)}
            handleDelete={handleDelete}
            handleDetail={handleDetail}
            isLoading={isLoading}
            tableHead={tableHead}
            tableBody={data}
          />
          <Box sx={{ display: "grid", rowGap: 2 }}>
            <ListItems
              isLoading={isLoading}
              pageCount={pageCount}
              onChange={(event: any, page: number) => setPage(page)}
              body={data}
              handleClick={handleDetail}
              head={tableHead}
            />
          </Box>
        </Box>
        <Detail />
        <BasicSpeedDial
          title={t("create_purchase")}
          handleClick={() =>
            navigate(
              "/kasir-usaha/app/transaction/purchase/create-purchase?purchase"
            )
          }
        />
        <DrawerSlideBottom
          setPage={setPage}
          isFetching={isFetching}
          open={open}
          handleClose={() => setOpen(false)}
        />
      </Box>
      <Outlet />
    </Box>
  );
}

export default Purchase;
