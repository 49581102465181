import React, { ChangeEvent } from "react";
import {
  Box,
  Grid,
  ListItemButton,
  Collapse,
  Divider,
  Skeleton,
  Typography,
  Button,
} from "@mui/material";
import Qs from "../../Utils/QS/qss";
import useDataFetch from "../../Hooks/Query/useDataFetch";
import CardComponent from "../../Component/CardComponent";
import { useTranslation } from "react-i18next";
import { FormatCurrency } from "../../Component/FormatCurrency";
import Cart from "./Cart";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import Searching from "../../Utils/Search/useSearch";
import AsyncAutoComplete from "../../Component/AsyncAutoComplete";
import Payment from "./Payment";
import { useNavigate, NavigateFunction } from "react-router-dom";
import MobileResponsive from "./MobileResponsive";
import { grey } from "@mui/material/colors";

function Cashier(): JSX.Element {
  const { t } = useTranslation<"translation", undefined>();
  const [data, setData] = React.useState<[] | any>([]);
  const [next, setNext] = React.useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const productSearch: any = Qs();
  const [move, setMove] = React.useState<boolean>(false);
  const { dataFetch, isFetching, isLoading, setPage } = useDataFetch({
    module: "products",
    s: productSearch?.s,
  });

  const itemRebuild = dataFetch?.map((i: any) => ({
    ...i,
    quantity: 0,
  }));
  const dataRebuild: any = data?.map((i: any) => ({
    ...i,
    total: FormatCurrency(i?.sellPrice * i?.quantity),
    totalSum: i?.sellPrice * i?.quantity,
  }));

  const handleClick: (item: any) => void = (item: any) => {
    let clone: any[] = [...data];
    //------------------------
    const productItem: any = {
      ...item,
      totalSum: item?.sellPrice * item?.quantity,
      total: item?.sellPrice * item?.quantity,
      unitPrice: item?.sellPrice,
      quantity: 1,
    };
    const findProduct: any = data?.find((i: any) => i?.id === item?.id);
    if (findProduct) {
      clone = clone?.map((i: any) => {
        if (i?.id === item?.id) {
          return {
            ...i,
            quantity: i?.quantity + 1,
            totalSum: i?.sellPrice * i?.quantity,
          };
        } else {
          return i;
        }
      });
    } else {
      clone?.push(productItem);
    }
    setData(clone);
  };

  const subTotal: any = dataRebuild
    ?.map((i: any) => i?.totalSum)
    ?.reduce((a: number, b: number) => a + b, 0);

  const handleDelete: (item: any) => void = (item: any) => {
    const clone: any[] = [...data];
    const delItem: any[] = clone?.filter((i) => i?.id !== item?.id);
    setData(delItem);
  };

  return (
    <Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          gap: 2,
          height: { xs: "0", sm: "100vh", md: "100vh", lg: "100vh" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            p: 2,
            borderRight: `${grey[400]} solid 1px`,
            display: { xs: "none", sm: "block", md: "block", lg: "block" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <AsyncAutoComplete
              onChange={(e: any, i: any) => {
                const sortByCategory: string = JSON.stringify({
                  categoryId: i?.id,
                });
                navigate(`?s=${sortByCategory}`);
              }}
              size="small"
              module="product_categories"
              label={t("sort_by_category")}
              sx={{ width: "300px" }}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              {/* <ListItemButton onClick={() => alert("belum")}>
                <QrCodeScannerIcon />
              </ListItemButton> */}
              <Collapse orientation="horizontal" in={move}>
                <Searching
                  size="small"
                  label={t("search")}
                  isFetching={isFetching}
                  setPage={setPage}
                />
              </Collapse>
              <ListItemButton onClick={() => setMove(!move)}>
                <SearchIcon />
              </ListItemButton>
            </Box>
          </Box>
          <Divider />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            rowSpacing={2}
            columnSpacing={2}
            mt={1}
          >
            {isLoading ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                rowSpacing={2}
                columnSpacing={2}
              >
                {Array(8)
                  .fill(1)
                  .map((item: number, index: number) => (
                    <Grid item key={index}>
                      <Skeleton
                        animation="wave"
                        sx={{ width: "200px", height: "220px" }}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              itemRebuild?.map((item: any, index: number) => {
                const counter: any = data?.find((o: any) => o?.id === item?.id);
                return (
                  <Grid item sm={4} md={4} xl={3} key={index}>
                    <CardComponent
                      bgChange={Boolean(counter)}
                      handleClick={() => handleClick(item)}
                      name={item?.name}
                      sellPrice={FormatCurrency(item?.sellPrice)}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Box>
        <Box
          sx={
            next
              ? {
                  display: "block",
                  width: {
                    xs: "100%",
                    sm: "70%",
                    md: "70%",
                    lg: "70%",
                  },
                }
              : {
                  width: "70%",
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                }
          }
        >
          <Cart
            dataRebuild={dataRebuild}
            data={data}
            setData={setData}
            handleDelete={handleDelete}
            subTotal={subTotal}
            next={next}
            setNext={setNext}
          />
        </Box>
        <Payment data={data} setData={setData} />
      </Box>
      <Box
        sx={
          next
            ? { display: "none" }
            : { display: { xs: "block", sm: "none", md: "none", lg: "none" } }
        }
      >
        <MobileResponsive
          data={data}
          isLoading={isLoading}
          handleClick={handleClick}
          itemRebuild={itemRebuild}
          isFetching={isFetching}
        />
      </Box>
      <Box
        sx={
          next
            ? { display: "none" }
            : {
                display:
                  data?.length <= 0
                    ? "none"
                    : { xs: "flex", sm: "none", md: "none", lg: "none" },
                bgcolor: "#000",
                width: "100%",
                height: "50px",
                alignItems: "center",
                position: "fixed",
                bottom: 0,
                px: 2,
                justifyContent: "space-between",
              }
        }
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ShoppingCartIcon
            sx={{
              color: "#fff",

              transform: "scale(1.3)",
              mr: 1,
            }}
          />
          <Typography variant="h6" color="#fff">
            {data?.length} {t("item_selected")}
          </Typography>
        </Box>
        <Button
          sx={{ color: "#000", bgcolor: "#fff" }}
          onClick={() => setNext(!next)}
          variant="outlined"
        >
          {t("next")}
        </Button>
      </Box>
    </Box>
  );
}

export default Cashier;
