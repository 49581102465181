import React, { useContext } from "react";
import { Box, Link, TextField, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../Component/LoadingButton";
import useMutationPost from "../../Hooks/Mutation/useMutationPost";
import { CREATE_COMPANY } from "../../Auth/Validation";
import { LanguageChange } from "../../Utils/LanguageChange";
import Map from "../../Component/GoogleMap/Map";

type NavigateFunction = /*unresolved*/ any;

const t: (params: any) => any = (params) => <LanguageChange params={params} />;

export interface FormCreateCompany {
  name: string;
  placeholder: string;
  type?: string;
  isMap?: boolean;
}
export interface InitialValue {
  name: string;
  address: string;
  phone: string;
  city: string;
  new_coordinate: any;
}
export const initialValues: InitialValue = {
  name: "",
  address: "",
  phone: "",
  city: "",
  new_coordinate: {},
};

export const formListCreateCompany: FormCreateCompany[] = [
  {
    name: "name",
    placeholder: t("name"),
    type: "text",
  },
  {
    name: "address",
    placeholder: t("address"),
    type: "text",
  },
  {
    name: "phone",
    placeholder: t("phone_number"),
    type: "number",
  },
  {
    name: "city",
    placeholder: t("city"),
    type: "text",
  },
];

function FormCreateCompany(): JSX.Element {
  const [loading, setLoading] = React.useState<boolean>(false);

  const { mutationPost, isLoading } = useMutationPost({
    module: "companies",
    errorMsg: "error",
    successMsg: "Berhasil mendaftar, silahkan masuk!",
    onSuccess: () => {
      localStorage.clear();
      setLoading(false);
      navigate("/kasir-usaha/login");
    }
  });
  const navigate: NavigateFunction = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: { xs: "grid", sm: "grid", lg: "flex", xl: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography variant="h5" fontWeight={600}>
            <span>
              <i>KasirUsaha</i>
              <span
                style={{ width: "10px", height: "10px", background: "#fff" }}
              ></span>
              <span>.WEB</span>
            </span>
          </Typography> */}
        </Box>
        <Box mt={1}>
          <Typography variant="h4" fontWeight={400}>
            {t("create_company")}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={CREATE_COMPANY}
            onSubmit={async (values: InitialValue) => {
              setLoading(true);
              mutationPost.mutate({
                ...values,
              });
            }}
          >
            {({ getFieldProps, errors, touched, setFieldValue }) => (
              <Form>
                <Box mt={3}>
                  <Box sx={{ display: "grid", rowGap: 3 }}>
                    {formListCreateCompany.map(
                      (item: FormCreateCompany, index: number) => {
                        return (
                          <Box key={index}>
                            <TextField
                              fullWidth
                              type={item.type}
                              {...getFieldProps(item.name)}
                              label={item.placeholder}
                              error={Boolean(
                                (touched as any)[item.name] &&
                                  (errors as any)[item?.name]
                              )}
                              helperText={
                                (touched as any)[item.name] &&
                                (errors as any)[item?.name]
                              }
                            />
                          </Box>
                        );
                      }
                    )}
                  </Box>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    isLoading={loading}
                    sx={{ mt: 3 }}
                    title={t("create")}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default FormCreateCompany;
