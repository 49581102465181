import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "../../../Component/IconButton";
import { cyan, green, grey, red, yellow } from "@mui/material/colors";
import AsyncAutoComplete from "../../../Component/AsyncAutoComplete";
import { useTranslation } from "react-i18next";

function CreateRecipeItem({
  setFieldValue,
  getFieldProps,
  values,
}: {
  setFieldValue: any;
  values: any;
  getFieldProps: any;
}) {
  const { t } = useTranslation<"translation", undefined>();
  const handleAddRecipeItem: () => void = () => {
    setFieldValue("ingredient", [
      ...values?.ingredient,
      {
        id: Math.floor(Math.random() * 19998 + 1),
        ingredientName: null,
        variantQuantity: "",
      },
    ]);
  };

  const handleDeleteRecipeItem: (item: any) => void = (item: any) => {
    const clone: any = [...values?.ingredient];
    const delItem: any = clone?.filter((i: any) => i.id !== item.id);
    setFieldValue("ingredient", delItem);
  };
  return (
    <Box>
      <Box sx={{ display: "grid", gap: 2 }}>
        {values?.ingredient?.map((x: any, y: number) => {
          return (
            <Box
              key={y}
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <AsyncAutoComplete
                  label={t("category_raw_material")}
                  value={values?.ingredient[y]?.ingredientName}
                  module="product_categories"
                  onChange={(e: any, i: any) => {
                    setFieldValue(`ingredient[${y}].ingredientName`, i);
                  }}
                  size="small"
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <TextField
                  InputProps={{ endAdornment: <Typography>pasang</Typography> }}
                  {...getFieldProps(`ingredient[${y}].variantQuantity`)}
                  type="number"
                  label={t("quantity")}
                  size="small"
                  disabled={Boolean(
                    values?.ingredient[y]?.ingredientName === null
                  )}
                />
                {y === 0 ? null : (
                  <IconButton onClick={() => handleDeleteRecipeItem(x)}>
                    <DeleteIcon sx={{ color: red[600] }} />
                  </IconButton>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Button
        onClick={handleAddRecipeItem}
        sx={{ mt: 3 }}
        variant="contained"
        fullWidth
      >
        {t("add_ingredient")}
      </Button>
    </Box>
  );
}

export default CreateRecipeItem;
